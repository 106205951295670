import React from "react";
import Button from "@mui/material/Button";

const rows = [
  {
    id: 1,
    Zone: "A",
    BlockNumber: "A01",
    BlockSize: "2.5x3m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop A",
    Business: "อาหาร",
    RentalDate: "2020/01/01",
    Deposit: "2,000 ",
    Rent: "6,000฿",
    Total: "6,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: " ",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: " ",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "ตุลยากร ภัชรภิรมย์",
  },
  {
    id: 2,
    Zone: "A",
    BlockNumber: "A02",
    BlockSize: "3x3m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop B",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/02/01",
    Deposit: " 2,000",
    Rent: "5,000฿",
    Total: "6,000฿",
    WaterBill: "250฿",
    ElectricityBill: "2,000฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "ชนัย คมมาก",
  },
  {
    id: 3,
    Zone: "A",
    BlockNumber: "A03",
    BlockSize: "2x2m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop C",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/03/01",
    Deposit: "8,000฿",
    Rent: "4,000฿",
    Total: "6,000฿",
    WaterBill: "200฿",
    ElectricityBill: "1,800฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "โชคชัย พาใจขวัญ",
  },
  {
    id: 4,
    Zone: "A",
    BlockNumber: "A04",
    BlockSize: "2x2.5m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop D",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/04/01",
    Deposit: "15,000฿",
    Rent: "7,000฿",
    Total: "6,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,500฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "กุลนิดา ภูภักดี",
  },
  {
    id: 5,
    Zone: "A",
    BlockNumber: "A05",
    BlockSize: "3x3.5m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop E",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/05/01",
    Deposit: "20,000฿",
    Rent: "8,000฿",
    Total: "6,000฿",
    WaterBill: "400฿",
    ElectricityBill: "2,800฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นันทิญา แสงสว่าง",

  },
  {
    id: 6,
    Zone: "B",
    BlockNumber: "A06",
    BlockSize: "3x3.5m",
    thumbnail_link: "/images/products/Rectangle 533.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop F",
    Business: "อุปกรณ์",
    RentalDate: "2020/06/08",
    Deposit: "18,000฿",
    Rent: "7,500฿",
    Total: "1,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นที กลับเพชร",
  },
  {
    id: 7,
    Zone: "B",
    BlockNumber: "A07",
    BlockSize: "2.5x2.5m",
    thumbnail_link: "/images/products/Rectangle 526.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop G",
    Business: "อาหาร",
    RentalDate: "2020/07/01",
    Deposit: "16,000฿",
    Rent: "6,500฿",
    Total: "1,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "จอมขวัญ แสงดารา",
  },
  {
    id: 8,
    Zone: "G",
    BlockNumber: "A08",
    BlockSize: "2x2.5m",
    thumbnail_link: "/images/products/Rectangle 528.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop H",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/08/01",
    Deposit: "13,000฿",
    Rent: "6,000฿",
    Total: "500฿",
    WaterBill: "250฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "อัมพิกา พัฒน์ธนโกศล",
  },
  {
    id: 9,
    Zone: "G",
    BlockNumber: "A09",
    BlockSize: "3x3m",
    thumbnail_link: "/images/products/Rectangle 529.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop I",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/09/01",
    Deposit: "14,000฿",
    Rent: "7,000฿",
    Total: "1,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "จันทรา กิตติเตชะคุณ",
  },
  {
    id: 10,
    Zone: "E",
    BlockNumber: "A10",
    BlockSize: "2.5x3.5m",
    thumbnail_link: "/images/products/Rectangle 530.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop J",
    Business: "อุปกรณ์",
    RentalDate: "2020/10/01",
    Deposit: "17,000฿",
    Rent: "7,500฿",
    Total: "1,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
  {
    id: 11,
    Zone: "E",
    BlockNumber: "A11",
    BlockSize: "2x2m",
    thumbnail_link: "/images/products/Rectangle 526.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop K",
    Business: "อาหาร",
    RentalDate: "2020/11/01",
    Deposit: "12,000฿",
    Rent: "6,000฿",
    Total: "4,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
  {
    id: 12,
    Zone: "F",
    BlockNumber: "A12",
    BlockSize: "3x3.5m",
    thumbnail_link: "/images/products/Rectangle 533.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop L",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/12/01",
    Deposit: "16,000฿",
    Rent: "7,500฿",
    Total: "2,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
  {
    id: 13,
    Zone: "G",
    BlockNumber: "A13",
    BlockSize: "2.5x2.5m",
    thumbnail_link: "/images/products/Rectangle 526.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop M",
    Business: "เครื่องดื่ม",
    RentalDate: "2021/01/01",
    Deposit: "14,000฿",
    Rent: "6,500฿",
    Total: "5,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
  {
    id: 14,
    Zone: "G",
    BlockNumber: "A14",
    BlockSize: "3x3m",
    thumbnail_link: "/images/products/Rectangle 528.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop N",
    Business: "อุปกรณ์",
    RentalDate: "2021/02/01",
    Deposit: "18,000฿",
    Rent: "7,000฿",
    Total: "8,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
  {
    id: 15,
    Zone: "F",
    BlockNumber: "A15",
    BlockSize: "2x2.5m",
    thumbnail_link: "/images/products/Rectangle 532.png",
    img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
    NameShop: "Shop O",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2021/03/01",
    Deposit: "15,000฿",
    Rent: "6,500฿",
    Total: "9,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    PayRent: "วันที่ 01 ของทุกเดือน",
    Name: "นนท์ มั่นคงประสิทธิ์",
  },
];

export default rows;