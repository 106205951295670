import axios from "axios"

export const svMiscellaneousData = () => {
    return axios.get(`miscellaneous/data`).then((res) => res).catch((error) => error);
}

export const svMiscellaneousCreate = (formData) => {
    return axios.post(`miscellaneous/create`, formData).then((res) => res).catch((error) => error);
}

export const svMiscellaneousUpdata = (formData) => {
    return axios.post(`miscellaneous/update`, formData).then((res) => res).catch((error) => error);
}

export const svMiscellaneousDelete = (id) => {
    return axios.delete(`miscellaneous/destroy/${id}`).then((res) => res).catch((error) => error);
}