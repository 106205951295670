import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./DefectivePage.scss";
// import rows from "../proofPayment/data/TableData";
import { InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRentData,
  getZoneData,
  getBoxsZoneData,
  getShop,
  getTenant,
  getBoxsData,
} from "../../services/rent.service";
import { getBlockNumber } from "../../services/reportblocknumber.service";
import ExportDetailsStore from "../exportDetailsStore/ExportDetailsStore";
import { svGetpeportshop } from "../../services/rent.service";

const DefectivePage = () => {
  const { id } = useParams();
  const seenNames = new Set();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [rentData, setRentData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [boxsZoneData, setBoxsZoneData] = useState([]);
  const [shopData, setShopData] = useState([]);
  const [rows, setRows] = useState([]);
  const [test, setTest] = useState([]);
  const [test2, setTest2] = useState([]);
  const uniqueRows = [];
  const nameToIdsMap = {};

  const uploadPath = useSelector((state) => state.app.uploadPath);

  console.log("shopData", shopData);

  // console.log("rentData", rentData);
  // console.log("zoneData", zoneData);
  // console.log("boxsZoneData", boxsZoneData);
  // console.log("rows", rows);
  // console.log("test", test);
  // console.log("test2", test2);

  useEffect(() => {
    // console.log("rows", rows);
    svGetpeportshop()
      .then((res) => {
        console.log(res.data.data);
        setShopData(res.data.data);
      })
      .catch();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const rentResponse = await getRentData();
  //     const rentDataRes = rentResponse.data.rent;
  //     setRentData(rentDataRes);

  //     const boxsZoneResponse = await getBoxsZoneData();
  //     const boxsZoneDataRes = boxsZoneResponse.data.boxs;
  //     setBoxsZoneData(boxsZoneDataRes);

  //     const tenantResponse = await getTenant();
  //     const tenantDataRes = tenantResponse.data.tenants;
  //     setTest(tenantDataRes);

  //     const combinedRows = rentDataRes.map((rent, index) => {
  //       const matchedBox = boxsZoneDataRes.find(
  //         (box) => box.box_size === rent.BlockSize
  //       );
  //       const matchedTenant = tenantDataRes.find(
  //         (tenant) => tenant.id === rent.id
  //       );
  //       return {
  //         ...rent,
  //         ...matchedBox,
  //         ...matchedTenant,
  //         id: rent.id || `rent-${index}`,
  //       };
  //     });
  //     setRows(combinedRows);
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   getBlockNumber().then((res) => {
  //     const shops = res.data.data;
  //     setTest2(shops);
  //   });
  // }, []);

  const handleOpen = (imageUrl) => {
    setSelectedImage(
      imageUrl ? `${uploadPath}${imageUrl}` : "/images/no-image.png"
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "บล็อกที่อยู่",
      width: 180,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.zoesBoxe}</div>
      ),
    },
    // {
    //   field: "ImgShop",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 160,
    //   headerClassName: "table-columns",
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         background: "#D0D0E2",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //       onClick={() => handleOpen(params.row.image)}
    //     >
    //       <img
    //         src={
    //           params.row.image
    //             ? `${uploadPath}${params.row.image}`
    //             : "/images/no-image.png"
    //         }
    //         alt=""
    //         width="90px"
    //         style={{
    //           borderRadius: "5px",
    //           objectFit: "cover",
    //         }}
    //       />
    //     </div>
    //   ),
    // },

    // {
    //   field: "NameShop",
    //   headerName: "ชื่อร้านที่เช่า",
    //   sortable: false,
    //   width: isHovered ? 290 : 180,
    //   renderCell: (params) => (
    //     <div
    //       style={{ paddingLeft: "0" }}
    //       onMouseEnter={() => setIsHovered(true)}
    //       onMouseLeave={() => setIsHovered(false)}
    //     >
    //       <p>{params.value}</p>
    //     </div>
    //   ),
    // },
    {
      field: "name",
      headerName: "ชื่อผู้เช่าร้าน",
      width: 180,
      sortable: false,
    },
    {
      field: "nickname",
      headerName: "เบอร์ติดต่อ(TH)",
      width: 190,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "เบอร์ติดต่อ(KH)",
      width: 200,
      sortable: false,
    },
    {
      field: "startDateTrent",
      headerName: "วันที่เริ่มเช่า",
      width: 190,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
    },
    // {
    //   field: "MoveOut",
    //   headerName: "วันที่ย้ายออก",
    //   width: 150,
    //   sortable: false,
    //   // headerAlign: "center",
    //   // align: "center",
    // },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 170,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 170,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 140,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Update",
      headerName: "แสดงรายละเอียด",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3", width: 110 }}
          onClick={() => {
            const url = `/exportdetails/store/${params.row.shopId}`;
            const newWindow = window.open(url, "_blank");
            if (newWindow) {
              newWindow.opener = null;
            }
          }}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];

  const rowsFiltered = shopData
    .filter((row) => {
      if (
        (age === "" || row.zone_name === age) &&
        (row.zoesBoxe.includes(searchInput) || row.name.includes(searchInput))
      ) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) =>
      a.zoesBoxe.localeCompare(b.zoesBoxe, undefined, { numeric: true })
    );

  console.log("rowsFiltered", rowsFiltered);

  const zones = Array.from(new Set(shopData.map((row) => row.zone_name)));

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              className=""
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              รายงานร้านค้า
            </p>
          </div>
          <div className="search">
            <div className="">
              <FormControl fullWidth size="small" style={{ width: "100px" }}>
                <InputLabel id="demo-select-small-label">โซน</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={rentalStatus2}
                  label="โซน"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>ทั้งหมด</em>
                  </MenuItem>
                  {zoneOptions}
                </Select>
              </FormControl>
            </div>

            <TextField
              label="ค้นหา บล็อก,ชื่อ"
              id="outlined-size-small"
              size="small"
              style={{ width: "230px" }}
              onChange={(e) => searchSupplier(e)}
              InputProps={{
                endAdornment: <SearchIcon style={{ color: "grey" }} />,
              }}
            />
          </div>
        </div>

        <DataGrid
          rows={rowsFiltered}
          columns={columns}
          rowHeight={70}
          rowsPerPageOptions={[5]}
          className="custom-data-grid"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={selectedImage}
              alt="Zoomed Shop"
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                width: "500px",
              }}
            />
          </div>
        </Modal>
      </>
    </div>
  );
};

export default DefectivePage;
