import axios from 'axios';

export const svBillingElectricityBillData = () => {
  return axios
    .get(`billingelectrbill/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreateBillingCycle = (formData) => {
  return axios
    .post(`billingelectrbill/create`, formData)
    .then((res) => res)
    .catch((error) => error);
};

export const svGetOldOutstanding = (formData) => {
  return axios
    .post(`billingelectrbill/oldoutstanding`, formData)
    .then((res) => res)
    .catch((error) => error);
};
// ประวัติ ชำระค่าไฟ
export const svGetHistoryBill = () => {
  return axios
    .get(`billingelectrbill/history`)
    .then((res) => res)
    .catch((err) => err);
};

export const svGetDetailsbill = (id) => {
  return axios
    .get(`billingelectrbill/details/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const svEditDetailsbill = (formData) => {
  return axios
    .post(`billingelectrbill/edit/history`, formData)
    .then((res) => res)
    .catch((error) => error);
};

export const svDeleteDetailsbill = (id) => {
  return axios
    .delete(`billingelectrbill/delete/history/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getElectricIncome = () => {
  return axios
    .get(`reports/electric`)
    .then((res) => res)
    .catch((err) => err);
};
