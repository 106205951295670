// ReceiptModal.jsx
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useSelector } from "react-redux";
import SaveAsPDFHandler from "./PDFModal";
import { ListsData } from "./data/DataProductsaddPage";
import FromAddEvidence from "./FromAddEvidence";
import dayjs from "dayjs";
import "./ProductsaddPage.scss";

const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

function ReceiptModal({
  selectedBlockId,
  totalDepositCost,
  totalRentalCost,
  totalWaterbillCost,
  totalElectricityCost,
  totalTentfeeCost,
  totalBill,
  selectedZone,
  selectedStartDate,
  selectedEndDate,
  rentalCost,
  depositCost,
  tentfeeCost,
  blocks,
  overdueRent2,
  note2,
  NameShop,
  Firstname,
  selectedShop,
  totalRental,
}) {
  const [randomNumber, setRandomNumber] = useState(null);
  const [renttotal, setRentTotal] = useState(0);

  useEffect(() => {
    const ttRentalCost = totalRental.reduce((acc, curr) => acc + curr, 0);
    setRentTotal(ttRentalCost);
  }, [totalRental]);

  const values = blocks.map((blockData) => blockData.block.value);

  const filteredOptions = blocks
    .flatMap((blockData) => blockData.block.options)
    .filter((option) => values.includes(option.id));

  const findBlockByValue = (value) => {
    const option = filteredOptions.find((option) => option.id === value);
    return option ? option.block : "ไม่พบข้อมูล";
  };

  useEffect(() => {
    generateRandomNumber();
  }, []);

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    let newRandomNumber;
    do {
      newRandomNumber = Math.floor(min + Math.random() * (max - min + 1));
    } while (newRandomNumber === randomNumber);

    const numberWithUFO = `UFO-${newRandomNumber}`;
    setRandomNumber(numberWithUFO);
  };

  const { displayName } = useSelector((state) => state.auth.profile);

  const handleSavePDF = () => {
    SaveAsPDFHandler("print", today);
  };

  return (
    <div>
      <Box
        className="width-modalRe"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          height: "95%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 1,
        }}
      >
        <div className="print-invoice" id="print">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <img
              src="/images/icons/logo-web-UFOmarket.png"
              style={{
                width: "250px",
              }}
            />
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              ใบเสร็จรับเงิน
            </h1>
          </div>

          <div className="body-invoice">
            <div className="header-invoice">
              <div className="header1">
                <p style={{ fontWeight: "400" }}>เลขที่ : {randomNumber}</p>
                <p style={{ fontWeight: "400" }}>วันที่แจ้ง : {today}</p>
                <p style={{ fontWeight: "400" }}>เบอร์โทร : 098-8888-888</p>
              </div>
              <div className="header1">
                <p style={{ fontWeight: "400" }}>
                  ชื่อผู้เช่า : {selectedShop.Firstname}
                </p>
                {/* <p style={{ fontWeight: "400" }}>
                  ชื่อร้าน : {selectedShop.NameShop}
                </p> */}
                <p style={{ fontWeight: 400 }}>
                  บล็อกที่เช่า :{" "}
                  {filteredOptions.length > 0 ? (
                    [
                      ...new Set(filteredOptions.map((option) => option.block)),
                    ].map((block, index, array) => (
                      <React.Fragment key={index}>
                        <span style={{ fontWeight: "400" }}>{block}</span>
                        {index !== array.length - 1 && ", "}
                      </React.Fragment>
                    ))
                  ) : (
                    <span>ไม่มีข้อมูลสำหรับ id ที่กำหนด</span>
                  )}
                </p>
              </div>
            </div>
            <div className="detail-invoice">
              <div className="detail1" style={{ paddingLeft: "0rem" }}>
                <p className="detail-text">คำอธิบาย</p>
                <p className="detail-text">จำนวนเงิน</p>
              </div>
              {values.map((value, index) => (
                <div key={index}>
                  <div className="total1">
                    <p
                      style={{ fontWeight: "400", fontSize: "1.8rem" }}
                      className="total-text"
                    >
                      {findBlockByValue(value)} (
                      {dayjs(selectedStartDate[index]).format("DD / MM / YYYY")}
                      ) - (
                      {dayjs(selectedEndDate[index]).format("DD / MM / YYYY")})
                    </p>
                  </div>
                  <div className="detail1">
                    <p className="detail-text1">ค่ามัดจำ</p>
                    <p className="detail-text1">
                      {depositCost[index]
                        ? depositCost[index].toLocaleString()
                        : 0}{" "}
                      บ.
                    </p>
                  </div>
                  <div className="detail1">
                    <p className="detail-text1">ค่าเช่า</p>
                    <p className="detail-text1">
                      {totalRental[index]
                        ? totalRental[index].toLocaleString()
                        : 0}{" "}
                      บ.
                    </p>
                  </div>
                  <div className="detail1">
                    <p className="detail-text1">ค่าเต็นท์</p>
                    <p className="detail-text1">
                      {tentfeeCost[index]
                        ? tentfeeCost[index].toLocaleString()
                        : 0}{" "}
                      บ.
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">รวมทั้งหมด</p>
                <p className="total-text">{totalBill.toLocaleString()} บ.</p>
              </div>
            </div>

            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">ค่าคงค้าง</p>
                <p className="total-text">{overdueRent2} บ.</p>
              </div>
            </div>

            <div className="note-invoice">
              <p className="note-text">หมายเหตุ*</p>
              <p>{note2}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <p style={{ fontSize: "2rem", fontWeight: "400" }}>
              ผู้รับเงิน : {displayName}{" "}
            </p>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "15px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "150px",
              display: "flex",
              gap: "4px",
              cursor: "pointer",
              marginBottom: "1rem",
            }}
            component="a"
            className="button-h"
            onClick={handleSavePDF}
          >
            <SaveAltIcon style={{ marginRight: "5px" }} /> Download
          </Button>
        </div> */}
      </Box>
    </div>
  );
}

export default ReceiptModal;
