import { Box } from "@mui/system";
import React, { useState } from "react";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import "./ExpirationUpdate.scss";
import { useSelector } from "react-redux";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import dayjs from "dayjs";
import SaveAsPDFHandler from "./PDFModal";

const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

function InvoiceModal({
  invoiceValue,
  blockNumbers,
  rentalCosts,
  blockAndRent,
  nameRent,
  nameShop,
}) {
  const { displayName } = useSelector((state) => state.auth.profile);

  const SaveAsPDFHandler = () => {
    console.log("ok");
    const dom = document.getElementById("print");
    toPng(dom, { pixelRatio: 3 })
      .then((dataUrl) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = dataUrl;
        img.onload = () => {
          const imgProps = img.width / img.height;
          const pdfWidth = 80; 
          const pdfHeight = pdfWidth / imgProps; 

          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight], 
          });

          const paddingX = 3; // 5mm padding on the sides
          const pdfContentWidth =
            pdf.internal.pageSize.getWidth() - 2 * paddingX;

          pdf.addImage(
            dataUrl,
            imgProps.fileType,
            paddingX, 
            0, 
            pdfContentWidth,
            pdfHeight
          );

          pdf.save(`invoice-${today}-A01.pdf`);
        };
        img.onerror = (error) => {
          console.error("Error loading image", error);
        };
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <div>
      <Box
        className="width-modal22"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 1,
        }}
      >
        <div className="print-invoice" id="print">
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <img
              src="/images/icons/logo-web-UFOmarket.png"
              style={{
                width: "250px",
              }}
            />
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              ใบแจ้งหนี้
            </h1>
          </div>

          <div className="header-invoiceEE">
            <div className="header11">
              <p style={{ fontWeight: "400" }}>วันที่แจ้ง : {today}</p>

              <p style={{ fontWeight: "400" }}>ชื่อผู้แจ้ง : {displayName} </p>

              <p
                style={{
                  fontWeight: 400,
                }}
              >
                ชื่อผู้เช่า :{" "}
                {nameRent.map((value, index) => (
                  <React.Fragment key={index}>
                    <span style={{ fontWeight: "400" }}>{value}</span>
                    {index !== nameRent.length - 1 && (index + 1) % 2 === 0 ? (
                      <span style={{ flexBasis: "100%" }} />
                    ) : (
                      index !== nameRent.length - 1 && ","
                    )}
                  </React.Fragment>
                ))}
              </p>
              <p style={{ fontWeight: 400 }}>
                บล็อกที่เช่า:{" "}
                {blockNumbers.map((value, index) => (
                  <React.Fragment key={index}>
                    <span style={{ fontWeight: "400" }}>{value}</span>
                    {index !== blockNumbers.length - 1 && ","}
                  </React.Fragment>
                ))}
              </p>
              <p style={{ fontWeight: "400" }}>เบอร์โทร : 098-8888-888</p>

              <p style={{ fontWeight: "400" }}>
                ตั้งแต่วันที่ :{" "}
                {dayjs(invoiceValue.selectedDate2).format("DD / MM / YYYY")} -{" "}
                {dayjs(invoiceValue.selectedDate3).format("DD / MM / YYYY")}
              </p>
            </div>
          </div>

          <div className="detail-invoice1">
            <div className="detail1">
              <p className="detail-text">คำอธิบาย</p>
              <p className="detail-text">จำนวนเงิน</p>
            </div>
            {blockNumbers.map((value, index) => (
              <React.Fragment key={index}>
                <div
                  className=""
                  style={{ display: "flex-col", marginLeft: "1.8rem" }}
                >
                  <p style={{ fontWeight: "400", fontSize: "1.8rem" }}>
                    บล็อกที่เช่า : {value}
                  </p>

                  <div className="detail1">
                    <p className="detail-text1">ค่าเช่า</p>
                    <p className="detail-text1">
                      {rentalCosts[index].toLocaleString()} บ.
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          {!!invoiceValue.totalFine && (
            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">ค่าปรับ</p>
                <p className="total-text">
                  {invoiceValue.totalFine.toLocaleString()} บ.
                </p>
              </div>
            </div>
          )}

          {!!invoiceValue.overdue && (
            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">ค่าคงค้าง</p>
                <p className="total-text">
                  {invoiceValue.overdue.toLocaleString()} บ.
                </p>
              </div>
            </div>
          )}

          <div className="total-invoice">
            <div className="total1">
              <p className="total-text">รวมทั้งหมด</p>
              <p className="total-text">{invoiceValue.totalToPay} บ.</p>
            </div>
          </div>

          {!!invoiceValue.note && (
            <div className="note-invoice">
              <p className="note-text">หมายเหตุ*</p>
              <p>{invoiceValue.note}</p>
            </div>
          )}
        </div>

        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "15px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "150px",
              display: "flex",
              gap: "4px",
              cursor: "poiter",
              marginBottom: "1rem",
            }}
            component="a"
            className="button-h"
            onClick={SaveAsPDFHandler}
          >
            <SaveAltIcon style={{ marginRight: "5px" }} /> Download
          </Button>
        </div>
      </Box>
    </div>
  );
}

export default InvoiceModal;
