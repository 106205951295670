import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import rows from "../data/TableData";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/system";
import { updatebox } from "../../../services/setting/boxzone.service";

function FormEditBlock(props) {
  const { openmodaleditboxs, setRefreshData, boxEdit, zonesName } = props;
  const [editData, setEditData] = useState(boxEdit);
  const [isError, setIsError] = useState({
    addzone: false,
    // selectZone: false,
    boxnumber: false,
    boxSize: false,
  });

  const zoneOptions = zonesName.map((zone) => (
    <MenuItem key={zone.id} value={zone.id}>
      {zone.zone_name}
    </MenuItem>
  ));

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      boxnumber: editData.zone_number.trim().length < 1,
      boxSize: editData.box_size.trim().length < 1,
    };
    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("boxid", editData.id);
      // formData.append("zoneselect", editData.idzone);
      // formData.append("boxnumber", editData.zone_number);
      formData.append("box_size", editData.box_size);

      /* Display the key/value pairs*/
  for (var pair of formData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }
  // return false;
  updatebox(formData).then((res)=>{
    console.log(res);
    if (res.status === 200) {
      openmodaleditboxs(false);
      SwalUI({ status: res.data.status, //description: res.data.description 
        });
      setRefreshData((prev) => prev + 1);
    }
  })
    }
    // console.log('123');
  };
  // console.log(editData);
  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              className=""
              style={{
                fontSize: "1.5rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              เพิ่มข้อมูลบล็อก / แก้ไขข้อมูล
            </p>
          </div>
        </div>
        <div className="box-form1">
          <div className="first-row">
            <label htmlFor="">โซน</label>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
            >
              {/* <InputLabel id="select-label-amphoe">A</InputLabel> */}
              {/*<Select
                labelId="select-label-amphoe"
                id="select-amphoe"
                label="A"
                value={editData.idzone}
                onChange={(e) =>
                  setEditData((prevState) => {
                    return { ...prevState, idzone: e.target.value };
                  })
                }
                // error={isError.selectZone}
              >
                {zoneOptions}
                 <MenuItem
                value=""
                style={{ display: "flex", gap: "6px" }}
                onClick={handleOpenShop}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{ color: "#707070" }}
                />
                เพิ่มโซน
              </MenuItem> 
              </Select>*/}
                <TextField
              id="outlined-basic"
              disabled
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={editData.zone_name}
            />
            </FormControl>
          </div>

          {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
          {/* <Modal open={openshop} onClose={handleCloseShop}>
          <Box
            className="width-modal-block"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 2,
            }}
          >
            <TextField
              label="เพิ่มโซน"
              variant="outlined"
              fullWidth
              value={addData.addzone}
              // onChange={handleSearchInputChange}
              onChange={(e) =>
                setAddData((prevState) => {
                  return { ...prevState, addzone: e.target.value };
                })
              }
              error={isError.addzone}
              autoFocus
              sx={{ mb: 2 }}
              InputProps={{
                style: {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />
            <div
              className=""
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "16px",
                  background: "#6C5DD3",
                  color: "white",
                  height: "39px",
                  width: "100px",
                }}
                component="a"
                className="button-h"
                onClick={addValidatorszone}
              >
                บันทึก
              </Button>
            </div>
          </Box>
        </Modal> */}

          <div className="first-row">
            <label htmlFor="">เลขบล็อกที่</label>
            <TextField
              id="outlined-basic"
              disabled
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={editData.zone_number}
              // onChange={(e) =>
              //   setEditData((prevState) => {
              //     return { ...prevState, zone_number: e.target.value };
              //   })
              // }
              // error={isError.boxnumber}
            />
          </div>
          <div className="first-row">
            <label htmlFor="">ขนาดบล็อก</label>
            <TextField
              id="outlined-basic"
              label="2X2 m"
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={editData.box_size}
              onChange={(e) =>
                setEditData((prevState) => {
                  return { ...prevState, box_size: e.target.value };
                })
              }
              error={isError.boxSize}
            />
          </div>
        </div>
        <div className="box-btn">
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            onClick={() => openmodaleditboxs(false)}
            className="button-h"
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            onClick={addValidators}
            className="button-h"
          >
            บันทึก
          </Button>
        </div>
      </>
    </div>
  );
}

export default FormEditBlock;
