import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import "./Salary.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { width } from "@mui/system";
import moment from "moment";
import { svEmployeUpdate } from "../../services/expenses/employe.service";
import SwalUI from "../../components/ui/swal-ui/swal-ui";

function UpdateFrom(props) {
  const { selectedRow, setOpenEdit, setRefreshData } = props;
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [editData, setEditData] = useState(selectedRow);
  // const [searchInput, setSearchInput] = useState("");
  // const [age, setAge] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [isError, setIsError] = useState({
    salary: false,
    department: false,
    workStartDate: false,
    firstName: false,
    lastName: false,
    telephoneNumber: false,
    imageNamr: false,
    idLine: false,
    email: false,
    address: false,
    telegram: false,
    wohatsApp: false,
  });

  useEffect(() => {
    let thumbnail = uploadPath + editData.url_img;
    setSelectedImage(thumbnail);
  }, []);

 

  // const handleSearchInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  // const [selectedShop, setSelectedShop] = useState(null);

  // const handleChangeBusiness = (event) => {
  //   const selectedValue = event.target.value;
  //   setAge(selectedValue);
  // };

  // const [openshop, setOpenShop] = useState(false);
  // const handleOpenShop = () => {
  //   setOpenShop(true);
  // };
  // const handleCloseShop = () => {
  //   setOpenShop(false);
  // };

  // const formInputRef = useRef(null);
  // const label = { inputProps: { "aria-label": "Switch demo" } };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement("input");
    setSelectedImage(null);
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setEditData((prevState) => {
            return { ...prevState,imageRemove: editData.url_img , imageNamr: file.name, imageFile: file };
          });
          console.log("123");
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const submitEditData = () => {
    let isValid = true;

    let error = {
      ...isError,
      salary: editData.salary < 1,
      department: editData.department.trim().length < 1,
      firstName: editData.first_name.trim().length < 1,
      lastName: editData.last_name.trim().length < 1,
      telephoneNumber: editData.telephone_number.trim().length < 1,
      email: !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(editData.email),
      address: editData.address.trim().length < 1,
    };

    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("id_employee", editData.id)
      formData.append("salary", editData.salary);
      formData.append("department", editData.department);
      formData.append("workStartDate",typeof editData.date === "string"?editData.work_start_date:moment(editData.work_start_date).format("DD/MM/YYYY"));
      formData.append("firstName", editData.first_name);
      formData.append("lastName", editData.last_name);
      formData.append("telephoneNumber", editData.telephone_number);
      
      formData.append("Thumbnail", editData.imageFile||"");
      formData.append("ThumbnailName", editData.url_img||editData.imageNamr);
      formData.append("ThumbnailRemove", editData.imageRemove||"");

      formData.append("idLine", editData.id_line);
      formData.append("email", editData.email);
      formData.append("address", editData.address);
      formData.append("telegram", editData.telegram);
      formData.append("wohatsApp", editData.wohats_app);

      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;

      svEmployeUpdate(formData).then((res)=>{
        console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            // description: res.data.description
          });
          setOpenEdit(false);
        }
      });

    }
  };

  return (
    <>
      <div className="box-shop">
        <div className="from-shopSalary">
          <div className="figure-container">
            <figure className="figure" style={{ cursor: "pointer" }}>
              {selectedImage && (
                <img
                  className="content-images"
                  src={selectedImage}
                  alt="Selected"
                />
              )}

              {/* แสดงรูปภาพที่เลือก */}
              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={previewImageHandler}
                  style={{ display: "none" }}
                />
              </div>
              {selectedImage ? (
                ""
              ) : (
                <div className="figure-text">
                  <InsertPhotoOutlinedIcon
                    fontSize="large"
                    style={{ color: "grey" }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#707070",
                      text: "center",
                    }}
                  >
                    อัปโหลด รูปภาพพนักงาน
                  </p>
                </div>
              )}
            </figure>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                width: "108px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              onClick={openFileUploader}
            >
              อัปโหลด
            </Button>
          </div>

          <div className="text-from-shop">
            <TextField
              size="small"
              label="เงินเดือน"
              variant="outlined"
              value={editData.salary}
              error={isError.salary}
              onChange={(event) =>
                setEditData((prevState) => {
                  return { ...prevState, salary: event.target.value };
                })
              }
            />
            <TextField
              size="small"
              label="ตำแหน่งงาน"
              variant="outlined"
              value={editData.department}
              error={isError.department}
              onChange={(event) =>
                setEditData((prevState) => {
                  return { ...prevState, department: event.target.value };
                })
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันเริ่มงาน"
                inputFormat="DD / MM / YYYY"
                value={moment(editData.work_start_date, "DD / MM / YYYY")}
                onChange={(date)=>{
                  setEditData((prevState) => {
                    return { ...prevState, work_start_date: date.format("DD / MM / YYYY")};
                  })
                }}
                sx={{ width: "33.33%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>

        {/* ข้อมูลพนักงาน */}
        <div className="from-Salary">
          <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
            ข้อมูลพนักงาน
          </h1>
          <div className="from-box-information">
            <div className="from-1">
              <TextField
                size="small"
                label="ชื่อ"
                variant="outlined"
                value={editData.first_name}
                error={isError.firstName}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, first_name: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="นามสกุล"
                variant="outlined"
                value={editData.last_name}
                error={isError.lastName}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, last_name: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="เบอร์โทรศัพท์"
                variant="outlined"
                value={editData.telephone_number}
                error={isError.telephoneNumber}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      telephone_number: event.target.value,
                    };
                  })
                }
              />
              <TextField
                size="small"
                label="ไอดี ไลน์"
                variant="outlined"
                value={editData.id_line}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, id_line: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="อีเมล"
                variant="outlined"
                value={editData.email}
                error={isError.email}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, email: event.target.value };
                  })
                }
              />
            </div>
            <div className="from-2">
              <TextField
                id="outlined-multiline-static"
                label="ที่อยู่"
                multiline
                rows={4}
                // defaultValue="Default Value"
                value={editData.address}
                error={isError.address}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, address: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="เทเลแกรม"
                variant="outlined"
                value={editData.telegram}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, telegram: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="WhatsApp"
                variant="outlined"
                value={editData.wohats_app}
                onChange={(event) =>
                  setEditData((prevState) => {
                    return { ...prevState, wohats_app: event.target.value };
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: "16px",
            background: "#6C5DD3",
            color: "white",
            height: "39px",
            width: "100px",
          }}
          component="a"
          className="button-h"
          onClick={submitEditData}
        >
          บันทึก
        </Button>
      </div>
    </>
  );
}

export default UpdateFrom;
