/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { Card } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./components/Table";
import { useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getPaymentDetail } from "../../services/proofpayment.service";

import "./ProofPaymentDetail.scss";

function ProofPaymentDetail() {
  const { id } = useParams();
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [dataPayRent, setDataPayRent] = useState([]);
  const [detailPayment, setDetailPayment] = useState([]);

  useEffect(() => {
    console.log(id);
    getPaymentDetail(id).then((res) => {
      setDataPayRent(res.data.box)
      setDetailPayment(res.data.payment);
    });
  }, [id]);

  // ฟังก์ชันสำหรับการค้นหา
  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    // ทำการค้นหาตามคำค้นหาที่ผู้ใช้ป้อน
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <section id="products-page">
      <div
        className="header"
        style={{ display: "flex", justifyContent: "space-between", gap: "4px", marginBottom:"1.5rem" }}
      >
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          รายละเอียดหลักฐานการจ่ายเงิน บล็อก {dataPayRent.BlockNumber}
        </div>
        <div className="search">
          <TextField
            label="ค้นหาชื่อผู้เช่า"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ color: "grey", marginLeft: "8rem" }} />
              ),
            }}
          />
        </div>
      </div>

      <div>
        <Table 
          searchInput={searchInput} 
          detailPayment={detailPayment}
        />
      </div>
    </section>
  );
}

export default ProofPaymentDetail;
