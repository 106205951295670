import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Card, TextField } from "@mui/material";
import axios from "axios";
import Table from "./components/Table";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import rows from "./data/TableData";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getExportDetail } from "../../services/report.service";

function ExportDetailsPage() {
  const [year, setYear] = React.useState("");
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { id } = useParams();
  const [dataExportDetail, setDataExportDetail] = useState([]);
  const [dataBoxDetail, setDataBoxDetail] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    getExportDetail(id).then((res) => {
      setDataExportDetail(res.data?.detail);
      setDataBoxDetail(res.data?.box);
    });
  }, [id]);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exportToExcel = (dataExportDetail, columns) => {
    // กรองเฉพาะคอลัมน์ที่ต้องการ และกำหนดชื่อใหม่
    const selectedFields = {
      Date: "วันที่",
      NameShop: "ชื่อร้านที่เช่า",
      Business: "ประเภทธุรกิจ",
      RentalDate: "วันที่เริ่มเช่า",
      Deposit: "ค่ามัดจำ",
      Rent: "ค่าเช่า",
      WaterBill: "ค่าน้ำ",
      ElectricityBill: "ค่าไฟ",
      StartDate: "วันที่เริ่มเช่า",
      MoveOut: "วันที่ย้ายออก",
      NumberOfDays: "จำนวนวันที่เช่าอยู่",
    };

    const filteredRows = dataExportDetail.map((row) => {
      const filteredRow = {};
      Object.keys(selectedFields).forEach((field) => {
        filteredRow[selectedFields[field]] = row[field];
      });
      return filteredRow;
    });

    // สร้าง Workbook และ Worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);

    // เพิ่ม Worksheet เข้าสู่ Workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // แปลง Workbook เป็น ArrayBuffer
    const wbArrayBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    // แปลง ArrayBuffer เป็น Blob
    const wbBlob = new Blob([wbArrayBuffer], {
      type: "application/octet-stream",
    });

    // บันทึกไฟล์ Excel ด้วยชื่อ salary_data.xlsx
    saveAs(wbBlob, "report_block_data.xlsx");
  };

  const columns = [
    {
      field: "image",
      headerName: "ภาพหน้าร้าน",
      width: 150,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: "name",
      headerName: "ชื่อผู้เช่า",
      // headerAlign: "center",
      align: "left",
      sortable: false,
      width: 200,
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 140,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "ElectricityBill",
      headerName: "ค่าไฟ",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "----฿";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "overdue_pay",
      headerName: "ค่าปรับ",
      width: 130,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "RentalDate",
      headerName: "วันที่เริ่มเช่า",
      width: 130,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "MoveOut",
      headerName: "วันที่ย้ายออก",
      width: 130,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      renderCell: (params) => {
        const numberDay = params.value;

        if (params.row.rent_status == 0) {
          return <div style={{ fontWeight: 500 }}>{params.row.UpdateDate}</div>;
        }
        return <p>--/--/--</p>;
      },
    },
    {
      field: "NumberOfDays",
      headerName: "จำนวนวันที่เช่าอยู่",
      width: 127,
      sortable: false,
      renderCell: (params) => {
        // const numberDay = params.value;

        // if (!numberDay || !numberDay.trim()) {
        //   return <p>--วัน</p>;
        // }
        if (params.row.rent_status == 0) {
          if (
            params.row.RentalDate !== null &&
            params.row.UpdateDate !== null
          ) {
            const start = new Date(
              moment(params.row.RentalDate, "DD/MM/YYYY").format("YYYY, MM, DD")
            );
            const end = new Date(
              moment(params.row.UpdateDate, "DD/MM/YYYY").format("YYYY, MM, DD")
            );
            const diffTime = end.getTime() - start.getTime();
            const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24)) + 1;

            return <p>{diffDays} วัน</p>;
          }
        }

        return <p>--</p>;
      },
    },
  ];

  const rowsFiltered = dataExportDetail;

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              gap: "2rem",
              marginBottom: "1rem",
            }}
          >
            <p style={{ width: "100%", fontSize: "1.75rem", fontWeight: 500 }}>
              รายงานเลขบล็อก {dataBoxDetail.BlockNumber}
            </p>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                gap: "1rem",
              }}
            >
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                style={{ backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-label">ปี</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="ปี"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>2023</MenuItem>
                  <MenuItem value={20}>2022</MenuItem>
                  <MenuItem value={30}>2021</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "14px",
                  background: "#6C5DD3",
                  color: "white",
                  height: "39px",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  width: "180px",
                  // marginTop: "2rem",
                }}
                className="button-h"
                onClick={() => exportToExcel(rowsFiltered, columns)}
              >
                EXPORT TO EXCEL
              </Button>
            </div>
          </div>
        </div>

        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={dataExportDetail}
            columns={columns}
            rowHeight={70}
            rowsPerPageOptions={[5]}
            className="custom-data-grid"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img
                src={selectedImage}
                alt="Zoomed Shop"
                style={{
                  maxWidth: "90vw",
                  maxHeight: "90vh",
                  // height: "350px",
                  width: "500px",
                }}
              />
            </div>
          </Modal>
        </div>
      </>
      {/* )} */}
    </section>
  );
}

export default ExportDetailsPage;
