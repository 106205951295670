import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import rows from "./data/TableData";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./StoreRent.scss";
import { Box } from "@mui/system";
import {
  svdestroyTenant,
  svTenantData,
} from "../../services/overalldisplay/tenantinfo.service";
import { getBusinessType } from "../../services/rent.service";
import PayRenttDetails from "./StoreRentupdate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from '../../components/ui/swal-ui/swal-ui';
const modalSwal = withReactContent(Swal);

const StoreRent = () => {
  const webPath = useSelector((state) => state.app.uploadPath);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState({
    nameShop: false,
    blockZone: false,
  });
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [age, setAge] = useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [openshop, setOpenShop] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedShop, setSelectedShop] = useState(null);
  const [tenantInfo, setTenantInfo] = useState([]);
  const [detailTenant, setDetailTenant] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [selectBusiness, setSelectBusiness] = useState([]);
  // const [bloczone, setBloczone] = useState([]);

  useEffect(() => {
    svTenantData().then((res) => {
      // console.log(res.data);
      if (res.status === 200) {
        setTenantInfo(res.data.tenant_shop);
      }
    });

    getBusinessType().then((res) => {
      const businessRes = res.data.business;
      // console.log(businessRes);
      setSelectBusiness(businessRes);
    });
  }, [refreshData]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterDuplicatesInModal = (data) => {
    const uniqueNames = new Set();
    return data.filter((item) => {
      if (uniqueNames.has(item.tenant.name)) {
        return false;
      }
      uniqueNames.add(item.tenant.name);
      return true;
    });
  };

  const handleResultClick = (selectedNameShop) => {
    const shop = rows.find((row) => row.RentalShopName === selectedNameShop);
    setSelectedShop(shop);
    setSearchQuery(selectedNameShop);
    setOpenShop(false);
  };

  const handleOpenDetail = (row) => {
    // console.log(row);
    setDetailTenant(row);
    setOpenDetail(true);
  };

  const handledestroy = (row) => {
    // console.log(row);
    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        text: "ลบบล็อกนี้ประวัติจะถูกลบทั้งหมด",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          svdestroyTenant(row).then((res) => {
            if (res.status === 201) {
              setRefreshData(prev => prev + 1)
             SwalUI({
                      status: res.data.status,
                      description: res.data.description,
                    });
            }
          });
        }
      });
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  // const searchSupplier = (e) => {
  //   setSearchInput(e.target.value);
  // };

  // const handleChangeTenant = (event) => {
  //   setSelectedTenant(event.target.value);
  // };

  const handleChangeStatus = (event) => {
    // console.log("123");
    setSelectedStatus(event.target.value);
  };

  const handleChangeZone = (event) => {
    setAge(event.target.value);
  };

  const columns = [
    {
      field: "Name",
      headerName: "ชื่อผู้เช่าร้าน",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div>
          <p>{params.row.tenant.name}</p>
        </div>
      ),
    },
    // {
    //   field: "shop",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 100,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <img
    //     src={webPath + params.row.shop.image}
    //     // src={''}
    //       alt="Shop"
    //       style={{
    //         width: 100,
    //         borderRadius: "0.375rem",
    //         width: "59px",
    //         height: "59px",
    //       }}
    //       onClick={() => handleOpen(webPath + params.row.shop.image)}
    //     />
    //   ),
    // },
    {
      field: "NameShop",
      headerName: "ชื่อร้านที่เช่า",
      align: "left",
      sortable: false,
      width: isHovered.nameShop ? 200 : 140,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          // onMouseEnter={() => setIsHovered(true)}
          onMouseEnter={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                nameShop: true,
              };
            })
          }
          onMouseLeave={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                nameShop: false,
              };
            })
          }
        >
          <p>{params.row.shop?.name}</p>
        </div>
      ),
    },
    {
      field: "BusinessType",
      headerName: "ประเภทธุรกิจ",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.shop?.box_color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.row.shop?.types_name}</div>
          </div>
        );
      },
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 110,
      sortable: false,
      renderCell: (params) => (
        //   forEach((animal) => {
        //     console.log(animal)
        //  })
        <div
          style={{ paddingLeft: "0", fontWeight: 500 }}
          onMouseEnter={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                blockZone: true,
              };
            })
          }
          onMouseLeave={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                blockZone: false,
              };
            })
          }
        >
          {params.row.combined}
        </div>
      ),
    },
    // {
    //   field: "address",
    //   headerName: "ที่อยู่",
    //   headerAlign: "center",
    //   align: "left",
    //   sortable: false,
    //   width: isHovered ? 520 : 200,
    //   renderCell: (params) => (
    //     <div
    //       style={{ paddingLeft: "0" }}
    //       onMouseEnter={() => setIsHovered(true)}
    //       onMouseLeave={() => setIsHovered(false)}
    //     >
    //       <p>{params.value}</p>
    //     </div>
    //   ),
    // },
    {
      field: "PhoneTh",
      headerName: "เบอร์ติดต่อ (TH)",
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.nickname}</div>
      ),
    },
    {
      field: "PhoneKh",
      headerName: "เบอร์ติดต่อ (KH)",
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.phone}</div>
      ),
    },
    {
      field: "idline",
      headerName: "ไอดีไลน์",
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.line}</div>
      ),
    },
    {
      field: "whatsapp",
      headerName: "วอตส์แอปป์",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.whatsapp}</div>
      ),
    },
    {
      field: "telegram",
      headerName: "เทเลแกรม",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.telegram}</div>
      ),
    },
    {
      field: "email",
      headerName: "อีเมล",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.tenant.mail}</div>
      ),
    },
    {
      field: "Update",
      headerName: "รายละเอียด",
      headerAlign: "center",
      align: "center",
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#6C5DD3",
            transition: "background-color 0.3s",
          }}
          onClick={() => handleOpenDetail(params.row)}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
    {
      field: "Delete",
      headerName: "ลบ",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => handledestroy(params.row)}
          style={{
            position: "relative",
            overflow: "hidden",
            background: "#FF2C2C",
            color: "#fff",
          }}
          className="button-Delete"
        >
          <DeleteOutlineIcon />
        </Button>
      ),
    },
  ];

  const rowsFiltered = tenantInfo.filter((row) => {
    if (
      (age === "" || row.zoneName === age) && // Filter by Zone
      row.tenant.name.includes(searchQuery)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const zones = Array.from(new Set(tenantInfo.map((row) => row.zoneName)));

  const zoneOptions = zones
    .sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((zone, index) => (
      <MenuItem key={index} value={zone}>
        {zone}
      </MenuItem>
    ));

  // const status = Array.from(new Set(tenantInfo.map((row) => row.rent_status)));
  // const statusOptions = status.map((status, index) => (
  //   <MenuItem key={index} value={status}>
  //     {status}
  //   </MenuItem>
  // ));
  // const statusOptions = {
  //   <MenuItem value={10}>Ten</MenuItem>
  //   <MenuItem value={20}>Twenty</MenuItem>
  // };

  return (
    <section id="products-page">
      <div className="header" style={{ marginBottom: "1.5rem" }}>
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          ร้านค้า / ผู้เช่า
        </div>
        <div className="search">
          {/* <div className="">
            <FormControl fullWidth size="small" style={{ width: "150px" }}>
              <InputLabel id="demo-select-small-label">สถานะผู้เช่า</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus}
                label="สถานะผู้เช่า"
                onChange={handleChangeStatus}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                <MenuItem value="1">ยังเช่าอยู่</MenuItem>
                <MenuItem value="0">ย้ายออกแล้ว</MenuItem>
              </Select>
            </FormControl>
          </div> */}

          <div className="">
            <FormControl fullWidth size="small" style={{ width: "150px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChangeZone}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>

          <Button
            className="button-h"
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              width: "150px",
            }}
            onClick={handleOpenShop}
          >
            ค้นหาผู้เช่า
          </Button>
          {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                p: 4,
              }}
            >
              <TextField
                label="ค้นหาร้าน"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
              <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#6C5DD3",
                        }}
                      >
                        เลือกผู้เช่า
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterDuplicatesInModal(rowsFiltered).map((result) => (
                      <tr
                        key={result.id}
                        onClick={() => handleResultClick(result.tenant.name)}
                        style={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f0f0f0" },
                        }}
                      >
                        <td
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {result.tenant.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>

      <Modal open={openDetail} onClose={handleCloseDetail}>
        <Box
          className="width-modalS"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <PayRenttDetails
            detailTenant={detailTenant}
            setRefreshData={setRefreshData}
            setOpenDetail={setOpenDetail}
            selectBusiness={selectBusiness}
          />
        </Box>
      </Modal>
    </section>
  );
};

export default StoreRent;
