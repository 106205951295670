/* eslint-disable */
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import "./BlockSize.scss";
import Table from "./components/Table";
import FormAddBlock from "./components/FormAddBlock";
import FormEditBlock from "./components/FormEditBlock";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { svBoxsData } from "../../services/setting/boxzone.service";
import { svZoneData } from "../../services/setting/boxzone.service";

function BlockSize() {
  const [openshop, setOpenShop] = useState(false);
  const [openboxsEdit, setOpenboxsEdit] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [boxEdit, setBoxEdit] = useState([]);
  const [zonesName, setZonesName] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");

  console.log("filteredData", filteredData);
  useEffect(() => {
    svZoneData().then((res) => {
      console.log(res);
      setZonesName(res.data.zonename);
    });
    svBoxsData().then((res) => {
      console.log(res);
      setFilteredData(res.data.boxdetails);
    });
  }, [refreshData]);

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleCloseboxsEdit = () => {
    setOpenboxsEdit(false);
  };

  const handleChangeZone = (event) => {
    setSelectedZone(event.target.value);
  };

  const zones = Array.from(
    new Set(filteredData.map((row) => row.zone_name))
  ).sort();

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  const filteredDataByZone = selectedZone
    ? filteredData.filter((data) => data.zone_name === selectedZone)
    : filteredData;

    const sortedData = filteredDataByZone.sort((a, b) => {
      if (a.zone_name < b.zone_name) return -1;
      if (a.zone_name > b.zone_name) return 1;
      return a.zone_number - b.zone_number;
    });

  return (
    <section>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              บล็อก ขนาดบล็อก
            </p>
          </div>

          <div className="add-box">
            <div className="" style={{ marginRight: "12px" }}>
              <FormControl fullWidth size="small" style={{ width: "150px" }}>
                <InputLabel id="demo-select-small-label">โซน</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="โซน"
                  value={selectedZone}
                  onChange={handleChangeZone}
                >
                  <MenuItem value="">
                    <em>ทั้งหมด</em>
                  </MenuItem>
                  {zoneOptions}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#707070",
                color: "white",
                height: "39px",
              }}
              component="a"
              className="button-h"
              onClick={handleOpenShop}
            >
              เพิ่มข้อมูลบล็อก
            </Button>
          </div>
        </div>
        <div>
          <Table
            filteredData={sortedData}
            setOpenboxsEdit={setOpenboxsEdit}
            setBoxEdit={setBoxEdit}
            setRefreshData={setRefreshData}
          />
        </div>

        <Modal open={openshop} onClose={handleCloseShop}>
          <Box
            className="width-modal-block"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "500px",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 3,
            }}
          >
            <FormAddBlock
              openmodalboxs={setOpenShop}
              setRefreshData={setRefreshData}
              zonesName={zonesName}
            />
          </Box>
        </Modal>

        <Modal open={openboxsEdit} onClose={handleCloseboxsEdit}>
          <Box
            className="width-modal-block"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "500px",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 3,
            }}
          >
            <FormEditBlock
              openmodaleditboxs={setOpenboxsEdit}
              setRefreshData={setRefreshData}
              boxEdit={boxEdit}
              zonesName={zonesName}
            />
          </Box>
        </Modal>
      </>
    </section>
  );
}

export default BlockSize;
