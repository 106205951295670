import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import "./Salary.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { svEmployeCreate } from "../../services/expenses/employe.service";
import SwalUI from "../../components/ui/swal-ui/swal-ui";

const addDataDefault = {
  salary: "",
  department: "",
  workStartDate: moment(),
  firstName: "",
  lastName: "",
  telephoneNumber: "",
  imageNamr: "",
  imageFile: "",
  idLine: "-",
  email: "-",
  address: "",
  telegram: "-",
  wohatsApp: "-",
};

function AddEditFrom(props) {
  const { modalClose, setRefreshData } = props;
  const [searchInput, setSearchInput] = useState("");
  const [age, setAge] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    salary: false,
    department: false,
    workStartDate: false,
    firstName: false,
    lastName: false,
    telephoneNumber: false,
    imageNamr: false,
    idLine: false,
    email: false,
    address: false,
    telegram: false,
    wohatsApp: false,
  });

  const handleDateChange1 = (momentdate) => {
    setAddData((prevState) => ({
      ...prevState,
      workStartDate: momentdate,
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [selectedShop, setSelectedShop] = useState(null);

  const handleChangeBusiness = (event) => {
    const selectedValue = event.target.value;
    setAge(selectedValue);
  };

  const [openshop, setOpenShop] = useState(false);
  const handleOpenShop = () => {
    setOpenShop(true);
  };
  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const formInputRef = useRef(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      console.log(file.name);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        setAddData((prevState) => {
          return { ...prevState, imageNamr: file.name, imageFile: file };
        });
      }
    };
    input.click();
  };

  const submitData = () => {
    let isValid = true;

    let error = {
      ...isError,
      salary: addData.salary < 1,
      department: addData.department.trim().length < 1,
      firstName: addData.firstName.trim().length < 1,
      lastName: addData.lastName.trim().length < 1,
      // telephoneNumber: addData.telephoneNumber.trim().length < 10 || addData.telephoneNumber.trim().length === 10,
      telephoneNumber: addData.telephoneNumber.trim().length < 1,
      email: !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(addData.email),
      address: addData.address.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      // console.log("123");
      const formData = new FormData();
      formData.append("salary", addData.salary);
      formData.append("department", addData.department);
      formData.append(
        "workStartDate",
        addData.workStartDate.format("DD/MM/YYYY")
      );
      formData.append("firstName", addData.firstName);
      formData.append("lastName", addData.lastName);
      formData.append("telephoneNumber", addData.telephoneNumber);
      formData.append("Thumbnail", addData.imageFile);
      formData.append("ThumbnailName", addData.imageNamr);
      formData.append("idLine", addData.idLine);
      formData.append("email", addData.email);
      formData.append("address", addData.address);
      formData.append("telegram", addData.telegram);
      formData.append("wohatsApp", addData.wohatsApp);

      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      svEmployeCreate(formData).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            description: res.data.description,
          });
          modalClose();
        }
      });
    }
    // const formData = new FormData();
    // console.log(formInputRef.current.files.FileList);
    // formData.append("profileImage", formInputRef.current.files[0]);
    // formData.append("NameShop", selectedShop.NameShop);
    // console.log(formData.get("profileImage"));
    // modalClose();
  };

  return (
    <>
      <div className="box-shop">
        {/* <div className="herder-shop">
          <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
            ข้อมูลพนักงาน
          </h1>
          <div className="button-shop"></div>
        </div> */}
        <div className="from-shopSalary">
          <div className="figure-container">
            <figure className="figure" style={{ cursor: "pointer" }}>
              {selectedImage && (
                <img
                  className="content-images"
                  src={selectedImage}
                  alt="Selected"
                />
              )}

              {/* แสดงรูปภาพที่เลือก */}
              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={previewImageHandler}
                  // ref={formInputRef}
                  style={{ display: "none" }}
                />
              </div>
              {selectedImage ? (
                ""
              ) : (
                <div className="figure-text">
                  <InsertPhotoOutlinedIcon
                    fontSize="large"
                    style={{ color: "grey" }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#707070",
                      text: "center",
                    }}
                  >
                    อัปโหลด รูปภาพพนักงาน
                  </p>
                </div>
              )}
            </figure>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                width: "108px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              onClick={openFileUploader}
            >
              อัปโหลด
            </Button>
          </div>

          <div className="text-from-shop">
            <TextField
              size="small"
              label="เงินเดือน"
              variant="outlined"
              value={addData.salary}
              error={isError.salary}
              onChange={(event) => {
                const value = event.target.value;
                if (/^\d*$/.test(value)) {
                  setAddData((prevState) => {
                    return { ...prevState, salary: value };
                  });
                }
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
            <TextField
              size="small"
              label="ตำแหน่งงาน"
              variant="outlined"
              // value={selectedShop ? selectedShop.NameShop : ""}
              value={addData.department}
              error={isError.department}
              onChange={(event) =>
                setAddData((prevState) => {
                  return { ...prevState, department: event.target.value };
                })
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันเริ่มงาน"
                inputFormat="DD / MM / YYYY"
                value={addData.workStartDate}
                onChange={handleDateChange1}
                sx={{ width: "33.33%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>

        {/* ข้อมูลพนักงาน */}
        <div className="from-Salary">
          <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
            ข้อมูลพนักงาน
          </h1>
          <div className="from-box-information">
            <div className="from-1">
              <TextField
                size="small"
                label="ชื่อ"
                variant="outlined"
                // value={selectedShop ? selectedShop.Firstname : ""}
                value={addData.firstName}
                error={isError.firstName}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, firstName: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="นามสกุล"
                variant="outlined"
                // value={selectedShop ? selectedShop.Lastname : ""}
                value={addData.lastName}
                error={isError.lastName}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, lastName: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="เบอร์โทรศัพท์"
                variant="outlined"
                value={addData.telephoneNumber}
                error={isError.telephoneNumber}
                onChange={(event) => {
                  const value = event.target.value;
                  if (/^\d*$/.test(value)) {
                    setAddData((prevState) => {
                      return {
                        ...prevState,
                        telephoneNumber: value,
                      };
                    });
                  }
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
              <TextField
                size="small"
                label="ไอดี ไลน์"
                variant="outlined"
                // value={selectedShop ? selectedShop.idline : ""}
                value={addData.idLine}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, idLine: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="อีเมล"
                variant="outlined"
                // value={selectedShop ? selectedShop.email : ""}
                value={addData.email}
                error={isError.email}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, email: event.target.value };
                  })
                }
              />
            </div>
            <div className="from-2">
              {/* <textarea 
                aria-label="empty textarea"
                placeholder="ที่อยู่"
                // value={selectedShop ? selectedShop.note : ""}
                value={addData.address}
                error={isError.address}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, address: event.target.value };
                  })
                }
                style={{
                  border: "1px solid #808080",
                  borderRadius: "5px",
                  padding: "8px",
                  width: "100%",
                  height: "170px",
                  resize: "vertical",
                }}
              /> */}
              <TextField
                id="outlined-multiline-static"
                label="ที่อยู่"
                multiline
                rows={4}
                // defaultValue="Default Value"
                value={addData.address}
                error={isError.address}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, address: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="เทเลแกรม"
                variant="outlined"
                // value={selectedShop ? selectedShop.talagram : ""}
                value={addData.telegram}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, telegram: event.target.value };
                  })
                }
              />
              <TextField
                size="small"
                label="WhatsApp"
                variant="outlined"
                // value={selectedShop ? selectedShop.whatsapp : ""}
                value={addData.wohatsApp}
                onChange={(event) =>
                  setAddData((prevState) => {
                    return { ...prevState, wohatsApp: event.target.value };
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: "16px",
            background: "#6C5DD3",
            color: "white",
            height: "39px",
            width: "100px",
          }}
          component="a"
          // href="/add-block"
          className="button-h"
          onClick={submitData}
        >
          บันทึก
        </Button>
      </div>
    </>
  );
}

export default AddEditFrom;
