import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { Modal } from '@mui/material';
import moment from "moment";
import './BillingCycleUpdate.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SwalUI from '../../../components/ui/swal-ui/swal-ui';
import {
  svCreateBillingCycle,
  svGetOldOutstanding,
} from '../../../services/overalldisplay/billingelectricitybill.service';

function BillingCycleUpdate(props) {
  const { selectedRowsData, setSelectedRowsData, setOpen, setRefreshData, refreshData } =
    props;

  // const [oldOutstanding, setOldOutstanding] = useState(0);
  // const [addData, setAddData] = useState({
  //   shopId: selectedRowsData.shopId,
  //   tenantId:selectedRowsData.tenantId,
  //   id: selectedRowsData.id,
  //   cost_per_day: selectedRowsData.bills_electricity?.cost_per_day || 0,
  //   additional:selectedRowsData.bills_electricity?.additional || 0 ,
  //   outstanding: selectedRowsData.bills_electricity?.outstanding,
  //   total_expenses: selectedRowsData.bills_electricity?.total_expenses,
  //   status: selectedRowsData.bills_electricity?.status,
  //   dateendrent: selectedRowsData.dateendrent
  //   ,
  // });
  /*const [isError, setIsError] = useState({
    electricity_bill: false,
    electrical_bill_status: false,
  });*/

  useEffect(() => {
    // console.log(selectedRowsData);
    const newSelectedRowsData = selectedRowsData.map((row) => {
      if (
        row.bills_electricity != null &&
        row.bills_electricity.status === 'ยังไม่จ่าย'
      ) {
        // เปลี่ยน index ตามที่ต้องการ
        return {
          ...row,
          bills_electricity: {
            ...row.bills_electricity,
            cost_per_day: 0,
            additional: 0,
            outstanding: row.bills_electricity.total_expenses,
            status: 'จ่ายแล้ว',
            created_at: null,
          },
        };
      } else {
        return {
          ...row,
          bills_electricity: {
            cost_per_day: 0,
            additional: 0,
            outstanding: 0,
            status: 'จ่ายแล้ว',
            created_at: null,
          },
        };
      }
      // return row;
    });
    setSelectedRowsData(newSelectedRowsData);
  }, [refreshData]);

  // useEffect(() => {
  //   const stepSelectedRowsData = selectedRowsData.map((row, index) => {
  //     if (row.bills_electricity.status === 'ยังไม่จ่าย') {
  //       // ถ้าสถานะเป็น "ยังไม่จ่าย" ให้ตั้งค่า outstanding เป็น total_expenses
  //       return {
  //         ...row,
  //         bills_electricity: {
  //           ...row.bills_electricity,
  //           outstanding: row.bills_electricity.total_expenses,
  //         },
  //       };
  //     }
  // })
  // setSelectedRowsData(stepSelectedRowsData);
  // }, [])

  // useEffect(() => {
  //   console.log(oldOutstanding)
  // }, [oldOutstanding])

  const calculateTotalAmount = () => {
    return Math.round(
      selectedRowsData.reduce((acc, block) => {
        const blockTotal =
          parseFloat(block.bills_electricity?.cost_per_day || 0) +
          parseFloat(block.bills_electricity?.additional || 0) +
          parseFloat(block.bills_electricity?.outstanding || 0);
        return acc + blockTotal;
      }, 0)
    ).toLocaleString('en-US');
  };

  // console.log('selectedRowsData Ta', selectedRowsData);

  const calculateAverageRent = (index) => {
    const block = selectedRowsData[index];
    const total =
      parseFloat(block.bills_electricity?.cost_per_day || 0) +
      parseFloat(block.bills_electricity?.additional || 0) +
      parseFloat(block.bills_electricity?.outstanding || 0);
    return total.toLocaleString('en-US', { minimumFractionDigits: 2 });
  };

  const handleElectricityChange = (event, index) => {
    // console.log(event.target.value);
    // const newValue = parseFloat(event.target.value.replace(/,/g, '')) || 0;
    const newValue = parseFloat(event.target.value) || 0;
    const newSelectedRowsData = [...selectedRowsData];
    newSelectedRowsData[index].bills_electricity = {
      ...selectedRowsData[index].bills_electricity,
      cost_per_day: newValue,
    };
    // console.log(newSelectedRowsData);
    setSelectedRowsData(newSelectedRowsData);
  };

  const handleElectricalAppliancesChange = (event, index) => {
    const newValue = parseFloat(event.target.value.replace(/,/g, '')) || 0;
    // console.log(newValue);
    const newSelectedRowsData = [...selectedRowsData];
    newSelectedRowsData[index].bills_electricity = {
      ...selectedRowsData[index].bills_electricity,
      additional: newValue,
    };
    setSelectedRowsData(newSelectedRowsData);
  };

  const handleOutstandingChange = (event, index) => {
    const newValue = parseFloat(event.target.value.replace(/,/g, '')) || 0;
    console.log(newValue);
    const newSelectedRowsData = [...selectedRowsData];
    newSelectedRowsData[index].bills_electricity = {
      ...selectedRowsData[index].bills_electricity,
      outstanding: newValue,
    };
    setSelectedRowsData(newSelectedRowsData);
  };

  const handleChangeStatus = (event, index) => {
    const newValue = event.target.value;
    console.log(newValue);
    const newSelectedRowsData = [...selectedRowsData];
    newSelectedRowsData[index].bills_electricity = {
      ...selectedRowsData[index].bills_electricity,
      status: newValue,
    };
    setSelectedRowsData(newSelectedRowsData);
  };

  const addValidators = () => {
    console.log(selectedRowsData);
    let error = selectedRowsData.every(item => item.bills_electricity.cost_per_day !== 0);
    console.log(error);
    if (error) {
      svCreateBillingCycle(selectedRowsData)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            description: res.data.message,
          });
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }else{
    SwalUI({
      status: error,
      description: 'กรุณากรอกข้อมูลค่าไฟให้ครบ',
    });
  }
    }
 

  const handleClose = () => {
    setOpen(false);
  };

  const handleEndDateChange = (date, index) => {
    const newValue = moment(date).format('YYYY-MM-DD');
    const newSelectedRowsData = [...selectedRowsData];
    newSelectedRowsData[index].bills_electricity = {
      ...selectedRowsData[index].bills_electricity,
      created_at: newValue,
    };
    setSelectedRowsData(newSelectedRowsData);
  };

  return (
    <section id="products-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          gap: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div className="total-billingcycle">
          <div style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
            อัปเดตข้อมูลค่าไฟ
          </div>
          <div className="block-total">
            <p>ยอดรวมค่าไฟทั้งหมด : </p>
            <p>{calculateTotalAmount()} บาท</p>
          </div>
        </div>
      </div>

      {selectedRowsData.map((block, index) => (
        <div key={index} style={{ marginTop: '1rem' }}>
          <div className="box-store-expiration1">
            <div className="store1">
              {index === 0 && <p className="p1">รายละเอียดค่าไฟ</p>}
              <p style={{ fontSize: '16px', fontWeight: '400' }}>
                บล็อกที่เช่า : {block.zone_name + block.zone_number}
              </p>
              <div className="from-box1">
                <TextField
                  id={`filled-multiline-flexible-${index}`}
                  label="ค่าไฟ/ วัน"
                  value={
                    block.bills_electricity
                      ? block.bills_electricity.cost_per_day
                      : 0
                  }
                  onChange={(event) => handleElectricityChange(event, index)}
                  multiline
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: 'white',
                      border: '1px solid black',
                      border: '1px solid #808080',
                      borderRadius: '5px',
                    },
                    inputProps: {
                      pattern: '[0-9]', // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                />

                <TextField
                  id={`filled-multiline-flexible-${index}`}
                  label="ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม"
                  multiline
                  maxRows={4}
                  variant="filled"
                  value={
                    block.bills_electricity
                      ? block.bills_electricity.additional
                      : 0
                  }
                  onChange={(event) =>
                    handleElectricalAppliancesChange(event, index)
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: 'white',
                      border: '1px solid black',
                      border: '1px solid #808080',
                      borderRadius: '5px',
                    },
                    inputProps: {
                      pattern: '[0-9]', // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                />
              </div>
            </div>

            <div className="store4">
              {index === 0 && <p className="p1">สถานะการจ่าย</p>}
              <p style={{ fontSize: '16px', fontWeight: '400' }}>
                บล็อกที่เช่า : {block.zone_name + block.zone_number}
              </p>
              <div className="from-box2">
                <div className="" style={{ width: '100%' }}>
                  <TextField
                    id={`filled-multiline-flexible-${index}`}
                    label="ค่าคงค้าง"
                    onChange={(event) => handleOutstandingChange(event, index)}
                    // value={oldOutstanding}
                    value={
                      block.bills_electricity == null
                        ? 0
                        : block.bills_electricity.outstanding
                    }
                    multiline
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        background: 'white',
                        border: '1px solid black',
                        border: '1px solid #808080',
                        borderRadius: '5px',
                      },
                      inputProps: {
                        pattern: '[0-9]', // Regular expression allowing only numbers
                      },
                    }}
                    onInput={(event) => {
                      const { value } = event.target;
                      const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                      event.target.value = newValue; // Set the new value
                    }}
                  />
                </div>

                <FormControl style={{ width: '100%' }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{margin:"6px"}}>
                    สถานะการจ่าย
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label" //
                    id="demo-select-small" //
                    defaultValue={'จ่ายแล้ว'}
                    // value={age}
                    // label="สถานะการจ่าย บล็อก A01"
                    // onChange={handleChangeStatus}
                    onChange={(event) => handleChangeStatus(event, index)}
                  >
                    <MenuItem value={'ยังไม่จ่าย'}>ยังไม่จ่าย</MenuItem>
                    <MenuItem value={'จ่ายแล้ว'}>จ่ายแล้ว</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="store3">
            {index === 0 && <p className="p1">ค่าไฟย้อนหลัง</p>}
              <p style={{ fontSize: '16px', fontWeight: '400' }}>
                บล็อกที่เช่า : {block.zone_name + block.zone_number}
              </p>
              <div className="from-box2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="วันที่เก็บค่าเช่า"
                    inputFormat="DD/MM/YYYY"
                    value={block.bills_electricity?.created_at || null}
                    onChange={(date) => handleEndDateChange(date, index)}
                    sx={{ width: '33.33%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: '100%' }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          sx: { padding: '16px' },
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="store2">
              {index === 0 && <p className="p1">รอบการจ่ายรายวัน</p>}
              <p style={{ fontSize: '16px', fontWeight: '400' }}>
                ยอดรวมค่าไฟบล็อก {block.zone_name + block.zone_number}
              </p>
              <div className="from-box2">
                <TextField
                  id={`filled-multiline-flexible-${index}`}
                  label="ยอดรวมค่าไฟทั้งหมด"
                  value={calculateAverageRent(index)}
                  multiline
                  maxRows={4}
                  variant="filled"
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: 'white',
                      border: '1px solid black',
                      border: '1px solid #808080',
                      borderRadius: '5px',
                      backgroundColor: '#F3F4F6',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="status-button">
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
          }}
          component="a"
          className="button-h"
          onClick={handleClose}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#6C5DD3',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
          className="button-h"
          onClick={addValidators}
        >
          บันทึกข้อมูล
        </Button>
      </div>
    </section>
  );
}

export default BillingCycleUpdate;
