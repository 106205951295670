import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import "./RentalIncomePage.scss";
import RadioGroup from "@mui/material/RadioGroup";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { getRentalincome } from "../../services/rentalincome.service";
import { getRentData } from "../../services/rent.service";

const RentalIncomePage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("tambon3");
  const [selectedColumn, setSelectedColumn] = useState("all");

  const isValidDate = (date) => date !== null && dayjs(date).isValid();

  const [rentData, setRentData] = useState([]);
  console.log("rentData", rentData);

  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    getRentData().then((res) => {
      // console.log(res);
      const rentDataRes = res.data.rent;
      setRentData(rentDataRes);
    });
  }, []);

  // useEffect(() => {
  //   getRentalincome().then((res) => {
  //     if (res.status === 200) {
  //       setRentData(res.data.rent);
  //     }
  //   });
  // }, []);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const rowsFiltered = rentData.filter((row) => {
    let rentalStatusFilter = false;

    if (selectedValue === "tambon1" && row.RentalStatus == 1) {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon2" && row.RentalStatus == 0) {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon3") {
      rentalStatusFilter = true;
    }

    if (
      selectedValue === "a" ||
      selectedValue === "b" ||
      selectedValue === "c" ||
      selectedValue === "d"
    ) {
      const startDate = dayjs(row.RentalDate, "DD/MM/YYYY");
      // const endDate = dayjs(row.Endrent, "DD/MM/YYYY");

      const dayFilter =
        selectedValue === "a" &&
        isValidDate(selectedDate1) &&
        startDate.date() === selectedDate1.date() &&
        startDate.month() === selectedDate1.month() &&
        startDate.year() === selectedDate1.year();
      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        startDate.month() === selectedDate2.month() &&
        startDate.year() === selectedDate2.year();
      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        startDate.year() === selectedDate3.year();
      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(selectedStartDate - 1).isBefore(startDate) &&
        dayjs(selectedEndDate + 1).isAfter(startDate);

      const dateFilter =
        dayFilter || monthFilter || yearFilter || dateRangeFilter;

      return dateFilter;
    }

    if (
      (age === "" || row.zone_name === age) &&
      (row.BlockNumber.includes(searchInput) ||
        row.tenantName.includes(searchInput)) &&
      rentalStatusFilter
    ) {
      return true;
    } else {
      return false;
    }
  });

  const rowsFilteredSorted = rowsFiltered.sort((a, b) => {
    if (a.zone_name < b.zone_name) {
      return -1;
    }
    if (a.zone_name > b.zone_name) {
      return 1;
    }

    const getBlockNumber = (blockNumber) => {
      const match = blockNumber.match(/(\d+)/);
      return match ? parseInt(match[0], 10) : 0;
    };

    const aBlockNumber = getBlockNumber(a.BlockNumber);
    const bBlockNumber = getBlockNumber(b.BlockNumber);

    return aBlockNumber - bBlockNumber;
  });

  const exportToExcel = () => {
    const depositHeaders = {
      // ชื่อร้านที่เช่า: "RentalShopName",
      ชื่อผู้เช่าร้าน: "tenantName",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่จ่ายล่าสุด: "Endrent",
      บล็อกที่อยู่: "BlockNumber",
      ค่ามัดจำเต็นท์: "TentDeposit",
      // ค่าไฟต่อเดือน: "ElectricityBill",
      ค่ามัดจำ: "Deposit",
    };

    const rentalHeaders = {
      // ชื่อร้านที่เช่า: "RentalShopName",
      ชื่อผู้เช่าร้าน: "tenantName",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่ย้ายออก: "Endrent",
      บล็อกที่อยู่: "BlockNumber",
      ค่ามัดจำเต็นท์: "TentDeposit",
      // ค่าไฟต่อเดือน: "ElectricityBill",
      ค่าเช่า: "Rent",
    };

    const fineHeaders = {
      // ชื่อร้านที่เช่า: "RentalShopName",
      ชื่อผู้เช่าร้าน: "tenantName",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่ย้ายออก: "Endrent",
      บล็อกที่อยู่: "BlockNumber",
      ค่ามัดจำเต็นท์: "TentDeposit",
      // ค่าไฟต่อเดือน: "ElectricityBill",
      ค่าปรับ: "Fine",
    };

    const otherHeaders = {
      // ชื่อร้านที่เช่า: "RentalShopName",
      ชื่อผู้เช่าร้าน: "tenantName",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่ย้ายออก: "Endrent",
      บล็อกที่อยู่: "BlockNumber",
      ค่ามัดจำเต็นท์: "TentDeposit",
      // ค่าไฟต่อเดือน: "ElectricityBill",
      ค่าเช่าค้างจ่าย: "Overdue",
    };

    const allHeaders = {
      // ชื่อร้านที่เช่า: "RentalShopName",
      ชื่อผู้เช่าร้าน: "tenantName",
      เบอร์ติดต่อTH: "tenant_nickname",
      เบอร์ติดต่อKH: "tenant_phone",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่ย้ายออก: "Endrent",
      บล็อกที่อยู่: "BlockNumber",
      ค่ามัดจำเต็นท์: "TentDeposit",
      // ค่าไฟต่อเดือน: "ElectricityBill",
      ค่ามัดจำ: "Deposit",
      ค่าเช่า: "Rent",
      ค่าเช่าค้างจ่าย: "Overdue",
      ค่าปรับ: "Fine",
    };

    let headers;

    switch (selectedColumn) {
      case "deposit":
        headers = depositHeaders;
        break;
      case "rental":
        headers = rentalHeaders;
        break;
      case "fine":
        headers = fineHeaders;
        break;
      case "other":
        headers = otherHeaders;
        break;
      default:
        headers = allHeaders;
    }

    console.log(headers); // เพื่อเช็คการสร้าง headers ที่ถูกต้อง

    const mappedRows = rowsFiltered.map((row) => {
      const mappedRow = {};
      for (const key in headers) {
        mappedRow[key] = row[headers[key]];
      }
      return mappedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [["รายงานรายได้ค่าเช่า ค่าปรับ"]], {
      origin: "A1",
    });
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }];
    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(headers)], {
      origin: "A2",
    });

    XLSX.utils.sheet_add_json(worksheet, mappedRows, {
      origin: "A3",
      skipHeader: true,
    });

    const dataTotal = [
      { p: "" },
      { p: "ผลรวมค่าต่างๆ" },
      { p: "ค่ามัดจำ : ", price: formattedTotalDeposit },
      { p: "ค่าเช่า : ", price: formattedTotalRent },
      { p: "ค่าปรับ : ", price: formattedTotalFine },
      { p: "ค่าเช่าค้างจ่าย :", price: formattedTotalMoney },
      { p: "ยอดรวมทั้งหมด : ", price: formattedTotalAll },
    ];

    const dataTotalRows = dataTotal.map((item) => [item.p, item.price]);
    XLSX.utils.sheet_add_aoa(worksheet, dataTotalRows, {
      origin: "A" + (mappedRows.length + 4),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(data, "FilteredData.xlsx");
  };

  const handleColumnChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(
      imageUrl ? `${uploadPath}${imageUrl}` : "/images/no-image.png"
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    setSelectedValue("tambon3");
  };

  const allColumns = [
    {
      field: "BlockNumber",
      headerName: "บล็อกที่อยู่",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    // {
    //   field: "ImgShop",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 120,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <img
    //       src={uploadPath + params.value}
    //       alt="Shop"
    //       style={{
    //         width: 100,
    //         borderRadius: "0.375rem",
    //         width: "59px",
    //         height: "59px",
    //       }}
    //       onClick={() => handleOpen(params.value)}
    //     />
    //   ),
    // },
    {
      field: "tenantName",
      headerName: "ชื่อผู้เช่าร้าน",
      sortable: false,
      width: 150,
    },
    {
      field: "tenant_nickname",
      headerName: "เบอร์ติดต่อ (TH)",
      sortable: false,
      width: 135,
    },
    {
      field: "tenant_phone",
      headerName: "เบอร์ติดต่อ (KH)",
      sortable: false,
      width: 135,
    },
    {
      field: "RentalDate",
      headerName: "วันที่เริ่มเช่า",
      sortable: false,
      width: 150,
    },
    {
      field: "Endrent",
      headerName: "วันที่จ่ายล่าสุด",
      sortable: false,
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "--/--/--";
        }
        return params.value;
      },
    },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 150,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Deposit",
      headerName: "ค่ามัดจำ",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    // {
    //   field: "ElectricityBill",
    //   headerName: "ค่าไฟ/เดือน",
    //   width: 150,
    //   valueFormatter: (params) => {
    //     if (params.value == null) {
    //       return "----฿";
    //     }
    //     return new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);
    //   },
    // },
    {
      field: "Overdue",
      headerName: "ค่าเช่าค้างจ่าย",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "----฿";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Fine",
      headerName: "ค่าปรับ",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "----฿";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
  ];

  // ค่ามัดจำ
  const depositColumns = allColumns.filter(
    (column) =>
      column.field === "ImgShop" ||
      column.field === "RentalShopName" ||
      column.field === "tenantName" ||
      column.field === "RentalDate" ||
      column.field === "Endrent" ||
      column.field === "BlockNumber" ||
      column.field === "TentDeposit" ||
      column.field === "ElectricityBill" ||
      column.field === "Deposit"
  );

  // ค่าเช่า
  const rentalColumns = allColumns.filter(
    (column) =>
      column.field === "ImgShop" ||
      column.field === "RentalShopName" ||
      column.field === "tenantName" ||
      column.field === "RentalDate" ||
      column.field === "Endrent" ||
      column.field === "BlockNumber" ||
      column.field === "TentDeposit" ||
      column.field === "ElectricityBill" ||
      column.field === "Rent"
  );

  // ค่าปรับ
  const fineColumns = allColumns.filter(
    (column) =>
      column.field === "ImgShop" ||
      column.field === "RentalShopName" ||
      column.field === "tenantName" ||
      column.field === "RentalDate" ||
      column.field === "Endrent" ||
      column.field === "BlockNumber" ||
      column.field === "TentDeposit" ||
      column.field === "ElectricityBill" ||
      column.field === "Fine"
  );

  // ค่าเช่าค้างจ่าย
  const otherColumns = allColumns.filter(
    (column) =>
      column.field === "ImgShop" ||
      column.field === "RentalShopName" ||
      column.field === "tenantName" ||
      column.field === "RentalDate" ||
      column.field === "Endrent" ||
      column.field === "BlockNumber" ||
      column.field === "TentDeposit" ||
      column.field === "ElectricityBill" ||
      column.field === "Overdue"
  );

  const columns = (() => {
    switch (selectedColumn) {
      case "deposit":
        return depositColumns;
      case "rental":
        return rentalColumns;
      case "fine":
        return fineColumns;
      case "other":
        return otherColumns;
      default:
        return allColumns;
    }
  })();

  const zones = Array.from(new Set(rentData.map((row) => row.Zone)));

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalDeposit += row.Deposit || 0;
      accumulator.totalRent += row.Rent || 0;
      accumulator.totalWaterBill += row.WaterBill || 0;
      accumulator.totalElectricityBill += row.ElectricityBill || 0;
      accumulator.totalTotal += row.Overdue || 0;
      accumulator.totalFine += row.Fine || 0;
      return accumulator;
    },
    {
      totalDeposit: 0,
      totalRent: 0,
      totalWaterBill: 0,
      totalElectricityBill: 0,
      totalTotal: 0,
      totalFine: 0,
    }
  );

  const {
    totalDeposit,
    totalRent,
    totalWaterBill,
    totalElectricityBill,
    totalTotal,
    totalFine,
  } = totals;

  const formattedTotalDeposit = totalDeposit.toLocaleString();
  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalWaterBill = totalWaterBill.toLocaleString();
  const formattedTotalElectricityBill = totalElectricityBill.toLocaleString();
  const formattedTotalMoney = totalTotal.toLocaleString();
  const formattedTotalFine = totalFine.toLocaleString();

  console.log("ผลรวมค่ามัดจำ:", formattedTotalDeposit);
  console.log("ผลรวมค่าเช่า:", formattedTotalRent);
  console.log("ผลรวมค่าน้ำ:", formattedTotalWaterBill);
  console.log("ผลรวมค่าไฟ:", formattedTotalElectricityBill);
  console.log("ผลรวมค่าไฟ:", formattedTotalMoney);
  console.log("ผลรวมค่าไฟ:", formattedTotalFine);

  const totalAll =
    totalDeposit +
    totalRent +
    totalWaterBill +
    totalElectricityBill +
    totalTotal +
    totalFine;
  const formattedTotalAll = totalAll.toLocaleString();
  console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  const dataTotal = [
    { p: "ค่ามัดจำ : ", price: formattedTotalDeposit },
    { p: "ค่าเช่า : ", price: formattedTotalRent },
    { p: "ค่าปรับ : ", price: formattedTotalFine },
    { p: "ค่าเช่าค้างจ่าย : ", price: formattedTotalMoney },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          รายงานรายได้ค่าเช่า ค่าปรับ
        </div>
        <div className="search">
          <TextField
            label="ค้นหา บล็อก,ชื่อ"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
        </div>
      </div>

      <div className="from-rental">
        {/* บล็อกด้านขวา */}
        <div className="from-filter1">
          <div className="button-text">
            <p style={{ fontWeight: 500, fontSize: "18px" }}>เลือก</p>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "14px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
                // marginTop: "2rem",
              }}
              className="button-h"
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </div>

          <div className="from-Date-filter1">
            {/* <div className="date-1">
              <Radio {...controlProps("a")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                วัน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วัน"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "a"}
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  views={["day", "month", "year"]}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div> */}
            <div className="date-2">
              <Radio {...controlProps("b")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                เดือน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="เดือน"
                  inputFormat="MM/YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "b"}
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-3">
              <Radio {...controlProps("c")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ปี
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ปี"
                  inputFormat="YYYY"
                  views={["year"]}
                  disabled={selectedValue !== "c"}
                  value={selectedDate3}
                  onChange={handleDateChange3}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-4">
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel id="select-label-tambon">สถานะการเช่า</InputLabel>
                <Select
                  labelId="select-label-tambon"
                  id="select-tambon"
                  label="สถานะการจ่าย"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <MenuItem value="tambon1">เช่าอยู่</MenuItem>
                  <MenuItem value="tambon2">ย้ายออก</MenuItem>
                  <MenuItem value="tambon3">ทั้งหมด</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="date-5">
              <Radio {...controlProps("d")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ตั้งแต่
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ตั้งแต่"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "d"}
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-6">
              <p
                className="date-2-text"
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginRight: "19px",
                }}
              >
                จนถึง
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="จนถึง"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "d"}
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-7">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedColumn}
                onChange={handleColumnChange}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio size="small" />}
                  label="ทั้งหมด"
                />
                <FormControlLabel
                  value="deposit"
                  control={<Radio size="small" />}
                  label="ค่ามัดจำ"
                />
                <FormControlLabel
                  value="rental"
                  control={<Radio size="small" />}
                  label="ค่าเช่า"
                />
                <FormControlLabel
                  value="fine"
                  control={<Radio size="small" />}
                  label="ค่าปรับ"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio size="small" />}
                  label="ค่าเช่าค้างจ่าย"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="data-box2">{totalData}</div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RentalIncomePage;
