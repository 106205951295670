import React, { useState } from 'react';
import MyDocument from './MyDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import './InvoiceForm.scss'; // นำเข้าไฟล์ SCSS

const InvoiceForm = () => {
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showModal, setShowModal] = useState(false);

  const addItem = () => {
    const newItem = { name: '', quantity: 1, price: 0 };
    setItems([...items, newItem]);
  };

  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handlePrint = () => {
    setShowModal(true);
  };

  const handleDownload = () => {
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h2>Invoice Form</h2>
      <button onClick={addItem}>Add Item</button>
      {items.map((item, index) => (
        <div key={index}>
          <input
            type="text"
            name="name"
            value={item.name}
            onChange={(e) => handleChange(e, index)}
            placeholder="Item Name"
          />
          <input
            type="number"
            name="quantity"
            value={item.quantity}
            onChange={(e) => handleChange(e, index)}
            placeholder="Quantity"
          />
          <input
            type="number"
            name="price"
            value={item.price}
            onChange={(e) => handleChange(e, index)}
            placeholder="Price"
          />
          <button onClick={() => removeItem(index)}>Remove</button>
        </div>
      ))}
      <div>
        <h3>Customer Details</h3>
        <input type="text" value={name} onChange={handleNameChange} placeholder="Name" />
        <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" />
        <input type="text" value={phone} onChange={handlePhoneChange} placeholder="Phone" />
      </div>
      <button onClick={handlePrint}>Print Invoice</button>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <MyDocument items={items} name={name} email={email} phone={phone} />
            <PDFDownloadLink document={<MyDocument items={items} name={name} email={email} phone={phone} />} fileName="invoice.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : <button onClick={handleDownload}>Download PDF</button>
              }
            </PDFDownloadLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceForm;