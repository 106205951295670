import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { rows } from "./data/TableData";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import "./ProductsaddPage.scss";
import ReceiptModal from "./ReceiptModal";
import FromAddEvidence from "./FromAddEvidence";
import {
  getBusinessType,
  getShop,
  getShopDetail,
} from "../../../services/rent.service";

const FromAddStore = ({
  selectedZone,
  selectedBlockId,
  setSelectedBlockId,
  NameShop,
  setNameShop,
  Firstname,
  setFirstName,
  selectedShop,
  setSelectedShop,
  searchResults,
  searchQuery,
  setSearchQuery,
  setSearchResults,
  setAddDataShop,
  freshData,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [age, setAge] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [openshop, setOpenShop] = useState(false);
  const [open, setOpen] = useState(false);
  const formInputRef = useRef(null);
  const [selectBusiness, setSelectBusiness] = useState([]);

  const uploadPath = useSelector((state) => state.app.uploadPath);

  // console.log("Value of nameShop:", Firstname, NameShop );

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    getShop().then((res) => {
      const shops = res.data.shop;

      // กรอง Firstname ที่ไม่ซ้ำกัน
      const uniqueShops = shops.reduce((acc, current) => {
        const x = acc.find((item) => item.Firstname === current.Firstname);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const shopResult = uniqueShops.filter((shop) =>
        shop?.Firstname?.includes(searchQuery)
      );

      setSearchResults(shopResult);
    });

    getBusinessType().then((res) => {
      const businessRes = res.data.business;
      setSelectBusiness(businessRes);
    });
  }, [searchQuery]);

  const handleResultClick = (selectedShopId) => {
    getShopDetail(selectedShopId).then((res) => {
      const shopDetail = res.data.shop;
      console.log(shopDetail);
      let thumbnail = uploadPath + shopDetail.ShopImage;

      setSelectedImage("");
      if (shopDetail.ShopImage != null) {
        setSelectedImage(thumbnail);
      }

      setSelectedShop(shopDetail);

      setSearchQuery(shopDetail.Firstname);
      setOpenShop(false);

      setAge(shopDetail.businessType);
      setSelectedShop((prevState) => {
        return { ...prevState, BusinessType: shopDetail.businessType };
      });
    });
  };

  const handleChangeBusiness = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    setAge(selectedValue);
    setSelectedShop((prevState) => {
      return { ...prevState, BusinessType: selectedValue };
    });
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };
  const handleCloseShop = () => {
    setOpenShop(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        setSelectedShop((prevState) => {
          return { ...prevState, ImageName: file.name, imageFile: file };
        });
      }
    };
    input.click();
  };

  return (
    <>
      <div className="box-shop-add">
        <div className="herder-shop-add">
          <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>ข้อมูลร้าน</h1>
          <div className="button-shop">
            <Button
              className="button-h"
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              onClick={handleOpenShop}
            >
              ค้นหาชื่อผู้เช่าที่มีอยู่
            </Button>
            {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
            <Modal open={openshop} onClose={handleCloseShop}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  p: 4,
                }}
              >
                <TextField
                  label="ค้นหาชื่อผู้เช่า"
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  autoFocus
                  sx={{ mb: 2 }}
                  InputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                />
                <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#6C5DD3",
                          }}
                        >
                          เลือกชื่อผู้เช่า
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {searchResults.map((result) => (
                        <tr
                          key={result.id}
                          onClick={() => handleResultClick(result.id)}
                          style={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "#f0f0f0" },
                          }}
                        >
                          <td
                            style={{
                              borderBottom: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            {result.Firstname}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div className="from-shop-add">
          <div className="figure-container-add">
            <figure className="figure-add" style={{ cursor: "pointer" }}>
              {selectedImage && (
                <img
                  className="content-images-add"
                  src={selectedImage}
                  alt="Selected"
                />
              )}

              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={previewImageHandler}
                  ref={formInputRef}
                  style={{ display: "none" }}
                />
              </div>
              {selectedImage ? (
                ""
              ) : (
                <div className="figure-text">
                  <InsertPhotoOutlinedIcon
                    fontSize="large"
                    style={{ color: "grey" }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#707070",
                      text: "center",
                    }}
                  >
                    อัปโหลด รูปภาพหน้าร้าน
                  </p>
                </div>
              )}
            </figure>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                width: "108px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              onClick={openFileUploader}
            >
              อัปโหลด
            </Button>
          </div>

          <div className="text-from-shop">
            <TextField
              size="small"
              label="ชื่อร้าน"
              variant="outlined"
              value={selectedShop ? selectedShop.NameShop : ""}
              onChange={(event) =>
                setSelectedShop({
                  ...selectedShop,
                  NameShop: event.target.value,
                })
              }
            />
            <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <InputLabel id="business-select-label">
                ประเภทการทำธุรกิจ
              </InputLabel>
              <Select
                labelId="business-select-label"
                id="business-select"
                value={age}
                label="ประเภทการทำธุรกิจ"
                size="small"
                onChange={handleChangeBusiness}
              >
                {selectBusiness.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          borderRadius: "3px",
                          height: "15px",
                          width: "15px",
                          backgroundColor: data.box_color,
                          marginRight: "6px",
                        }}
                      />
                      <div>{data.types_name}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <textarea
              aria-label="empty textarea"
              placeholder="รายระเอียดประเภทธุรกิจ"
              value={selectedShop ? selectedShop.note : ""}
              onChange={(event) =>
                setSelectedShop({
                  ...selectedShop,
                  note: event.target.value,
                })
              }
              style={{
                border: "1px solid #808080",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
                height: "80px",
                resize: "both",
              }}
            />
          </div>
        </div>

        <div className="from-information">
          <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>
            ข้อมูลผู้เช่า
          </h1>
          <div className="from-box-information">
            <div className="from-1">
              <TextField
                size="small"
                label="ชื่อผู้เช่า"
                variant="outlined"
                value={selectedShop ? selectedShop.Firstname : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    Firstname: event.target.value,
                  })
                }
              />
              <TextField
                size="small"
                label="เบอร์โทรศัพท์ (TH)"
                variant="outlined"
                value={selectedShop ? selectedShop.Lastname : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    Lastname: event.target.value,
                  })
                }
              />
              <TextField
                size="small"
                label="เบอร์โทรศัพท์ (KH)"
                variant="outlined"
                value={selectedShop ? selectedShop.NumberPhone : ""}
                onChange={(event) => {
                  setSelectedShop({
                    ...selectedShop,
                    NumberPhone: event.target.value,
                  });
                  // const inputText = event.target.value;
                  // if (!isNaN(inputText)) {
                  //   setSelectedShop({
                  //     ...selectedShop,
                  //     NumberPhone: inputText,
                  //   });
                  // }
                }}
              />
            </div>
            <div className="from-2">
              <TextField
                size="small"
                label="ไอดี ไลน์"
                variant="outlined"
                value={selectedShop ? selectedShop.idline : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    idline: event.target.value,
                  })
                }
              />
              <TextField
                size="small"
                label="Telegram"
                variant="outlined"
                value={selectedShop ? selectedShop.telegram : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    telegram: event.target.value,
                  })
                }
              />
              <TextField
                size="small"
                label="WhatsApp"
                variant="outlined"
                value={selectedShop ? selectedShop.WhateApp : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    WhateApp: event.target.value,
                  })
                }
              />
            </div>
            <div className="from-3">
              <TextField
                size="small"
                label="ชื่อ-นามสกุล บุคคลที่ติดต่อได้"
                variant="outlined"
                value={selectedShop ? selectedShop.ContactPerson : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    ContactPerson: event.target.value,
                  })
                }
              />
              <TextField
                size="small"
                label="เบอร์โทรศัพท์ บุคคลที่ติดต่อได้"
                variant="outlined"
                value={selectedShop ? selectedShop.PhoneContactPerson : ""}
                onChange={(event) => {
                  setSelectedShop({
                    ...selectedShop,
                    PhoneContactPerson: event.target.value,
                  });
                  // const inputText = event.target.value;
                  // if (!isNaN(inputText)) {
                  //   setSelectedShop({
                  //     ...selectedShop,
                  //     PhoneContactPerson: inputText,
                  //   });
                  // }
                }}
              />
              <TextField
                size="small"
                label="อีเมล"
                variant="outlined"
                value={selectedShop ? selectedShop.email : ""}
                onChange={(event) =>
                  setSelectedShop({
                    ...selectedShop,
                    email: event.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FromAddStore;
