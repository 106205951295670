import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { Typography, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import BillingCycleUpdate from "./BillingCycleUpdate/BillingCycleUpdate";
import BillingCycleEditData from "../historyElectricWater/detailHistoryElectricWater/components/BillingCycleEditData";
import Swal from "sweetalert2";
import moment from "moment";
import { svBillingElectricityBillData } from "../../services/overalldisplay/billingelectricitybill.service";
import "./BillingCyclePage.scss";

const BillingCyclePage = () => {
  const webPath = useSelector((state) => state.app.uploadPath);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState({
    nameShop: false,
    nameTenant: false,
  });
  const [age, setAge] = useState("");
  // const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedShop, setSelectedShop] = useState(null);

  const [openshop, setOpenShop] = useState(false);
  const [openshopE, setOpenShopE] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [shopBillingData, setShopBillingData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [totalAll, setTotalAll] = useState([]);
  const [lodtotalOutstanding, setLodtotalOutstanding] = useState();

  useEffect(() => {
    svBillingElectricityBillData().then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        setShopBillingData(res.data.billingcycle);
        setTotalAll(res.data.totalElectricityBill);
        setLodtotalOutstanding(res.data.lodtotalElectricityBill);
      }
    });
  }, [refreshData]);

  const onRowsSelectionHandler = (ids) => {
    // console.log(ids);
    const selectedRowsData = ids.map((id) =>
      shopBillingData.find((row) => row.id === id)
    );
    console.log(selectedRowsData);
    setSelectedRowsData(selectedRowsData);
  };

  // useEffect(() => {
  //   console.log(shopBillingData)
  // }, [shopBillingData])

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleOpenShopE = () => {
    if (selectedRowsData && selectedRowsData.length > 0) {
      setOpenShopE(true);
    } else {
      Swal.fire({
        title: "เลือกข้อมูลค่าไฟก่อน",
        icon: "error",
      });
    }
  };

  const handleCloseShopE = () => {
    setOpenShopE(false);
  };
  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    // console.log('123');
    setAge(event.target.value);
  };

  // const searchSupplier = (e) => {
  //   setSearchInput(e.target.value);
  // };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterDuplicatesInModal = (data) => {
    const uniqueNames = new Set();
    return data.filter((item) => {
      if (uniqueNames.has(item.tenant.name)) {
        return false;
      }
      uniqueNames.add(item.tenant.name);
      return true;
    });
  };
  const handleResultClick = (selectedNameShop) => {
    const shop = shopBillingData.find(
      (row) => row.RentalShopName === selectedNameShop
    );
    setSelectedShop(shop);
    setSearchQuery(selectedNameShop);
    setOpenShop(false);
  };

  const currentDate = moment().format("DD/MM/YYYY");

  const columns = [
    {
      field: "dateendrent",
      headerName: "วันที่ชำระค่าไฟ",
      width: 176,
      renderCell: (params) => {
        const DateValue = params.value;

        if (!DateValue || !DateValue.trim()) {
          return <p style={{ fontWeight: 500 }}>--/--/--</p>;
        }
        return <div style={{ fontWeight: 500 }}>{DateValue}</div>;
      },
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 176,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>
          {params.row.zone_name + params.row.zone_number}
        </div>
      ),
    },
    /* 
    {
      field: 'shop',
      headerName: 'ภาพหน้าร้าน',
      width: 170,
      renderCell: (params) => (
        <img
          src={webPath + params.row.shop.image}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: '0.375rem',
            width: '59px',
            height: '59px',
          }}
          onClick={() => handleOpen(webPath + params.row.shop.image)}
        />
      ),
    },
     {
      field: 'NameShop',
      headerName: 'ชื่อร้านที่เช่า',
      sortable: false,
      width: isHovered.nameShop ? 290 : 100,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: '0' }}
          onMouseEnter={() => setIsHovered((prevState) => {
            return {
              ...prevState,
              nameShop: true,
            };
          })}
          onMouseLeave={() => setIsHovered((prevState) => {
            return {
              ...prevState,
              nameShop: false,
            };
          })}
        >
          <p>{params.row.shop.name}</p>
        </div>
      ),
    },
    */
    {
      field: "Name",
      headerName: "ชื่อผู้เช่า",
      sortable: false,
      width: isHovered.nameTenant ? 200 : 176,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          onMouseEnter={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                nameTenant: true,
              };
            })
          }
          onMouseLeave={() =>
            setIsHovered((prevState) => {
              return {
                ...prevState,
                nameTenant: false,
              };
            })
          }
        >
          <p>{params.row.tenant.name}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 176,
      renderCell: (params) => {
        let color;
        // switch (params.value) {
        //   case 'อาหาร':
        //     color = '#FFCE72';
        //     break;
        //   case 'เครื่องดื่ม':
        //     color = '#3F8CFF';
        //     break;
        //   case 'เสื้อผ้าแฟร์ชั่น':
        //     color = '#FF9CBD';
        //     break;
        //   case 'อุปกรณ์':
        //     color = '#6C5DD3';
        //     break;
        //   default:
        //     color = 'inherit';
        // }
        if (params.row.shop?.types_name) {
          color = params.row.shop.box_color;
        } else {
          color = "inherit";
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.row.shop?.types_name}</div>
          </div>
        );
      },
    },
    {
      field: "WaterBill",
      width: 176,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.cost_per_day);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ค่าไฟ/วัน
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          ></Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.cost_per_day);

        if (!params.row.bills_electricity?.cost_per_day) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "ElectricBillsAdditional",
      width: 176,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.additional);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ค่าเครื่องใช้ไฟฟ้า/วัน
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          ></Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.additional);

        if (!params.row.bills_electricity?.additional) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "outstanding",
      width: 176,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.outstanding);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ค่าคงค้าง/วัน
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          ></Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.outstanding);

        if (!params.row.bills_electricity?.outstanding) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "MonthElectricity",
      width: 176,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.total_expenses);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            รวมค่าไฟ/วัน
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.row.bills_electricity?.total_expenses);

        if (!params.row.bills_electricity?.total_expenses) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "RentalStatus",
      headerName: "สถานะการจ่าย",
      width: 170,

      renderCell: (params) => (
        <div
          style={{
            color:
              params.row.bills_electricity?.status === "จ่ายแล้ว"
                ? "#00D715"
                : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.bills_electricity?.status}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.bills_electricity != null
              ? moment(params.row.bills_electricity.created_at)
                  .format("DD/MM/YYYY")
                  .toString()
              : "--/--/--"}
            {/*//06/09/2023 */}
          </p>
        </div>
      ),
    },
    // {
    //   field: 'Delete',
    //   headerName: 'ลบ',
    //   headerAlign: 'center',
    //   align: 'center',
    //   width: 176,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Button
    //       onClick={() => editHandle(params.row)}
    //       style={{
    //         position: 'relative',
    //         overflow: 'hidden',
    //         background: '#F9E400',
    //         color: '#fff',
    //       }}
    //       className="button-Edit"
    //     >
    //       แก้ไข
    //     </Button>
    //   ),
    // },
  ];

  const rowsFiltered = shopBillingData
    .filter((row) => {
      if (
        (age === "" || row.zone_name === age) &&
        row.tenant.name.includes(searchQuery)
      ) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) =>
      a.zone_name.localeCompare(b.zone_name, undefined, { numeric: true })
    );

  const zones = Array.from(
    new Set(shopBillingData.map((row) => row.zone_name))
  );

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalDayElectricity += row.WaterBill;
      accumulator.totalMonthElectricity += row.MonthElectricity;
      return accumulator;
    },
    {
      totalDayWater: 0,
      totalDayElectricity: 0,
      totalMonthWater: 0,
      totalMonthElectricity: 0,
    }
  );

  const {
    totalDayWater,
    totalDayElectricity,
    totalMonthWater,
    totalMonthElectricity,
  } = totals;
  // console.log(shopBillingData[4]['bills_electricity']['cost_per_day']);

  // const billsElectricityData = shopBillingData.filter((item) => item.bills_electricity !== null).map((item) => item.bills_electricity);
  // const totalCostPerDay = billsElectricityData.reduce((accumulator, current) => {
  //   if (current.cost_per_day !== null) {
  //     accumulator += current.cost_per_day;
  //   }
  //   return accumulator;
  // }, 0);

  // console.log(totalCostPerDay);
  // const formattedTotalDayWater = totalDayWater.toLocaleString();
  // const formattedTotalDayElectricity = totalDayElectricity.toLocaleString();
  // const formattedTotalMonthWater = totalMonthWater.toLocaleString();
  // const formattedTotalMonthElectricity = totalMonthElectricity.toLocaleString();

  // const totalAll =
  //   totalDayWater +
  //   totalDayElectricity +
  //   totalMonthWater +
  //   totalMonthElectricity;
  // const formattedTotalAll = totalAll.toLocaleString();
  // console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  // console.log(totalAll);

  const totalCostPerDay = new Intl.NumberFormat("th-TH").format(
    totalAll?.total_cost_per_day
  );
  const totalAdditional = new Intl.NumberFormat("th-TH").format(
    totalAll?.total_additional
  );
  const totalOutstanding = new Intl.NumberFormat("th-TH").format(
    totalAll?.total_outstanding
  );
  const totalCostPerDayAll = new Intl.NumberFormat("th-TH").format(
    totalAll?.total_cost_per_day_all
  );

  const dataTotal = [
    { p: "ค่าไฟ/เดือน : ", price: totalCostPerDay || 0 },
    { p: "ค่าเครื่องใช้ไฟฟ้า/เดือน : ", price: totalAdditional || 0 },
    { p: "ค่าคงค้าง/เดือน : ", price: totalOutstanding || 0 },
    { p: "ยอดรวม/เดือน : ", price: totalCostPerDayAll || 0 },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>ชำระค่าไฟ</div>
        <div className="searchE">
          <div className="">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="โซน"
                value={age}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <Button
            className="button-h"
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            onClick={handleOpenShop}
          >
            ค้นหาผู้เช่า
          </Button>
          {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                p: 4,
              }}
            >
              <TextField
                label="ค้นหาร้าน"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
              <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#6C5DD3",
                        }}
                      >
                        เลือกผู้เช่า
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterDuplicatesInModal(rowsFiltered).map((result) => (
                      <tr
                        key={result.id}
                        onClick={() => handleResultClick(result.tenant.name)}
                        style={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f0f0f0" },
                        }}
                      >
                        <td
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {result.tenant.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Modal>

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            onClick={handleOpenShopE}
            className="button-h"
          >
            อัปเดตข้อมูล
          </Button>
        </div>
      </div>

      <Modal open={openshopE} onClose={handleCloseShopE}>
        <Box
          className="width-modalE"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <BillingCycleUpdate
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={setSelectedRowsData}
            handleClose={handleClose}
            setOpen={setOpenShopE}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          />
        </Box>
      </Modal>

      <div className="data-boxB">{totalData}</div>

      <DataGrid
        checkboxSelection
        // isRowSelectable={(params) =>
        //   params.row.bills_electricity == null || moment(params.row.bills_electricity?.created_at).format('DD/MM/YYYY') != currentDate
        // }
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default BillingCyclePage;
