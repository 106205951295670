import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { ListsData } from "./data/DataProductsaddPage";
import Swal from "sweetalert2";
import FromAddEvidence from "./FromAddEvidence";
import ReceiptModal from "./ReceiptModal";
import { getZoneData } from "../../../services/rent.service";
import { getBoxsData } from "../../../services/rent.service";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";

function FromAddBlock({
  selectedZone,
  openshop,
  setOpenShop,
  selectedBlockId,
  setSelectedBlockId,
  overdueRent,
  note,
  NameShop,
  Firstname,
  selectedShop,
  setSelectedShop,
  setAddDataBlocks,
  freshData,
}) {
  const [blocks, setBlocks] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedZoneProp, setSelectedZoneProp] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState([]);
  const [averageRentPerDay, setAverageRentPerDay] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState([]);
  const [totalRent, setTotalRent] = useState([]);
  const [rentalCost, setRentalCost] = useState([]);
  const [totalRental, setTotalRental] = useState([]);
  const [depositCost, setDepositCost] = useState([]);
  const [waterbillCost, setWaterbillCost] = useState([]);
  const [electricityCost, setElectricityCost] = useState([]);
  const [tentfeeCost, setTentfeeCost] = useState([]);
  const [totalDay, setTotalDay] = useState([]);
  const [totalBill, setTotalBill] = useState(0);
  const [totalDepositCost, setTotalDepositCost] = useState(0);
  const [totalRentalCost, setTotalRentalCost] = useState(0);
  const [totalWaterbillCost, setTotalWaterbillCost] = useState(0);
  const [totalElectricityCost, setTotalElectricityCost] = useState(0);
  const [totalTentfeeCost, setTotalTentfeeCost] = useState(0);
  const [overdueRent2, setOverdueRent2] = useState(0);
  const [note2, setNote2] = useState("");
  const [selectedZoneProps, setSelectedZoneProps] = useState([]);
  const [selectedBlocksProps, setSelectedBlocksProps] = useState([]);
  const [totalRentSum, setTotalRentSum] = useState(0);

  // console.log("blocks", blocks);

  useEffect(() => {
    setOverdueRent2(overdueRent);
    setNote2(note);
  }, [overdueRent, note]);

  useEffect(() => {
    const ttDeposit = depositCost.reduce((acc, curr) => acc + curr, 0);
    // console.log("Total Deposit:", ttDeposit);

    const sum = totalRental.reduce((acc, curr) => acc + (curr || 0), 0);
    // console.log("Total Rental Cost:", sum);

    const ttWaterbillCost = waterbillCost.reduce((acc, curr) => acc + curr, 0);
    // console.log("Total Waterbill Cost:", ttWaterbillCost);

    const ttTentfeeCost = tentfeeCost.reduce((acc, curr) => acc + curr, 0);
    // console.log("Total Tentfee Cost:", ttTentfeeCost);

    const ttElectricityCost = electricityCost.reduce(
      (acc, curr) => acc + curr,
      0
    );
    // console.log("Total Electricity Cost:", ttElectricityCost);

    setTotalDepositCost(ttDeposit);
    setTotalRentalCost(sum);
    setTotalWaterbillCost(ttWaterbillCost);
    setTotalElectricityCost(ttElectricityCost);
    setTotalTentfeeCost(ttTentfeeCost);

    const totalBill2 =
      ttDeposit + sum + ttWaterbillCost + ttTentfeeCost + ttElectricityCost;
    // console.log("Total Bill:", totalBill2);

    setTotalBill(totalBill2);
  }, [depositCost, totalRental, waterbillCost, tentfeeCost, electricityCost]);

  useEffect(() => {
    const data = blocks.map((block, index) => ({
      zone: block.zone.value,
      box: block.block.value,
      boxSize: block.block.blocksiz,
      deposit: depositCost[index] || 0,
      rent: rentalCost[index] || 0,
      datestartrent: selectedStartDate[index] || null,
      dateendrent: selectedEndDate[index] || null,
      tentfee: tentfeeCost[index] || 0,
      avgrentperday: averageRentPerDay[index] || 0,
      countdate: numberOfDays[index] || 0,
      amountpaid: totalRent[index] || 0,
    }));
    setAddDataBlocks(data);
  }, [freshData, averageRentPerDay, numberOfDays, totalRent, blocks]);

  const handleAddNewBlock = () => {
    // const zones = ListsData.map((data) => data.zone);
    // const newBlock = {
    //   zone: { id: 'zone', value: '0', options: zones },
    //   block: { id: 'block', value: '0', options: [] },
    // };
    // setBlocks([...blocks, newBlock]);

    getZoneData().then((res) => {
      let zone = [];
      const zoneRes = res.data.zonename;
      zoneRes.forEach((data) => {
        zone.push({ id: data.id, name: data.zone_name });
      });
      const newBlock = {
        zone: { id: "zone", value: "0", options: zone },
        block: { id: "block", value: "0", options: [] },
      };
      // console.log("zoneRes", zoneRes);
      setBlocks([...blocks, newBlock]);
    });
  };

  const handleCancelClick = (index) => {
    const blockToCancel = blocks[index];
    const updatedBlocks = blocks.filter((_, idx) => idx !== index);
    console.log(updatedBlocks);
    setBlocks(updatedBlocks);
  };

  const handleChangeZone = (event, index) => {
    const { value } = event.target;

    getBoxsData(event.target.value).then((res) => {
      // console.log(res);
      let boxs = [];
      const boxsRes = res.data.boxs;
      // Map the response data into an array of objects
      boxsRes.forEach((data) => {
        boxs.push({
          id: data.id,
          block: data.zone_name + " " + data.zone_number,
          blocksiz: data.box_size,
          status: data.status,
          zone_number: data.zone_number,
        });
      });

      boxs.sort((a, b) => a.zone_number - b.zone_number);
      setBlocks((prevBlocks) =>
        prevBlocks.map((prevBlock, prevIndex) =>
          prevIndex === index
            ? {
                ...prevBlock,
                zone: { ...prevBlock.zone, value },
                block: {
                  ...prevBlock.block,
                  value: "",
                  options: boxs,
                  blocksiz: "",
                },
              }
            : prevBlock
        )
      );
    });
  };

  const handleChangeBlock = (event, index) => {
    const { value } = event.target;
    const Vblock = value.id;
    const VblockSiz = value.blocksiz;
    const status = value.status;

    const isDuplicate = blocks.some(
      (prevBlock, prevIndex) =>
        prevBlock.block.value === Vblock && prevIndex !== index
    );

    if (isDuplicate) {
      Swal.fire({
        title: "บล็อกไม่ว่าง !!",
        text: "กรุณาเลือกบล็อกที่ยังว่าง",
        icon: "warning",
      });

      setBlocks((prevBlocks) =>
        prevBlocks.map((prevBlock, prevIndex) =>
          prevIndex === index
            ? {
                ...prevBlock,
                block: {
                  ...prevBlock.block,
                  value: "",
                  blocksiz: "",
                  status: "",
                },
              }
            : prevBlock
        )
      );
      return;
    }

    setBlocks((prevBlocks) =>
      prevBlocks.map((prevBlock, prevIndex) =>
        prevIndex === index
          ? {
              ...prevBlock,
              block: {
                ...prevBlock.block,
                value: Vblock,
                blocksiz: VblockSiz,
                status: status,
              },
            }
          : prevBlock
      )
    );
  };

  const handleStartDateChange = (date, index) => {
    const updatedStartDate = [...selectedStartDate];
    updatedStartDate[index] = date;
    setSelectedStartDate(updatedStartDate);
    // calculateRent(date, selectedEndDate[index], rentalCost[index], index);
    calculateRent(
      date,
      selectedEndDate[index],
      depositCost[index],
      rentalCost[index],
      tentfeeCost[index],
      totalDay[index],
      index
    );
  };

  const handleEndDateChange = (date, index) => {
    const updatedEndDate = [...selectedEndDate];
    updatedEndDate[index] = date;
    setSelectedEndDate(updatedEndDate);
    // calculateRent(selectedStartDate[index], date, rentalCost[index], index);
    calculateRent(
      selectedStartDate[index],
      date,
      depositCost[index],
      rentalCost[index],
      tentfeeCost[index],
      totalDay[index],
      index
    );
  };

  const handleDepositCostChange = (event, index) => {
    const updatedDepositCost = [...depositCost];
    const cost = parseFloat(event.target.value);
    updatedDepositCost[index] = isNaN(cost) ? 0 : cost;
    setDepositCost(updatedDepositCost);

    calculateRent(
      selectedStartDate[index],
      selectedEndDate[index],
      cost,
      rentalCost[index],
      tentfeeCost[index],
      totalDay[index],
      updatedDepositCost[index]
    );
  };

  const handleRentalCostChange = (event, index) => {
    const updatedRentalCost = [...rentalCost];
    const cost = parseFloat(event.target.value);
    updatedRentalCost[index] = isNaN(cost) ? 0 : cost;
    setRentalCost(updatedRentalCost);

    calculateRent(
      selectedStartDate[index],
      selectedEndDate[index],
      depositCost[index],
      tentfeeCost[index],
      totalDay[index],
      updatedRentalCost[index],
      index
    );
  };

  const handleTentfeeCost = (event, index) => {
    const updatedTentfeeCost = [...tentfeeCost];
    const cost = parseFloat(event.target.value);
    updatedTentfeeCost[index] = isNaN(cost) ? 0 : cost; // Set to 0 if NaN
    setTentfeeCost(updatedTentfeeCost); // Update the state

    // Call calculateRent after updating the state
    calculateRent(
      selectedStartDate[index],
      selectedEndDate[index],
      depositCost[index],
      rentalCost[index],
      updatedTentfeeCost[index],
      index,
      totalDay[index]
    );
  };

  const handleTotalDay = (event, index) => {
    const updatedTotalDay = [...numberOfDays]; // Ensure you're updating the right state
    const days = parseFloat(event.target.value);
    updatedTotalDay[index] = isNaN(days) ? 0 : days; // Set to 0 if NaN
    setNumberOfDays(updatedTotalDay); // Update the state

    // Call calculateRent after updating the state
    calculateRent(
      selectedStartDate[index],
      selectedEndDate[index],
      depositCost[index],
      rentalCost[index],
      tentfeeCost[index],
      index,
      updatedTotalDay[index]
    );
  };

  useEffect(() => {
    // Calculate rent whenever any of the following states change
    const updateRent = () => {
      depositCost.forEach((_, index) => {
        calculateRent(
          selectedStartDate[index],
          selectedEndDate[index],
          depositCost[index] || 0,
          rentalCost[index] || 0,
          tentfeeCost[index] || 0,
          index,
          numberOfDays[index] || 0
        );
      });
    };

    updateRent();
  }, [
    depositCost,
    rentalCost,
    tentfeeCost,
    numberOfDays,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handleChange = (event, index, type) => {
    const value = parseFloat(event.target.value);
    const updatedValues = [...type];
    updatedValues[index] = isNaN(value) ? 0 : value;

    switch (type) {
      case depositCost:
        setDepositCost(updatedValues);
        break;
      case rentalCost:
        setRentalCost(updatedValues);
        break;
      case tentfeeCost:
        setTentfeeCost(updatedValues);
        break;
      case numberOfDays:
        setNumberOfDays(updatedValues);
        break;
      default:
        break;
    }
  };

  const calculateRent = (
    startDate,
    endDate,
    depositCost,
    rentalCost,
    tentfeeCost,
    index,
    numberOfDays
  ) => {
    rentalCost = rentalCost || 0;
    numberOfDays = numberOfDays || 0;

    const averageRent = rentalCost / 30;

    const updatedAverageRentPerDay = [...averageRentPerDay];
    updatedAverageRentPerDay[index] = averageRent;
    setAverageRentPerDay(updatedAverageRentPerDay);

    const total = numberOfDays * averageRent || 0;

    const updatedTotalRental = [...totalRental];
    updatedTotalRental[index] = total; // Store only the total value
    setTotalRental(updatedTotalRental);

    const updatedTotalRent = [...totalRent];
    updatedTotalRent[index] = total + depositCost + tentfeeCost;
    setTotalRent(updatedTotalRent);
    const totalSum = updatedTotalRent.reduce((acc, curr) => acc + (curr || 0), 0) .toLocaleString();
    setTotalRentSum(totalSum);
    console.log("kkl", totalSum);
  };
  console.log(totalRental);

  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "1.4rem", fontWeight: "500" }}>ข้อมูลบล็อก</h1>
        <div
          className=""
          style={{
            backgroundColor: "#6c5dd3",
            padding: "6px",
            borderRadius: "5px",
            gap: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          {/* */}
          <h1
            style={{
              fontSize: "1.4rem",
              fontWeight: "500",
              textAlign: "end",
              color: "#ffffff",
            }}
          >
            รวมเงินที่ต้องจ่าย
          </h1>
          <h1
            style={{
              fontSize: "1.4rem",
              fontWeight: "500",
              textAlign: "end",
              color: "#ffffff",
            }}
          >
            {totalRentSum}
          </h1>
          <h1
            style={{
              fontSize: "1.4rem",
              fontWeight: "500",
              textAlign: "end",
              color: "#ffffff",
            }}
          >
            บาท
          </h1>
        </div>
      </div>
      {blocks.map((block, index) => (
        <div key={index} className="border">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {index !== 0 && (
              <CancelOutlinedIcon
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginTop: "0.5rem",
                }}
                onClick={() => handleCancelClick(index)}
              />
            )}
          </div>

          <div className="from-information">
            <div className="from-box2">
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id={`select-label-amphoe-${index}`}>โซน</InputLabel>
                <Select
                  labelId={`select-label-amphoe-${index}`}
                  id={`select-amphoe-${index}`}
                  label="โซน"
                  value={block.zone?.value}
                  onChange={(event) => handleChangeZone(event, index)}
                >
                  {block.zone.options.map((option, optionIndex) => (
                    <MenuItem key={optionIndex} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id={`select-label-province-${index}`}>
                  บล็อก
                </InputLabel>
                <Select
                  labelId={`select-label-province-${index}`}
                  id={`select-province-${index}`}
                  label="บล็อก"
                  value={block.block?.value.block}
                  onChange={(event) => handleChangeBlock(event, index)}
                >
                  {block.block.options.map((option, optionIndex) => (
                    <MenuItem
                      key={optionIndex}
                      value={option}
                      disabled={option.status === "1"}
                      style={{
                        backgroundColor:
                          option.status === "1" ? "gray" : "white",
                      }}
                    >
                      {option.block}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="text-from1">
              <TextField
                id={`outlined-basic-size-${index}`}
                size="small"
                label={block.block?.blocksiz ? "" : "ขนาดบล็อก"}
                variant="outlined"
                // defaultValue={'2.5x3m'}
                value={block.block?.blocksiz}
                InputProps={{ style: { backgroundColor: "#F3F4F6" } }}
                disabled
              />
              <TextField
                id={`outlined-basic-deposit-${index}`}
                size="small"
                label="ค่ามัดจำ"
                variant="outlined"
                value={depositCost[index] || 0}
                onChange={(e) => handleDepositCostChange(e, index)}
              />
            </div>
            <div className="text-from1">
              <TextField
                id={`outlined-basic-rental-${index}`}
                size="small"
                label="ค่าเช่า"
                variant="outlined"
                value={rentalCost[index] || 0}
                onChange={(e) => handleRentalCostChange(e, index)}
              />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันที่เริ่มเช่า"
                inputFormat="DD / MM / YYYY"
                value={selectedStartDate[index] || null}
                onChange={(date) => handleStartDateChange(date, index)}
                sx={{ width: "33.33%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันที่เก็บค่าเช่า"
                inputFormat="DD / MM / YYYY"
                value={selectedEndDate[index] || null}
                onChange={(date) => handleEndDateChange(date, index)}
                sx={{ width: "33.33%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
            <div className="text-from2">
              <TextField
                id={`outlined-basic-rental-${index}`}
                size="small"
                label="ค่าเต็นท์"
                variant="outlined"
                value={tentfeeCost[index] || 0}
                onChange={(e) => handleTentfeeCost(e, index)}
              />
            </div>
            <TextField
              id={`outlined-basic-average-${index}`}
              size="small"
              label="ค่าเช่า เฉลี่ยต่อวัน"
              d
              variant="outlined"
              value={averageRentPerDay[index]?.toFixed(2) || ""}
              InputProps={{ style: { backgroundColor: "#F3F4F6" } }}
              disabled
            />
            <div className="text-from4">
              <TextField
                id={`outlined-basic-days-${index}`}
                size="small"
                label="จำนวนวัน"
                variant="outlined"
                value={numberOfDays[index] || 0}
                onChange={(e) => handleTotalDay(e, index)}
              />
              <TextField
                id={`outlined-basic-total-${index}`}
                size="small"
                label="จำนวนเงินที่ต้องจ่าย"
                variant="outlined"
                value={totalRent[index]?.toFixed(2) || ""}
                InputProps={{ style: { backgroundColor: "#F3F4F6" } }}
                disabled
              />
            </div>
          </div>

          {/* ส่ง Porp ผลรวม Total  */}
          <Modal open={openshop} onClose={() => setOpenShop(false)}>
            <ReceiptModal
              selectedBlockId={selectedBlockId}
              totalDepositCost={totalDepositCost}
              totalRentalCost={totalRentalCost}
              totalWaterbillCost={totalWaterbillCost}
              totalElectricityCost={totalElectricityCost}
              totalTentfeeCost={totalTentfeeCost}
              totalBill={totalBill}
              selectedZone={selectedZone}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              // rentalCost={rentalCost}
              totalRental={totalRental}
              depositCost={depositCost}
              tentfeeCost={tentfeeCost}
              blocks={blocks}
              overdueRent2={overdueRent2}
              note2={note2}
              NameShop={NameShop}
              Firstname={Firstname}
              selectedShop={selectedShop}
            />
          </Modal>
        </div>
      ))}
      <div className="button">
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: "16px",
            background: "#6C5DD3",
            color: "white",
            height: "39px",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            marginTop: "2rem",
          }}
          onClick={handleAddNewBlock}
        >
          เพิ่มบล็อก
        </Button>
      </div>
    </>
  );
}
export default FromAddBlock;
