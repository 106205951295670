import { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Box } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Typography from "@mui/material/Typography";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { padding, width } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { svDeletebox } from "../../../services/setting/boxzone.service";

const modalSwal = withReactContent(Swal);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F6FF",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(id, BlockNumber, BlockSize) {
  return { id, BlockNumber, BlockSize };
}

export default function CustomPaginationActionsTable({filteredData,setOpenboxsEdit,setBoxEdit,setRefreshData}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
// console.log(filteredData);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    // ในฟังก์ชั่นนี้คุณสามารถเข้าถึงข้อมูลของแถวที่ต้องการแก้ไขโดยใช้ id
    console.log("Edit row with id:", id);
    // เพิ่มโค้ดสำหรับการแก้ไขตามต้องการ
    setBoxEdit(id)
    setOpenboxsEdit(true)

  };

  const handleDelete = (id) => {
    // ในฟังก์ชั่นนี้คุณสามารถเข้าถึงข้อมูลของแถวที่ต้องการลบโดยใช้ id
    console.log("Delete row with id:", id);
    // เพิ่มโค้ดสำหรับการลบตามต้องการ
    modalSwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "I want to delete this data!",
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "#e11d48",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        svDeletebox(id).then((res) => {
          if (res.status === 200) {
            setRefreshData((prev) => prev + 1);
            SwalUI({
              status: res.data.status,
              //description: res.data.description
            });
          } else {
            SwalUI({
              status: res.data.status,
              //description: res.data.description
            });
          }
        });
      }
    });
  };
  // const rowsClassName = "table-rows";

  // Table2 datagrid
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: "110px" }}>
              เลขที่บล็อก
            </StyledTableCell>
            <StyledTableCell
              align="left"
              colSpan={6}
              style={{ width: "110px" }}
            >
              ขนาดบล็อก
            </StyledTableCell>
            {/* <StyledTableCell align="right">Fat&nbsp;(g)</StyledTableCell> */}
            <StyledTableCell align="center" style={{ width: "30px" }}>
              ลบ
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "30px" }}  >
              แก้ไข
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredData
          ).map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell style={{fontWeight:"600"}}>{row.zone_name+" "+row.zone_number}</StyledTableCell>
              <StyledTableCell align="left" colSpan={6}>
                {row.box_size}
              </StyledTableCell>

              <StyledTableCell align="right">
                {" "}
                <Button
                  variant="contained"
                  onClick={() => handleDelete(row.id)}
                  style={{ background: "#FF2C2C" }}
                  className="button-Delete"
                >
                  <DeleteOutlineIcon />
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                {" "}
                <Button
                  variant="contained"
                  onClick={() => handleEdit(row)}
                  style={{ background: "#6C5DD3" }}
                  className="button-h"
                >
                  แก้ไข
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
