import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Radio from "@mui/material/Radio";
import "./ElectricityBill.scss";
import FormAddModal from "./components/FormAddmodal";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import rows from "./data/TableData";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import dayjs from "dayjs";
import axios from "axios";
import {
  svElectricityBillData,
  svElectricityBillDelete,
} from "../../services/expenses/electricitybill.service";
import FormEditmodal from "./components/FormEditmodal";
import SwalUI from "../../components/ui/swal-ui/swal-ui";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const modalSwal = withReactContent(Swal);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F6FF",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ElectricityBill() {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("e");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [refreshData, setRefreshData] = useState(0);
  const [dataBill, setDataBill] = useState([]);
  const [maxMumbeUnit, setMaxMumbeUnit] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [electricityBillEdit, setElectricityBillEdit] = useState(null);

  console.log("dataBill", dataBill);

  useEffect(() => {
    svElectricityBillData().then((res) => {
      console.log(res);
      if (res.status === 200) {
        setDataBill(res.data.electricitybill);
        setMaxMumbeUnit(res.data.maxnumbeunit);
      }
    });
  }, [refreshData]);
  // console.log(dataBill)

  const isValidDate = (date) => date !== null && dayjs(date).isValid();
  // console.log(rows)
  const rowsFiltered = dataBill.filter((row) => {
    if (selectedValue === "e") {
      return true;
    }

    let rentalStatusFilter = false;

    if (
      selectedValue === "a" ||
      selectedValue === "b" ||
      selectedValue === "c" ||
      selectedValue === "d"
    ) {
      const startDate = dayjs(row.date, "DD/MM/YYYY");
      const endDate = dayjs(row.date, "DD/MM/YYYY");

      const dayFilter =
        selectedValue === "a" &&
        isValidDate(selectedDate1) &&
        startDate.date() === selectedDate1.date() &&
        startDate.month() === selectedDate1.month() &&
        startDate.year() === selectedDate1.year();
      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        startDate.month() === selectedDate2.month() &&
        startDate.year() === selectedDate2.year();
      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        startDate.year() === selectedDate3.year();
      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(selectedStartDate - 1).isBefore(startDate) &&
        dayjs(selectedEndDate + 1).isAfter(endDate);

      const dateFilter =
        dayFilter || monthFilter || yearFilter || dateRangeFilter;

      return dateFilter;
    }

    return false;
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsFiltered.length) : 0;

  const totalServiceFee = rowsFiltered.reduce((total, row) => {
    const fee = parseInt(row.expenses);
    return total + fee;
  }, 0);

  const formattedTotalServiceFee = totalServiceFee.toLocaleString();
  // .toString()
  // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // console.log(`Total Service Fee: ${formattedTotalServiceFee}฿`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (row) => {
    // console.log(row);
    setElectricityBillEdit(row);
    setOpenEdit(true);
  };

  const handleDelete = (id) => {
    // console.log("Delete row with id:", id);
    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        // text: "I want to delete this data!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svElectricityBillDelete(id).then((res) => {
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);
              SwalUI({
                status: res.data.status,
                // //description: res.data.description
                description: "คุณได้ลบข้อมูลแล้ว",
              });
            } else {
              SwalUI({
                status: res.data.status,
                //description: res.data.description
              });
            }
          });
        }
      });
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [openshop, setOpenShop] = useState(false);
  const handleOpenShop = () => {
    setOpenShop(true);
  };
  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const exportToExcel = () => {
    if (!Array.isArray(rowsFiltered) || rowsFiltered.length === 0) {
      console.error("Invalid data: Data should be a non-empty array.");
      return;
    }

    try {

      const filteredData = rowsFiltered.map(({ id, created_at, updated_at, date, previous_unit, last_unit, quantity_used, expenses }) => ({
        "วันที่เรียกเก็บ": date,
        "เลขครั้งก่อน": previous_unit,
        "เลขครั้งหลัง": last_unit,
        "จำนวนที่ใช้": quantity_used,
        "ยอดค่าใช้จ่าย": expenses,
      }));
      console.log("Filtered Data:", filteredData);
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      XLSX.utils.sheet_add_aoa(worksheet, [["ค่าใช้จ่าย ค่าไฟ"]], {
        origin: "A1",
      });
      worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "วันที่เรียกเก็บ",
            "เลขครั้งก่อน",
            "เลขครั้งหลัง",
            "จำนวนที่ใช้",
            "ยอดค่าใช่จ่าย",
          ],
        ],
        { origin: "A2" }
      );

      XLSX.utils.sheet_add_json(worksheet, filteredData, {
        origin: "A3",
        skipHeader: true,
      });

      const lastRow = filteredData.length + 3;
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[ "", "", "", "รวม", formattedTotalServiceFee]],
        { origin: `A${lastRow}` }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `ค่าไฟ.xlsx`);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  return (
    <section>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
          className="defective-nav"
        >
          <p
            style={{
              fontSize: "1.75rem",
              fontWeight: 500,
              alignItems: "center",
            }}
          >
            ค่าใช้จ่าย ค่าไฟ
          </p>

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "1rem",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            className="button-h"
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
        </div>

        <div className="from-warter-m1">
          <div className="box-total-m">
            <p style={{ fontSize: "18px", fontWeight: 500 }}>ค่าไฟทั้งหมด</p>
            <p style={{ fontSize: "2rem", fontWeight: 700 }}>
              {formattedTotalServiceFee} บาท
            </p>
          </div>
          {/* บล็อกด้านขวา */}
          <div className="box-main-2">
            <div className="button-text-2">
              <p style={{ fontWeight: 700, fontSize: "1rem" }}>เลือก</p>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "1rem",
                  background: "#6C5DD3",
                  color: "white",
                  height: "39px",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                }}
                className="button-h"
                onClick={handleOpenShop}
              >
                เพิ่มค่าไฟ
              </Button>
            </div>
            <div className="from-Date-filter1">
              <div className="date-1">
                <Radio {...controlProps("a")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  วัน
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วัน"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "a"}
                    value={selectedDate1}
                    onChange={handleDateChange1}
                    views={["day", "month", "year"]}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        style={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-2">
                <Radio {...controlProps("b")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  เดือน
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="เดือน"
                    inputFormat="MM/YYYY"
                    views={["month", "year"]}
                    disabled={selectedValue !== "b"}
                    value={selectedDate2}
                    onChange={handleDateChange2}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-3">
                <Radio {...controlProps("c")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ปี
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="ปี"
                    inputFormat="YYYY"
                    views={["year"]}
                    disabled={selectedValue !== "c"}
                    value={selectedDate3}
                    onChange={handleDateChange3}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-5">
                <Radio {...controlProps("d")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ตั้งแต่
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="ตั้งแต่"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-6">
                <p
                  className="date-2-text"
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginRight: "19px",
                  }}
                >
                  จนถึง
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="จนถึง"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-5">
                <Radio {...controlProps("e")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ทั้งหมด
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="from-filter-2">
          {/* แสดง Modal  */}
          <div>
            {openshop && (
              <FormAddModal
                openshop={openshop}
                handleCloseShop={handleCloseShop}
                maxMumbeUnit={maxMumbeUnit}
                setRefreshData={setRefreshData}
              />
            )}
          </div>
          <div>
            {openEdit && (
              <FormEditmodal
                setRefreshData={setRefreshData}
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                electricityBillEdit={electricityBillEdit}
              />
            )}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "250px" }}>
                  วันที่เรียกเก็บ
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: "200px" }}>
                  เลขครั้งก่อน
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: "200px" }}>
                  เลขครั้งหลัง
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: "200px" }}>
                  จำนวนที่ใช้
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ width: "2000px" }}
                  colSpan={6}
                >
                  ยอดค่าใช้จ่าย
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "100px" }}>
                  แก้ไข
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "100px" }}>
                  ลบ
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rowsFiltered.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rowsFiltered
              ).map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell style={{ fontWeight: "400" }}>
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.previous_unit}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.last_unit}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.quantity_used}
                  </StyledTableCell>
                  <StyledTableCell align="left" colSpan={6}>
                    {(row.expenses || 0).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      classlastnumber="edit-button"
                      onClick={() => handleEdit(row)}
                    >
                      <img src="/images/icons/mage_edit.png" alt="" />
                    </button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      classlastnumber="delete-button"
                      onClick={() => handleDelete(row.id)}
                    >
                      <img
                        src="/images/icons/gravity-ui_trash-bin.png"
                        alt="" style={{hover:'scal:1.25'}}
                      />
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={8} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={rowsFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    </section>
  );
}

export default ElectricityBill;
