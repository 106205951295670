import axios from "axios"

export const svWaterDispenserData = () => {
    return axios.get(`water_dispenser/data`).then((res) => res).catch((error) => error);
}

export const svWaterDispenserCreate = (formData) => {
    return axios.post(`water_dispenser/create`, formData).then((res) => res).catch((error) => error);
}

export const svWaterDispenserUpdate = (formData) => {
    return axios.post(`water_dispenser/update`, formData).then((res) => res).catch((error) => error);
}

export const svWaterDispenserDelete = (id) => {
    return axios.delete(`water_dispenser/destroy/${id}`).then((res) => res).catch((error) => error);
}