import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import PulseLoader from "react-spinners/PulseLoader";
import { useParams } from 'react-router-dom';
// import HeadPageComponent from "../../components/layout/headpage/headpage";
import { useTranslation } from 'react-i18next';
// import { Autocomplete, Button, Card, TextField } from "@mui/material";
// import axios from "axios";
// import TableHistory from "./components/Table";
import TablePayrent from './components/TablePayrent';
import Modal from '@mui/material/Modal';
import BillingCycleEditData from './components/BillingCycleEditData';
// import { useRef } from "react";
// import {
//   PDFDownloadLink,
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   pdf,
// } from "@react-pdf/renderer";
// import ReactToPdf from "react-to-pdf";
// import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { svDeleteDetailsbill, svGetDetailsbill } from '../../../services/overalldisplay/billingelectricitybill.service';
import moment from 'moment';
import { Box } from '@mui/material';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from '../../../components/ui/swal-ui/swal-ui';
const modalSwal = withReactContent(Swal);

function HistoryDetails() {
  // const { t } = useTranslation(["dashboard-page"]);
  const webPath = useSelector((state) => state.app.uploadPath);
  const { id } = useParams();
  // const [loading, setLoading] = useState(true);
  // const [exportedProductDetails, setExportedProductDetails] = useState([]);
  // const [refreshData, setRefreshData] = useState(0);
  const [year, setYear] = React.useState('');
  const [detail, setDetail] = useState([]);
  const [boxeData, setBoxeData] = useState([]);
  const [openEditDate, setOpenEditDate] = useState(false);
  const [editData, setEditData] = useState();
  const [refreshData, setRefreshData] = useState(0);
  // const [showTable, setShowTable] = useState(true);
  // const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    // console.log(id);
    svGetDetailsbill(id).then((res) => {
      console.log(res);
      if (res.status === 201) {
        setDetail(res.data.historyDetails);
        setBoxeData(res.data.boxe);
      }
    });
  }, [refreshData]);
  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const handleCloseEditDate = () => {
    setOpenEditDate(false);
  };

  const handleOpenEditDate = (row) => {
    console.log(row);
    setEditData(row);
    setOpenEditDate(true);
  };
 
  const deleteHandle = (id)=>{
    console.log(id);
    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        // text: "I want to delete this data!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          svDeleteDetailsbill(id).then((res) => {
            if (res.status === 200) {
              setRefreshData(prev => prev + 1)
             SwalUI({
                      status: res.data.status,
                      // description: res.data.description,
                      description: "คุณได้ลบข้อมูลแล้ว",
                    });
            }else {
                  SwalUI({
                    status: res.data.status,
                    description: res.data.description,
                  });
                }
          })
        }
      });
  }
  // const showTableHandler = () => {
  //   setShowTable(true); // เปลี่ยนค่า state เพื่อแสดงตาราง
  //   setShowForm(false); // ซ่อนฟอร์ม
  // };

  // const showFormHandler = () => {
  //   setShowTable(false); // ซ่อนตาราง
  //   setShowForm(true); // เปิดฟอร์ม
  // };

  // console.log(exportedProductDetails);

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <p style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
              รายละเอียด การชำระเงินค่าไฟ
            </p>
          </div>
        </div>

        <div className="box-detail">
          <div className="box-Ar">
            <p className="number">{boxeData?.zoesBoxe}</p>
            <p className="size">{boxeData?.boxSize}</p>
          </div>

          <figure className="img-boxr">
            <img
              src={webPath + boxeData?.image}
              alt=""
              className="img-storer"
            />
          </figure>

          <div className="box-Br">
            <p className="title">วันที่เริ่มเช่า</p>
            <p className="date-start">
              {moment(boxeData?.datestartrent).format('DD/MM/YYYY')}
            </p>
          </div>

          <div className="box-Cr">
            <p className="title">ยอดรวมทั้งหมด</p>
            <p className="deposit">{boxeData?.totalExpenses + ' ' + '฿'}</p>
          </div>

          {/* <div className="box-Dr">
            <p className="title">ยอดรวม/เดือน</p>
            <p className="rent">6,000 ฿</p>
          </div> */}

          <div className="box-Er">
            <p className="title">ชื่อผู้เช่า</p>
            <p className="NameShop">{boxeData?.name}</p>
          </div>

          <div className="box-Fr">
            <p className="title">ประเภทธุรกิจ</p>
            <div
              className="type-business"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  borderRadius: '3px',
                  height: '16px',
                  width: '16px',
                  backgroundColor: boxeData?.box_color,
                  marginRight: '6px',
                }}
              />
              <div className="NameShop">{boxeData?.types_name}</div>
            </div>
          </div>
        </div>

        <div
          className="box-button"
          style={{
            width: '100%',
            display: 'flex',
            // justifyContent: "space-between",
            alignItems: 'center',
            position: 'relative',
            gap: '2rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              gap: '1rem',
            }}
          >
            {/* <Button
              onClick={showTableHandler}
              variant="contained"
              sx={{
                background: showTable ? "#6C5DD3" : "#707070",
                width: "160px",
                textTransform: "capitalize",
                fontSize:"16px"
              }}
            >
              ประวัติการเช่า
            </Button> */}

            {/* <Button
              onClick={showFormHandler}
              variant="contained"
              sx={{
                background: showForm ? "#6C5DD3" : "#707070",
                width: "160px",
                textTransform: "capitalize",
                fontSize:"16px"
              }}
            >
              ข้อมูลการชำระเงิน
            </Button> */}
          </div>

          <div
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              gap: '1rem',
            }}
          >
            <FormControl
              sx={{ minWidth: 150 }}
              size="small"
              style={{ backgroundColor: 'white' }}
            >
              <InputLabel id="demo-simple-select-label">ปี</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="ปี"
                onChange={handleChange}
              >
                <MenuItem value={10}>2023</MenuItem>
                <MenuItem value={20}>2022</MenuItem>
                <MenuItem value={30}>2021</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {/* {showTable && (
          <div className="">
            <TableHistory />
          </div>
        )} */}
        {/* แก้ไขการชำระค่าไฟ */}
        <Modal open={openEditDate} onClose={handleCloseEditDate}>
          <Box
            className="width-modalEditele"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              p: 4,
            }}
          >
            <BillingCycleEditData
              setOpenEditDate={setOpenEditDate}
              editData={editData}
              setRefreshData={setRefreshData}
              
            />
          </Box>
        </Modal>
        {/* {showForm && ( */}
        <div className="container-form">
          <TablePayrent
            detail={detail}
            handleOpenEditDate={handleOpenEditDate}
            deleteHandle={deleteHandle}
          />
        </div>
        {/* )} */}
      </>
      {/* )} */}
    </section>
  );
}

export default HistoryDetails;
