import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
});

// Component
const MyDocument = ({ items, name, email, phone }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Invoice</Text>
        <View style={styles.itemRow}>
          <Text>Name:</Text>
          <Text>{name}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text>Email:</Text>
          <Text>{email}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text>Phone:</Text>
          <Text>{phone}</Text>
        </View>
        <View style={styles.itemRow}>
          <Text>Items:</Text>
          <View>
            {items.map((item, index) => (
              <View key={index} style={styles.itemRow}>
                <Text>{item.name}</Text>
                <Text>{item.quantity} x ${item.price}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;