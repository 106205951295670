import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import rows from "./data/TableData";
import { InputLabel } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { getBlockNumber } from "../../services/reportblocknumber.service";

const StockPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [exportedProductDetails, setExportedProductDetails] = useState([]);
  const [dataBlock, setDataBlock] = useState([]);

  console.log("dataBlock", dataBlock);

  useEffect(() => {
    getBlockNumber().then((res) => {
      console.log(res.data.data);
      setDataBlock(res.data?.data);
    });
  }, []);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "boxSize",
      headerName: "ขนาดบล็อก",
      width: 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "NameShop",
      headerName: "ชื่อร้านที่เช่า",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false,
    },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 170,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "Update",
      headerName: "แสดงรายละเอียด",
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        // console.log(params.row.BlockNumber)
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3", width: 110 }}
          onClick={() => {
            const newWindow = window.open(
              `/exportdetails/${params.row.boxId}`,
              "_blank"
            );
            if (newWindow) {
              newWindow.opener = null;
            }
          }}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];
  // console.log(rows)
  const rowsFiltered = dataBlock.filter((row) => {
    if (
      (age === "" || row.Zone === age) && // Filter by Zone
      (row.BlockNumber?.includes(searchInput) ||
        row.NameShop?.includes(searchInput))
    ) {
      return true;
    } else {
      return false;
    }
  });

  const zones = Array.from(new Set(dataBlock.map((row) => row.Zone)));

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  const exportToExcel = () => {
    const headers = {
      เลขที่บล็อก: "BlockNumber",
      ขนาดบล็อก: "boxSize",
      ชื่อร้านที่เช่า: "NameShop",
      ค่ามัดจำเต็นท์: "tentfee",
      ค่ามัดจำ: "deposit",
      ค่าเช่า: "rent",
    };

    // สร้างข้อมูลที่กรองแล้ว
    const mappedRows = rowsFiltered.map((row) => {
      const mappedRow = {};
      for (const key in headers) {
        mappedRow[key] = row[headers[key]];
      }
      return mappedRow;
    });

    // สร้างแผ่นงานใหม่
    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(worksheet, [["รายงานเลขบล็อก"]], {
      origin: "A1",
    });
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }];

    // เพิ่มชื่อคอลัมน์ในแถวที่สอง
    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(headers)], {
      origin: "A2",
    });

    // เพิ่มข้อมูลในแถวที่สาม
    XLSX.utils.sheet_add_json(worksheet, mappedRows, {
      origin: "A3",
      skipHeader: true,
    });

    // สร้าง Workbook และเพิ่ม Worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");

    // แปลง Workbook เป็น ArrayBuffer และ Blob
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    // บันทึกไฟล์ Excel
    saveAs(data, "Block_Number_Report.xlsx");
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header" style={{ marginBottom: "1.5rem" }}>
        <div style={{ fontSize: "1.75rem", fontWeight: 400 }}>
          รายงานเลขบล็อก
        </div>
        <div className="search">
          <div className="">
            <FormControl fullWidth size="small" style={{ width: "100px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="ค้นหา บล็อก,ร้าน"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ color: "grey", marginLeft: "8rem" }} />
              ),
            }}
          />

          <Button
            onClick={exportToExcel}
            variant="contained"
            size="small"
            style={{
              fontSize: "14px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              // marginTop: "2rem",
            }}
            className="button-h"
          >
            EXPORT TO EXCEL
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default StockPage;
