import axios from "axios";

export const svCleaningData=()=>{
    return axios.get(`cleaning/data`).then((res) => res).catch((err) => err);
};

export const svCleaningCreate=(formData)=>{
    return axios.post(`cleaning/create`,formData).then((res) => res).catch((err) => err);
};

export const svCleaningUpdate=(formData)=>{
    return axios.post(`cleaning/update`,formData).then((res) => res).catch((err) => err);
};

export const svCleaningDelete=(id)=>{
    return axios.delete(`cleaning/destroy/${id}`).then((res) => res).catch((err) => err);
};