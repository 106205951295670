import axios from "axios";

export const svBusinessTypeData=()=>{
    return axios.get(`businesstypes/details`).then((res) => res).catch((error) => error);
}

export const svBusinessTypeCreate = (formData)=>{
    return axios.post(`businesstypes/create`,formData).then((res) => res).catch((error) => error);
}

export const svBusinessTypeUpdate = (formData)=>{
    return axios.post(`businesstypes/update`,formData).then((res) => res).catch((error) => error);
}

export const svBusinessTypeDelete = (id) => {
    return axios.delete(`businesstypes/destroy/${id}`).then((res) => res).catch((error) => error);
}