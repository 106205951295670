import React, { useState } from "react";
import ExpirationPage from "../variousexpenses/VariousExpensesPage";

function TotalGraphs() {
  const [addDataTotal, setAddDataTotal] = useState(0); //ค่าใช้จ่ายรายเดือน
  const [addDataFine, setAddDataFine] = useState(0); //ค่าปรับ
  const [addDataAccrued_rent, setAddDataAccrued_rent] = useState(0); //ค่าเช่าค้างจ่าย

  return (
    <div>
      {/* Pass setAddDataTotal as a prop */}
       <div>ค่าใช้จ่ายรายเดือน: {addDataTotal}</div>
      <div>ค่าปรับ: {addDataFine}</div>
      <div>ค่าเช่าค้างจ่าย: {addDataAccrued_rent}</div>

      <div className="" style={{ display: "none" }}>
        <ExpirationPage setAddDataTotal={setAddDataTotal} />
        <ExpirationPage setAddDataFine={setAddDataFine} />
        <ExpirationPage setAddDataAccrued_rent={setAddDataAccrued_rent} />
      </div>
    </div>
  );
}

export default TotalGraphs;
