import axios from "axios"

export const svGarbageData = () => {
    return axios.get(`garbage/data`).then((res) => res).catch((error) => error);
}

export const svGarbageCreate = (formData) => {
    return axios.post(`garbage/create`, formData).then((res) => res).catch((error) => error);
}

export const svGarbageUpdate = (formData) => {
    return axios.post(`garbage/update`, formData).then((res) => res).catch((error) => error);
}

export const svGarbageDelete = (id) => {
    return axios.delete(`garbage/destroy/${id}`).then((res) => res).catch((error) => error);
}