import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { height } from '@mui/system';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import moment from 'moment';
import ButtonUI from '../../../../components/ui/button/button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function TablePayrent(props) {
  const { detail, handleOpenEditDate, deleteHandle } = props;
  // const [open, setOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
 

 

  const columns = [
    {
      field: 'created_at',
      headerName: 'วันที่จ่ายเงิน',
      width: 170,
      sortable: true,
      renderCell: (params) => {
        const Datevalue = params.row.created_at;

        if (!params.value) {
          return <p>--/--/--</p>;
        }
        return moment(Datevalue).format('DD/MM/YYYY').toString();

        //   <>
        //   {moment(params.row.datestartrent)
        //      .format('DD/MM/YYYY')
        //      .toString()}
        //  </>
      },
    },
    // {
    //   field: "thumbnail_link",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 170,
    //   headerClassName: "table-columns",
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         background: "#D0D0E2",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //       }}
    //       onClick={() => handleOpen(params.value)}
    //     >
    //       <img
    //         src={params.value ? `${params.value}` : "/images/no-image.png"}
    //         alt={""}
    //       />
    //     </div>
    //   ),
    // },
    {
      field: 'shopName',
      headerName: 'ชื่อร้านที่เช่า',
      sortable: false,
      width: isHovered ? 200 : 160,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: '0' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: 'nameTenant',
      headerName: 'ชื่อผู้เช่า',
      width: 200,
      sortable: false,
    },
    {
      field: 'Business',
      headerName: 'ประเภทธุรกิจ',
      width: 190,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                borderRadius: '3px',
                height: '14px',
                width: '14px',
                backgroundColor: params.row.box_color,
                marginRight: '6px',
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.row.types_name}</div>
          </div>
        );
      },
    },

    {
      field: 'cost_per_day',
      headerName: 'ค่าไฟ / วัน',
      width: 170,
      sortable: false,
    },
    {
      field: 'additional',
      width: 200,
      sortable: false,
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: '30px' }}
          >
            ค่าเครืองใช้ไฟฟ้าเพิ่มเติม
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue) {
          return <p>----฿</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: 'outstanding',
      width: 120,
      sortable: false,
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: '14px', fontWeight: 500, lineHeight: '30px' }}
          >
            ค่าคงค้าง
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue) {
          return <p>----฿</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: 'total_expenses',
      headerName: 'ยอดรวม',
      width: 200,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const fineValue = params.value;

        if (!fineValue) {
          return <p>---฿</p>; // แสดงข้อความหรือค่าที่ต้องการเมื่อไม่มีค่า Fine
        }

        // แสดงค่า Fine ที่มีอยู่
        return fineValue;
      },
    },
    {
      // flex: 1,
      field: "action",
      headerName: 'จัดการ',
      width: 160,
      headerAlign: "center",
      align: "center",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <div style={{display:'flex',gap:'20px'}}>
         <ButtonUI
                onClick={() => handleOpenEditDate(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                แก้ไข
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row.id)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                ลบ
              </ButtonUI>
        </div>
      ),
    },
  ];

 return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={detail}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              // height: "350px",
              width: "500px",
            }}
          />
        </div>
      </Modal> */}
    </div>
  );
}

export default TablePayrent;
