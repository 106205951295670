import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { rows } from "./data/TableData";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import "./ProductsaddPage.scss";
import Swal from "sweetalert2";
import ReceiptModal from "./ReceiptModal";
import FromAddBlock from "./FromAddBlock";
import SaveAsPDFHandler from "./PDFModal";

const FromAddEvidence = ({
  openshop,
  setOpenShop,
  selectedZone,
  overdueRent,
  setOverdueRent,
  setNote,
  note,
  NameShop,
  Firstname,
  setFirstName,
  selectedShop,
  setSelectedShop,
  freshData,
  setAddDataEvidence,
  selectedImage,
  setSelectedImage,
  handleOpenShop,
  setData,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [age, setAge] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);
  const formInputRef = useRef(null);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // console.log("selectedImage", selectedImage);

  const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

  useEffect(() => {
    const filteredResults = rows.filter((row) =>
      row.NameShop.includes(searchQuery)
    );
    setSearchResults(filteredResults);
  }, [searchQuery]);

  useEffect(() => {
    setAddDataEvidence((prevState) => {
      return { ...prevState, overdue: overdueRent, businessDetails: note };
    });
  }, [freshData, note, overdueRent]);

  //   const handleOpenShop = () => {
  //     setOpenShop(true);
  // };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const previewImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileUploader = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        console.log(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        setAddDataEvidence((prevState) => {
          return { ...prevState, imageName: file.name, imageFile: file };
        });
      }
    };
    input.click();
  };

  const handleOpenShopAndSavePDF = () => {
    setOpenShop(true);
    // SaveAsPDFHandler('elementId', today);
  };

  return (
    <>
      <div className="box-shop-add">
        <div className="herder-shop-add">
          <h1 style={{ fontSize: '1.4rem', fontWeight: '500' }}>
            หลักฐานการโอน
          </h1>
          <div className="button-shop">
            
            <Button
              className="button-h"
              variant="contained"
              size="small"
              style={{
                fontSize: '16px',
                background: '#6C5DD3',
                color: 'white',
                height: '39px',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
              }}
              onClick={handleOpenShopAndSavePDF}
            >
              ใบเสร็จรับเงิน
            </Button>
            {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
            {/* <Modal open={openshop} onClose={handleCloseShop}>
              <ReceiptModal
                receiptValue={{
                  overdueRent,
                  note,
                }}
              />
            </Modal> */}
          </div>
        </div>
        <div className="from-shop-add">
          <div className="figure-container-add">
            <figure className="figure-add" style={{ cursor: 'pointer' }}>
              {selectedImage && (
                <img
                  className="content-images"
                  src={selectedImage}
                  alt="Selected"
                />
              )}

              {/* แสดงรูปภาพที่เลือก */}
              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={previewImageHandler}
                  ref={formInputRef}
                  style={{ display: 'none' }}
                />
              </div>
              {selectedImage ? (
                ''
              ) : (
                <div className="figure-text">
                  <InsertPhotoOutlinedIcon
                    fontSize="large"
                    style={{ color: 'grey' }}
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#707070',
                      text: 'center',
                    }}
                  >
                    อัปโหลด รูปภาพสลิป
                  </p>
                </div>
              )}
            </figure>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: '16px',
                background: '#6C5DD3',
                color: 'white',
                height: '39px',
                width: '108px',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
              }}
              className="button-h"
              onClick={openFileUploader}
            >
              อัปโหลด
            </Button>
          </div>
          <div className="text-from-shop">
            <TextField
              size="small"
              label="ค่าคงค้าง"
              variant="outlined"
              value={overdueRent
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              onChange={(event) =>
                setOverdueRent(
                  parseFloat(event.target.value.replace(/,/g, '')) || 0
                )
              }
            />

            <textarea
              aria-label="empty textarea"
              placeholder="หมายเหตุ"
              value={note !== null ? note : ""}
              onChange={(event) => setNote(event.target.value)}
              style={{
                border: '1px solid #808080',
                borderRadius: '5px',
                padding: '8px',
                width: '100%',
                height: '80px',
                resize: 'vertical',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FromAddEvidence;