import React, { useState } from "react";
import { Modal, Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./FormAddmodal.scss";
import moment from "moment";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svCleaningCreate } from "../../../services/expenses/clean.service";

const addDataDefault = {
  buy_equipment: "",
  quantity_buy: "",
  expenses: "",
};

function FormAddModal(props) {
  const { openshop, handleCloseShop, setRefreshData } = props;
  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    buy_equipment: false,
    quantity_buy: false,
    date_buy: false,
    expenses: false,
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleCancel = () => {
    handleCloseShop();
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      buy_equipment: addData.buy_equipment < 1,
      quantity_buy: addData.quantity_buy.trim().length < 1,
      date_buy: selectedStartDate.toString().trim().length < 1,
      expenses: addData.expenses.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();

      formData.append("equipment", addData.buy_equipment);
      formData.append("RequisitionDay", selectedStartDate.format("DD/MM/YYYY"));
      formData.append("quantity", addData.quantity_buy);
      formData.append("totalExpenses", addData.expenses);

      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      svCleaningCreate(formData).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            // //description: res.data.description
          });
          handleCloseShop();
        }
      });
    }
  };

  return (
    <Modal open={openshop} onClose={handleCloseShop}>
      <Box
        className="width-modal"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 4,
        }}
      >
        <div className="box-modal-add">
          <div className="justifu-CancelIcon">
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              ข้อมูลค่าอุปกรณ์ทำความสะอาด
            </p>
            <button onClick={handleCancel}>
              <CancelIcon className="CancelIcon" />
            </button>
          </div>

          <div className="main-modal">
            <div className="box-modal-1">
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  อุปกรณ์ที่ซื้อ
                </p>
                <TextField
                  id="outlined-basic"
                  value={addData.buy_equipment}
                  size="small"
                  // label="หัวข้อ"
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.buy_equipment}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, buy_equipment: e.target.value };
                    })
                  }
                />
              </div>
              <div className="box-maintenance">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  วันที่ซื้อ
                </p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // label="ปี"
                    inputFormat="DD / MM / YYYY"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              {/* <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  ยอดค่าใช้จ่าย
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  // label="หัวข้อ"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div> */}
            </div>
            <div className="box-modal-2">
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  จำนวนที่ซื้อ
                </p>
                <TextField
                  id="outlined-basic"
                  value={addData.quantity_buy}
                  size="small"
                  // label="หัวข้อ"
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.quantity_buy}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, quantity_buy: e.target.value };
                    })
                  }
                />
              </div>
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  ยอดค่าใช้จ่าย
                </p>
                <TextField
                  id="outlined-basic"
                  value={addData.expenses}
                  size="small"
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.expenses}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (/^\d*$/.test(value)) {
                      setAddData((prevState) => {
                        return { ...prevState, expenses: value };
                      });
                    }
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Button-save">
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "14px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            className="button-h"
            onClick={addValidators}
          >
            บันทึก
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default FormAddModal;
