import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { Typography, InputLabel } from "@mui/material";
// import rows from "./data/TableData";
import { svGetHistoryBill } from "../../services/overalldisplay/billingelectricitybill.service";
import moment from "moment";

const HistoryElectricWater = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    svGetHistoryBill().then((res) => {
      console.log(res.data.history);
      if (res.status === 201) {
        setHistoryData(res.data.history);
      }
    });
  }, []);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 100,
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.row.zoesBoxe}</div>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อผู้เช่า",
      sortable: false,
      width: 150,
      flex: 1,
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.box_color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.row.types_name}</div>
          </div>
        );
      },
    },
    {
      field: "datestartrent",
      headerName: "วันที่เริ่มเช่า",
      width: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {moment(params.row.datestartrent).format("DD/MM/YYYY").toString()}
          </>
        );
      },
    },
    {
      field: "latest_created_at",
      headerName: "วันที่เก็บล่าสุด",
      width: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.latest_created_at}</>;
      },
    },
    {
      field: "total_expenses",
      width: 160,
      sortable: false,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}
          >
            ค่าไฟต่อวัน
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "status",
      headerName: "สถานะการจ่าย",
      width: 150,
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "จ่ายแล้ว" ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.status}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.latest_created_at}
          </p>
        </div>
      ),
    },
    {
      field: "Update",
      headerName: "รายละเอียด",
      headerAlign: "center",
      align: "center",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3" }}
          component={Link}
          to={`/history/details/${params.row.id}`}
          target={"_blank"}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];

  const rowsFiltered = historyData
    .filter((row) => {
      if (
        (age === "" || row.zone_name === age) &&
        (searchInput === "" ||
          row.name.includes(searchInput) ||
          row.zoesBoxe.includes(searchInput.toUpperCase()))
      ) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) =>
      a.zone_name.localeCompare(b.zone_name, undefined, { numeric: true })
    );

  const zones = Array.from(new Set(historyData.map((row) => row.zone_name)));

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header" style={{ marginBottom: "1.5rem" }}>
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          ประวัติ ชำระค่าไฟ
        </div>
        <div className="search">
          <div className="">
            <FormControl fullWidth size="small" style={{ width: "100px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="ค้นหา บล็อก,ผู้เช่า"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            href="/billingcycle"
            className="button-h"
          >
            ชำระค่าไฟ
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HistoryElectricWater;
