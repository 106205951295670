/* eslint-disable */
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import { Card } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PulseLoader from "react-spinners/PulseLoader";
import "./BusinessType.scss";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import Table from "./components/Table";
import { Form, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { maxWidth } from "@mui/system";
import FormAddColor from "./components/FormAddColor";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import { svBusinessTypeData } from "../../services/setting/businesstype.service";
import FormEditBusinesstypes from "./components/FormEditBusinesstypes";
// import SearchIcon from "@mui/icons-material/Search";

function BusinessType() {
  const { t } = useTranslation(["dashboard-page"]);

  const [loading, setLoading] = useState(true);
  const [openshop, setOpenShop] = useState(false);
  const [modalEdti, setModalEdit] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [businessTypesData, setBusinessTypesData] = useState([]);
  const [businessTypesEdit, setBusinessTypesEdit] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    svBusinessTypeData().then((res) => {
      console.log(res);
      setBusinessTypesData(res.data.typesname);
    });
  }, [refreshData]);

  console.log(businessTypesData);

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const ClosemodalEdit = () => {
    setModalEdit(false);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredData = businessTypesData.filter((data) =>
    data.types_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <section id="defective-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              className=""
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              ประเภทธุรกิจ
            </p>
          </div>
          <div className="add-box">
            <div className="" style={{ marginRight: "12px" }}>
              {" "}
              <TextField
                label="ค้นหาประเภทสินค้า"
                size="small"
                style={{ width: "180px" }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                // InputProps={{
                //   endAdornment: (
                //     <SearchIcon style={{ color: "grey", marginLeft: "8rem" }} />
                //   ),
                // }}
              />
            </div>

            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#707070",
                color: "white",
                height: "39px",
              }}
              component="a"
              onClick={handleOpenShop}
              className="button-h"
            >
              เพิ่มข้อมูลธุรกิจ
            </Button>
          </div>
        </div>
        <div>
          <Table
            businessTypesData={filteredData}
            handlemodalEdit={setModalEdit}
            setBusinessTypesEdit={setBusinessTypesEdit}
            setRefreshData={setRefreshData}
          />
        </div>

        <Modal open={openshop} onClose={handleCloseShop}>
          <Box
            className="width-modal-block"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "500px",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 3,
            }}
          >
            <FormAddColor
              handlemodalAdd={setOpenShop}
              refreshData={setRefreshData}
            />
          </Box>
        </Modal>

        <Modal open={modalEdti} onClose={ClosemodalEdit}>
          <Box
            className="width-modal-block"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "500px",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 3,
            }}
          >
            <FormEditBusinesstypes
              businessTypesEdit={businessTypesEdit}
              setModalEdit={setModalEdit}
              setRefreshData={setRefreshData}
            />
          </Box>
        </Modal>
      </>
      {/* )} */}
    </section>
  );
}

export default BusinessType;
