/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Checkbox from "@mui/material/Checkbox";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Radio from "@mui/material/Radio";
import "./Salary.scss";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import AddEditFrom from "./AddEditFrom";
import CancelIcon from "@mui/icons-material/Cancel";
import rows from "./data/TableData";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import UpdateFrom from "./UpdateFrom";
import {
  svEmployeData,
  svEmployeDelete,
  svSalaeyReset,
  svSalaryUpdate,
} from "../../services/expenses/employe.service";
import moment from "moment";
import { name } from "dayjs/locale/th";
import SwalUI from "../../components/ui/swal-ui/swal-ui";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const modalSwal = withReactContent(Swal);
import { Tune } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Salary() {
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const uPermission = useSelector((state) => state.auth.userPermission);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [openshop, setOpenShop] = useState(false);
  // const [openshopUpdate, setOpenShopUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  // const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("tambon3");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  /* const [nameEmpolyee, setTotalSelectedName] = useState(null);
   const [salary, setTotalSelectedSalary] = useState(null);
   const [position, setTotalSelectedPosition] = useState(null);
   const [imgEmpolyee, setTotalSelectedImgEmpolyee] = useState(null);
   const [startDate, setTotalSelectedStartDate] = useState(null);*/
  const [checkedStates, setCheckedStates] = useState({});
  const [refreshData, setRefreshData] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [paymentDate, setPaymentDate] = useState({});

  // console.log("checkedStates", checkedStates);

  useEffect(() => {
    const savedCheckedStates = localStorage.getItem("checkedStates");
    if (savedCheckedStates) {
      setCheckedStates(JSON.parse(savedCheckedStates));
    }

    const savedPaymentDate = localStorage.getItem("paymentDate");
    if (savedPaymentDate) {
      setPaymentDate(JSON.parse(savedPaymentDate));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("checkedStates", JSON.stringify(checkedStates));
    localStorage.setItem("paymentDate", JSON.stringify(paymentDate));
  }, [checkedStates]);

  // useEffect(() => {
  //   const now = new Date();
  //   const day = now.getDate();
  //   const day = 2;

  //   if (day === 25) {
  //     handleResetCheckedStates();
  //   }
  // }, []);

  useEffect(() => {
    svEmployeData().then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setFilteredData(res.data.employees);
      }
    });
  }, [refreshData]);

  const handleChangeSwitch = ({ name, emploteId }) => {
    console.log(emploteId, name);

    if (name != undefined && !checkedStates[emploteId]) {
      Swal.fire({
        title: "ยืนยันการจ่ายเงิน",
        text: `คุณ ${name}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "จ่ายเงิน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("employeesId", emploteId);
          formData.append("paymentDate", paymentDate.date);
          formData.append("paymentSalaryDate", moment().format("DD/MM/YYYY"));

          svSalaryUpdate(formData).then((res) => {
            // Swal.fire("จ่ายเงินแล้ว!", "", "success")
            // console.log(res.response.data);
            if (res.status === 200) {
              if (res.data.status) {
                setRefreshData((prev) => prev + 1);
                setCheckedStates((prev) => ({
                  ...prev,
                  [emploteId]: true,
                }));
              }
              SwalUI({
                status: res.data.status,
                //description: res.data.description
              });
            } else {
              SwalUI({
                status: res.response.data.status,
                description: res.response.data.description,
              });
            }
          });
        }
      });
    }
  };

  const handleResetCheckedStates = () => {
    Swal.fire({
      title: "ยืนยันการรีเซ็ตสถานะ",
      text: "คุณแน่ใจหรือไม่ที่ต้องการรีเซ็ตสถานะการจ่ายเงินทั้งหมดของพนักงาน?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        svSalaeyReset().then((res) => {
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem("checkedStates", JSON.stringify({}));
            setCheckedStates({});

            setPaymentDate((prev) => ({
              ...prev,
              date: "30/" + moment().format("MM/YYYY"),
            }));
            setRefreshData((prev) => prev + 1);
            Swal.fire("สำเร็จ!", "รีเซ็ตสถานะเรียบร้อยแล้ว", "success");
          }
        });
      }
    });
  };

  const uniquePositions = [
    ...new Set(filteredData.map((row) => row.department)),
  ];

  const isValidDate = (date) => date !== null && dayjs(date).isValid();

  const exportToExcel = (filteredData, columns) => {
    if (!Array.isArray(filteredData) || filteredData.length === 0) {
      console.error("Invalid data: Data should be a non-empty array.");
      return;
    }

    try {
      const filteredDataValid = filteredData.filter((row) => {
        return isValidDate(row.work_start_date) && row.salary > 0;
      });

      const worksheet = XLSX.utils.json_to_sheet([]);

      XLSX.utils.sheet_add_aoa(worksheet, [["เงินเดือนพนักงาน"]], {
        origin: "A1",
      });
      worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }];

      const selectedFields = {
        full_name: "ชื่อพนักงาน",
        telephone_number: "เบอร์ติดต่อ",
        work_start_date: "วันเริ่มงาน",
        latest_salary: "วันที่่จ่ายเงินล่าสุด",
        department: "ตำแหน่ง",
        salary: "เงินเดือน",
      };

      XLSX.utils.sheet_add_aoa(worksheet, [Object.values(selectedFields)], {
        origin: "A2",
      });

      const filteredRows = filteredDataValid.map((row) => {
        const filteredRow = {
          ชื่อพนักงาน: `${row.first_name} ${row.last_name}`,
          เบอร์ติดต่อ: row.telephone_number,
          วันเริ่มงาน: row.work_start_date,
          วันที่่จ่ายเงินล่าสุด: row.latest_salary,
          ตำแหน่ง: row.department,
          เงินเดือน: row.salary,
        };
        return filteredRow;
      });

      XLSX.utils.sheet_add_json(worksheet, filteredRows, {
        origin: "A3",
        skipHeader: true,
      });

      const totalSalary = filteredDataValid.reduce(
        (sum, row) => sum + (row.salary || 0),
        0
      );
      const formattedTotal = totalSalary.toLocaleString();

      const lastRow = filteredDataValid.length + 3;
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["", "", "", "", "รวม", formattedTotal]],
        { origin: `A${lastRow}` }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const wbArrayBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });
      const wbBlob = new Blob([wbArrayBuffer], {
        type: "application/octet-stream",
      });

      saveAs(wbBlob, "salary_data.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpenEdit = (row) => {
    console.log(row);
    setSelectedRow(row);
    setOpenEdit(true);
  };

  // const handleCloseShopUpdate = () => {
  //   setOpenShopUpdate(false);
  // };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const handleCancel = () => {
    handleCloseShop();
  };

  // const handleStatus = (event) => {
  //   setSelectedStatus(event.target.value);
  // };

  const handlePosition = (event) => {
    setSelectedPosition(event.target.value);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    setSelectedValue("tambon3");
  };

  const handleDelete = (id) => {
    // console.log("Delete row with id:", id);

    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        // text: "I want to delete this data!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svEmployeDelete(id).then((res) => {
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);
              SwalUI({
                status: res.data.status,
                //description: res.data.description
              });
            } else {
              SwalUI({
                status: res.data.status,
                //description: res.data.description
              });
            }
          });
        }
      });
  };

  const columns = [
    {
      field: "url_img",
      headerName: "ภาพพนักงาน",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(uploadPath + params.value)}
        />
      ),
    },
    {
      field: "first_name",
      headerName: "ชื่อพนักงาน",
      sortable: false,
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <>{params.row.first_name + " " + params.row.last_name}</>
      ),
    },
    {
      field: "telephone_number",
      headerName: "เบอร์ติดต่อ",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "work_start_date",
      headerName: "วันเริ่มงาน",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "department",
      headerName: "ตำแหน่ง",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "salary",
      headerName: "เงินเดือน",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "latest_salary",
      headerName: "วันที่จ่ายเงินเดือน",
      width: 140,
    },
    {
      field: "statusSalary",
      headerName: "สถานะการจ่าย",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status_salary === 1 ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.status_salary === 1 ? "จ่ายแล้ว" : "ยังไม่จ่าย"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.status_salary === 1
              ? params.row.latest_salary
              : paymentDate.date}
            {/* paymentDate */}
          </p>
        </div>
      ),
    },

    {
      field: "CheckStatus",
      headerName: "จ่ายเงิน",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const isChecked = checkedStates[params.id] || false;
        const switchColor = isChecked ? "#00D715" : "#6c5dd3";
        return (
          <Switch
            checked={isChecked}
            onChange={() =>
              handleChangeSwitch({
                name: params.row.first_name + " " + params.row.last_name,
                emploteId: params.row.id,
              })
            }
            size="small"
            style={{ color: switchColor }}
          />
        );
      },
    },
    {
      field: "Update",
      headerName: "ประวัติการจ่ายเงิน",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#6C5DD3",
            transition: "background-color 0.3s",
          }}
          component={Link}
          to={`/salary/detail/${params.row.id}`}
          target={"_blank"}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "แก้ไข",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          className="edit-button"
          onClick={() => {
            handleOpenEdit(params.row);
          }}
        >
          <img src="/images/icons/mage_edit.png" alt="" />
        </button>
      ),
    },
    {
      field: "del",
      headerName: "ลบ",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          className="delete-button"
          onClick={() => handleDelete(params.row.id)}
        >
          <img src="/images/icons/gravity-ui_trash-bin.png" alt="" />
        </button>
      ),
    },
  ];

  const rowsFiltered = filteredData.filter((row) => {
    let rentalStatusFilter = false;

    if (selectedValue === "tambon1" && row.status_salary === 1) {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon2" && row.status_salary === 0) {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon3") {
      rentalStatusFilter = true;
    }

    let positionFilter = true;
    if (selectedPosition && selectedPosition !== "All") {
      positionFilter = row.department === selectedPosition;
    }

    if (
      (selectedValue === "a" ||
        selectedValue === "b" ||
        selectedValue === "c" ||
        selectedValue === "d") &&
      positionFilter
    ) {
      const startDate = dayjs(row.latest_salary, "DD/MM/YYYY");
      const endDate = dayjs(row.latest_salary, "DD/MM/YYYY");

      const dayFilter =
        selectedValue === "a" &&
        isValidDate(selectedDate1) &&
        startDate.date() === selectedDate1.date() &&
        startDate.month() === selectedDate1.month() &&
        startDate.year() === selectedDate1.year();
      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        startDate.month() === selectedDate2.month() &&
        startDate.year() === selectedDate2.year();
      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        startDate.year() === selectedDate3.year();
      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(selectedStartDate - 1).isBefore(startDate) &&
        dayjs(selectedEndDate + 1).isAfter(endDate);

      const dateFilter =
        dayFilter || monthFilter || yearFilter || dateRangeFilter;

      return dateFilter;
    }

    if (
      (age === "" || row.Zone === age) &&
      row.first_name.includes(searchInput) &&
      rentalStatusFilter &&
      positionFilter
    ) {
      return true;
    } else {
      return false;
    }
  });
  // console.log(rows);
  // const zones = Array.from(new Set(rows.map((row) => row.Zone)));

  // const zoneOptions = zones.map((zone, index) => (
  //   <MenuItem key={index} value={zone}>
  //     {zone}
  //   </MenuItem>
  // ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.Total += row.salary;
      return accumulator;
    },
    {
      Total: 0,
    }
  );

  const { Total } = totals;

  const formattedTotal = Total.toLocaleString();

  return (
    <section>
      <>
        <div className="nav1">
          <div style={{ width: "100%" }}>
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              เงินเดือนพนักงาน
            </p>
          </div>
          <div className="">
            <TextField
              label="ค้นหา พนักงาน"
              id="outlined-size-small"
              size="small"
              style={{ width: "230px" }}
              onChange={(e) => searchSupplier(e)}
              InputProps={{
                endAdornment: <SearchIcon style={{ color: "grey" }} />,
              }}
            />
          </div>
        </div>

        <div className="from-warter1">
          <div className="box-total-m">
            <p style={{ fontSize: "18px", fontWeight: 500 }}>
              เงินเดือนทั้งหมด
            </p>
            <p style={{ fontSize: "2rem", fontWeight: 700 }}>
              {formattedTotal} บาท
            </p>
          </div>

          {/* บล็อกด้านขวา */}
          <div className="from-filter1">
            <div className="button-text">
              <p
                className="p-salary"
                style={{ fontWeight: 700, fontSize: "18px" }}
              >
                เลือก
              </p>

              <div
                className="button-salary"
                style={{ display: "flex", gap: "1rem" }}
              >
                {uPermission.superAdmin && (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontSize: "1rem",
                      background: "#6C5DD3",
                      color: "white",
                      height: "39px",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      // marginTop: "2rem",
                    }}
                    className="button-h"
                    onClick={handleResetCheckedStates}
                  >
                    รีเซ็ตการจ่ายเงิน
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "1rem",
                    background: "#6C5DD3",
                    color: "white",
                    height: "39px",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    // marginTop: "2rem",
                  }}
                  className="button-h"
                  onClick={handleOpenShop}
                >
                  เพิ่มพนักงาน
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "1rem",
                    background: "#6C5DD3",
                    color: "white",
                    height: "39px",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    // marginTop: "2rem",
                  }}
                  className="button-h"
                  onClick={() => exportToExcel(rowsFiltered, columns)}
                >
                  EXPORT TO EXCEL
                </Button>
              </div>
            </div>

            {/* Modal add employee */}
            <Modal open={openshop} onClose={handleCloseShop}>
              <Box
                className="width-modal1"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  p: 4,
                }}
              >
                <h1
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "500",
                    marginBottom: "0rem",
                  }}
                >
                  เพิ่ม / แก้ไข ข้อมูลพนักงาน
                </h1>
                <button onClick={handleCancel}>
                  <CancelIcon className="CancelIcon" />
                </button>

                <AddEditFrom
                  modalClose={handleCloseShop}
                  setRefreshData={setRefreshData}
                />
              </Box>
            </Modal>

            <div className="from-Date-filter1">
              {/* <div className="date-1">
                <Radio {...controlProps("a")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  วัน
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วัน"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "a"}
                    value={selectedDate1}
                    onChange={handleDateChange1}
                    views={["day", "month", "year"]}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        style={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div> */}
              <div className="date-1">
                <Radio {...controlProps("b")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  เดือน
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="เดือน"
                    inputFormat="MM/YYYY"
                    views={["month", "year"]}
                    disabled={selectedValue !== "b"}
                    value={selectedDate2}
                    onChange={handleDateChange2}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-2">
                <Radio {...controlProps("c")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ปี
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="ปี"
                    inputFormat="YYYY"
                    views={["year"]}
                    disabled={selectedValue !== "c"}
                    value={selectedDate3}
                    onChange={handleDateChange3}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel id="select-label-tambon">ตำแหน่ง</InputLabel>
                  <Select
                    labelId="select-label-tambon"
                    id="select-tambon"
                    label="สถานะการจ่าย"
                    value={selectedPosition}
                    onChange={handlePosition}
                  >
                    <MenuItem value="All">ทั้งหมด</MenuItem>
                    {uniquePositions.map((position, index) => (
                      <MenuItem key={index} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="date-4">
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel id="select-label-tambon">สถานะการเช่า</InputLabel>
                  <Select
                    labelId="select-label-tambon"
                    id="select-tambon"
                    label="สถานะการจ่าย"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <MenuItem value="tambon3">ทั้งหมด</MenuItem>
                    <MenuItem value="tambon1">จ่ายแล้ว</MenuItem>
                    <MenuItem value="tambon2">ยังไม่จ่าย</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="date-5">
                <Radio {...controlProps("d")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ตั้งแต่
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="ตั้งแต่"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-6">
                <p
                  className="date-2-text"
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginRight: "19px",
                  }}
                >
                  จนถึง
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="จนถึง"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-7">
                <Radio {...controlProps("tambon3")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                  }}
                >
                  ทั้งหมด
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <Button onClick={handleResetCheckedStates}>Reset localStorage</Button> */}
        <DataGrid
          rows={rowsFiltered}
          columns={columns}
          rowHeight={90}
          rowsPerPageOptions={[5]}
          className="custom-data-grid"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={selectedImage}
              alt="Zoomed Shop"
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                width: "500px",
              }}
            />
          </div>
        </Modal>

        <Modal
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
          }}
        >
          <Box
            className="width-modal2"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 4,
            }}
          >
            <h1
              style={{
                fontSize: "1.4rem",
                fontWeight: "500",
                marginBottom: "0rem",
              }}
            >
              อัปเดตข้อมูล พนักงาน
            </h1>
            <button
              onClick={() => {
                setOpenEdit(false);
              }}
            >
              <CancelIcon className="CancelIcon" />
            </button>

            <UpdateFrom
              selectedRow={selectedRow}
              setOpenEdit={setOpenEdit}
              setRefreshData={setRefreshData}
            />
          </Box>
        </Modal>
      </>
    </section>
  );
}

export default Salary;
