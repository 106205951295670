import axios from "axios";

export const svMaintenanceData=()=>{
    return axios.get(`repair/data`).then((res)=> res).catch((error) => error);
}

export const svCreateMaintenance=(formData)=>{
    return axios.post(`repair/create`,formData).then((res)=> res).catch((error) => error);
}

export const svUpdataMaintenance=(formData)=>{
    return axios.post(`repair/update`,formData).then((res)=> res).catch((error) => error);
}

export const svDeleteMaintenance=(id)=>{
    return axios.delete(`repair/destroy/${id}`).then((res)=> res).catch((error) => error);
}