import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../../store/app-slice';
import { authActions } from '../../../store/auth-slice';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sidebar.scss';
import {
  faCaretDown,
  // faListOl,
  // faSignsPost,
  // faSitemap,
  // faNewspaper,
  // faGamepad,
  // faBoxOpen,
  // faImages,
  // faTools,
  // faLanguage,
  // faUserShield,
  // faCircleInfo,
  // faStreetView,
  // faInbox,
  // faComments,
  // faFileCsv,
  // faHome,
  // faIcons,
  // faEnvelope,
  // faBook,
  faArrowRightFromBracket,
  // faStore,
  // faFileCirclePlus,
  // faCalendarCheck,
  // faCheckToSlot,
  // faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import NavLink from './navlink';
import { Link } from 'react-router-dom';
import { fontWeight } from '@mui/system';
import { faNoteSticky } from '@fortawesome/free-regular-svg-icons';

const SidebarComponent = (props) => {
  const { t, i18n } = useTranslation('sidebar');

  const dispatch = useDispatch();
  const uPermission = useSelector((state) => state.auth.userPermission);
  const selectedLanguage = useSelector((state) => state.app.language);
  const webPath = useSelector((state) => state.app.webPath);
  const activateLanguage = useSelector((state) => state.auth.activateLanguage);
  const pagesAllow = useSelector((state) => state.app.pages);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const isDevMode = useSelector((state) => state.app.isDevMode);
  const { displayName, email, profileImage } = useSelector(
    (state) => state.auth.profile
  );
  const userRoleName = useSelector((state) => state.auth.userRoleName);

  const OnSignOutHandler = () => {
    dispatch(authActions.logout());
  };
  const languageSelectHandler = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(appActions.changeLanguage(lang));
  };

  const toggleSubMenu = (event) => {
    const subMenu = event.target.closest('.has-child');
    subMenu.classList.toggle('opened');
  };

  const closeSidebarhandler = (e) => {
    /* ย่อแถบทำงานเฉพาะ width < 768 */
    let elRoot = document.querySelector('#root');
    if (elRoot && elRoot.offsetWidth <= 900) {
      props.collapseHandler(true);
    }
  };

  return (
    <aside className="aside">
      <nav>
        <Link className="sidenav-header" to="/">
          <figure className="figure-image">
            <img
              src="/images/icons/logo-web-UFOmarket.png"
              className="website-logo"
            />
          </figure>
        </Link>
        {/* <hr className="line-section" />
        <div className="title-section">{t("Languages")}</div>
        <div className="language-selection">
          {activateLanguage.map((lang) => (
            <Button
              variant="outlined"
              key={lang}
              onClick={(e) => languageSelectHandler(lang)}
              className={`btn-lang ${
                lang.toLowerCase() === selectedLanguage.toLowerCase()
                  ? "selected"
                  : ""
              }`}
            >
              {lang}
            </Button>
          ))}
        </div> */}

        {/* <hr className="line-section gap " /> */}
        <div className="sidenav-main">
          {pagesAllow.groups.notify && (
            <Fragment>
              <div className="title-section">{t('ส่วนแสดงผลรวม')}</div>
              <ul className="nav-menu">
                {pagesAllow.dashboard && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/dashboard"
                      className="navlink"
                      title={t('dashboardPage')}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <img
                          src="/images/icons/dashboard.png"
                          className="white-image"
                          alt="logo"
                        />
                      </figure>
                      <div className="menu-title">{t('หน้าแดชบอร์ด')}</div>
                    </NavLink>
                  </li>
                )}

                {/* {pagesAllow.messages && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/messages"
                      className={`navlink `}
                      title={t("MessagesPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faComments} />
                      </figure>
                      <div className="menu-title">{t("MessagesPage")}</div>
                    </NavLink>
                  </li>
                )}
                {pagesAllow.inbox && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/inbox"
                      className={`navlink `}
                      title={t("InboxPage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faInbox} />
                      </figure>
                      <div className="menu-title">{t("InboxPage")}</div>
                    </NavLink>
                  </li>
                )} */}
                {/* {pagesAllow.subscribe && (
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/subscribe"
                      className={`navlink `}
                      title={t("SubscribePage")}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </figure>
                      <div className="menu-title">{t("SubscribePage")}</div>
                    </NavLink>
                  </li>
                )} */}
              </ul>
            </Fragment>
          )}
          {/* <hr className="line-section gap " /> */}
          {pagesAllow.groups.product && (
            <Fragment>
              {/* <hr className="line-section gap" /> */}
              {/* <div className="title-section">{t("RenterInformation")}</div> */}
              {/* <ul className="nav-menu">
                <li className="menu-link has-child opened">
                  <a
                    className={`navlink `}
                    title={t("ProductsTitleMenu")}
                    onClick={toggleSubMenu}
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <figure
                      className="faIcon"
                      style={{ paddingRight: ".25rem" }}
                    >
                      <img src="/images/icons/imports-icon.png" alt="" />
                    </figure>
                    <div className="menu-title">{t("เพิ่มสินค้า")}</div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      <li>
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/products/import"
                          className={`items `}
                          title={t("เพิ่มสินค้าหลัก")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faSignsPost} />
                          </span>
                          <span className="menu-title">
                            {t("เพิ่มสินค้าหลัก")}
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={closeSidebarhandler}
                          to="/subproduct/import"
                          className={`items `}
                          title={t("เพิ่มสินค้าย่อย")}
                          liClass="sub-items"
                        >
                          <span className="collap-title">
                            <FontAwesomeIcon icon={faSignsPost} />
                          </span>
                          <span className="menu-title">
                            {t("เพิ่มสินค้าย่อย")}
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul> */}
              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/products"
                    className="navlink"
                    title={t('productPage')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/icon-park-outline_shop.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">ข้อมูลการเช่า</div>
                  </NavLink>
                </li>
              </ul>

              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/products/add"
                    className="navlink"
                    title={t('เพิ่มข้อมูลการเช่า')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/lucide_file-plus.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('เพิ่มข้อมูลการเช่า')}</div>
                  </NavLink>
                </li>
              </ul>

              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/expiration"
                    className="navlink"
                    title={t('วันที่ จ่าย/เก็บ ค่าเช่า')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/quill_calendar.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">
                      {t('วันที่ จ่าย/เก็บ ค่าเช่า')}
                    </div>
                  </NavLink>
                </li>
              </ul>
              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/payRent"
                    className="navlink"
                    title={t('ประวัติ เช่า/ชำระค่าเช่า')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/majesticons_paper-fold-line.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">
                      {t('ประวัติ เช่า/ชำระค่าเช่า')}
                    </div>
                  </NavLink>
                </li>
              </ul>

              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/billingcycle"
                    className="navlink"
                    title={t('ชำระค่าไฟ')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/bill.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('ชำระค่าไฟ')}</div>
                  </NavLink>
                </li>
              </ul>
              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/history/electricWater"
                    className="navlink"
                    title={t('ประวัติ ชำระค่าไฟ')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/history.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('ประวัติ ชำระค่าไฟ')}</div>
                  </NavLink>
                </li>
              </ul>

              {/* <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/proof-payment"
                    className="navlink"
                    title={t('หลักฐานการจ่ายเงิน')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/solar_bill-check-linear.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('หลักฐานการจ่ายเงิน')}</div>
                  </NavLink>
                </li>
              </ul> */}

              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/bathroomservice"
                    className="navlink"
                    title={t('ค่าบริการห้องน้ำ')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/icon-park-outline_public-toilet.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('ค่าบริการห้องน้ำ')}</div>
                  </NavLink>
                </li>
              </ul>
              <ul className="nav-menu">
                <li>
                  <NavLink
                    onClick={closeSidebarhandler}
                    to="/waterDispenser"
                    className="navlink"
                    title={t('ค่าบริการตู้กดน้ำ')}
                    liClass="menu-link"
                  >
                    <figure className="faIcon">
                      <img
                        src="/images/icons/uil_water-glass.png"
                        className="white-image"
                        alt="logo"
                      />
                    </figure>
                    <div className="menu-title">{t('ค่าบริการตู้กดน้ำ')}</div>
                  </NavLink>
                </li>
              </ul>
            </Fragment>
          )}

          {pagesAllow.groups.product &&
            (uPermission.superAdmin || uPermission.admin) && (
              <Fragment>
                <hr className="line-section gap" />
                <div className="title-section">{t('ข้อมูลผู้เช่า')}</div>
                <ul className="nav-menu">
                  <li>
                    <NavLink
                      onClick={closeSidebarhandler}
                      to="/store"
                      className="navlink"
                      title={t('ร้านค้า/ผู้เช่า')}
                      liClass="menu-link"
                    >
                      <figure className="faIcon">
                        <img
                          src="/images/icons/solar_user-id-linear (1).png"
                          className="white-image"
                          alt="logo"
                        />
                      </figure>
                      <div className="menu-title">{t('ร้านค้า/ผู้เช่า')}</div>
                    </NavLink>
                  </li>
                </ul>
              </Fragment>
            )}

          {(uPermission.superAdmin || uPermission.admin) && (
            <>
              <hr className="line-section gap" />
              <ul className="nav-menu">
                <li className={`menu-link has-child `}>
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title="User"
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <div
                      className="menu-title"
                      style={{ fontWeight: 400, fontSize: '20px' }}
                    >
                      ค่าใช้จ่าย
                    </div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.groups.product && (
                        <Fragment>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/salary"
                                className="navlink"
                                title={t('เงินเดือน')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/carbon_report (1).png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('เงินเดือน')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/maintenancecosts"
                                className="navlink"
                                title={t('ค่าซ่อม/บำรุงรักษา')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/Vector.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ค่าซ่อม/บำรุงรักษา')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/garbagecollec"
                                className="navlink"
                                title={t('ค่าเก็บขยะ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/mage_light-bulb-electricity.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ค่าเก็บขยะ')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/waterbill"
                                className="navlink"
                                title={t('ค่าน้ำ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/healthicons_running-water-outline.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">{t('ค่าน้ำ')}</div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/electricitybill"
                                className="navlink"
                                title={t('ค่าไฟ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/tabler_sort-ascending-numbers.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">{t('ค่าไฟ')}</div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/clean"
                                className="navlink"
                                title={t('ค่าซื้ออุปกรณ์ทำความสะอาด')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon-items">
                                  <img
                                    src="/images/icons/icon-park-outline_shop.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ค่าซื้ออุปกรณ์ทำความสะอาด')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/miscellaneous"
                                className={`navlink `}
                                title={t('ค่าใช้จ่ายเบ็ดเตล็ด')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/humbleicons_coins.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ค่าใช้จ่ายเบ็ดเตล็ด')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                        </Fragment>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </>
          )}

          {(uPermission.superAdmin || uPermission.admin) && (
            <>
              <hr className="line-section gap" />
              <ul className="nav-menu">
                <li className={`menu-link has-child `}>
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title="User"
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <div
                      className="menu-title"
                      style={{ fontWeight: 400, fontSize: '20px' }}
                    >
                      รายงาน
                    </div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.groups.product && (
                        <Fragment>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/allstores"
                                className="navlink"
                                title={t('ร้านค้าทั้งหมด')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/carbon_report (1).png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ร้านค้าทั้งหมด')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/rentalincome"
                                className="navlink"
                                title={t('รายได้ค่าเช่า ค่าปรับ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/Vector.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('รายได้ค่าเช่า ค่าปรับ')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>

                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/waterincome"
                                className="navlink"
                                title={t('รายได้ค่าไฟ ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/mage_light-bulb-electricity.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('รายได้ค่าไฟ ')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/variousexpenses"
                                className="navlink"
                                title={t('ค่าใช้จ่ายต่าง ๆ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/healthicons_running-water-outline.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ค่าใช้จ่ายต่าง ๆ')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/stock"
                                className="navlink"
                                title={t('เลขบล็อก')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/tabler_sort-ascending-numbers.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('เลขบล็อก')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/defective"
                                className="navlink"
                                title={t('ร้านค้า')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/icon-park-outline_shop.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">{t('ร้านค้า')}</div>
                              </NavLink>
                            </li>
                          </ul>
                          <ul className="nav-menu">
                            <li>
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/rentalAmount"
                                className={`navlink `}
                                title={t('จำนวนค่าเช่า')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/humbleicons_coins.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('จำนวนค่าเช่า')}
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                        </Fragment>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </>
          )}

          {uPermission.superAdmin && (
            <>
              <hr className="line-section gap" />
              <ul className="nav-menu">
                <li className={`menu-link has-child `}>
                  <a
                    className={`navlink `}
                    onClick={toggleSubMenu}
                    title="User"
                  >
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="toggle-submenu"
                    />
                    <div
                      className="menu-title"
                      style={{ fontWeight: 400, fontSize: '20px' }}
                    >
                      ตั้งค่า
                    </div>
                  </a>
                  <div className="child-menu ">
                    <ul className="nav-items ">
                      {pagesAllow.groups.system && (
                        <Fragment>
                          <ul className="nav-menu">
                            {pagesAllow.suppliers && (
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/blockSize"
                                className={`navlink `}
                                title={t('บล็อก ขนาดบล็อก')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/tabler_ruler.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('บล็อก ขนาดบล็อก')}
                                </div>
                              </NavLink>
                            )}

                            {pagesAllow.productcate && (
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/BusinessType"
                                className={`navlink `}
                                title={t('ประเภทธุรกิจ')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/lucide_flag.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ประเภทธุรกิจ')}
                                </div>
                              </NavLink>
                            )}
                            {pagesAllow.admins && (
                              <NavLink
                                onClick={closeSidebarhandler}
                                to="/admins"
                                className={`navlink `}
                                title={t('AdminPage')}
                                liClass="menu-link"
                              >
                                <figure className="faIcon">
                                  <img
                                    src="/images/icons/user.png"
                                    className="white-image"
                                    alt="logo"
                                  />
                                </figure>
                                <div className="menu-title">
                                  {t('ผู้ดูแลระบบ')}
                                </div>
                              </NavLink>
                            )}
                          </ul>
                        </Fragment>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>

      <ul
        className="nav-menu mini-bar"
        style={{ marginTop: 'auto', paddingRight: '.5rem' }}
      >
        {/* <li className="menu-link footerLink">
          <a href={webPath} target="_blank" className="navlink pink-btn " title={t("GoToWebSite")}>
            <figure className="faIcon">
              <FontAwesomeIcon icon={faHome} />
            </figure>
            <span className="menu-title">{t("GoToWebSite")}</span>
          </a>
        </li> */}
      </ul>

      {/* <div className="powerBy"> */}
      <ul className="nav-menu">
        <li className={`menu-link has-child `}>
          <a className={`navlink `} onClick={toggleSubMenu} title="User">
            <FontAwesomeIcon icon={faCaretDown} className="toggle-submenu" />

            <figure className="figure-image-user">
              <img
                src={profileImage || '/images/logo/Ellipse 2.png'}
                className="user-pic"
                onError={(e) =>
                  e.target.setAttribute('src', '/images/default-user.png')
                }
              />
            </figure>
            <div className="menu-title">
              <div className="menu-title-user"> {displayName}</div>
              <span className="user-role">{userRoleName}</span>
            </div>
          </a>

          <div className="child-menu ">
            <ul className="nav-items ">
              <NavLink
                onClick={(e) => {
                  closeSidebarhandler(e);
                  OnSignOutHandler();
                }}
                className={`items `}
                title={t('signOut')}
                liClass="sub-items"
              >
                <span className="collap-title">
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </span>

                <span className="menu-title">{t('ออกจากระบบ')}</span>
              </NavLink>
            </ul>
          </div>
        </li>
      </ul>
      {/* </div> */}
    </aside>
  );
};

export default SidebarComponent;
