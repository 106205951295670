import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import rows from "../data/TableData";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/system";
import { Createzone } from "../../../services/setting/boxzone.service";
import { Createboxs } from "../../../services/setting/boxzone.service";

const addDataDefault = {
  addzone: " ",
  selectZone: 0,
  boxnumber: " ",
  boxSize: " ",
};

function FormAddBlock({openmodalboxs,setRefreshData,zonesName}) {
  const [blockSize, setBlockSize] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockNumber, setBlockNumber] = useState("");
  const [openshop, setOpenShop] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    addzone: false,
    selectZone: false,
    boxnumber: false,
    boxSize: false,
  });
  // const handleSearchInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

 

  const handleOpenShop = () => {
    setAddData((prevState) => ({
      ...prevState,
      addzone: " ",
    }));
    setOpenShop(true);
  };
  const handleCloseShop = () => {
    setOpenShop(false);
  };
  const handleAddZoneClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveZone = () => {
    // ทำอะไรบางอย่างเมื่อผู้ใช้บันทึกโซน
    setIsModalOpen(false); // ปิด modal
  };
  // Function to handle reset
  const handleReset = () => {
    // Reset input values
    setBlockNumber("");
    setBlockSize("");
  };

  const zones = Array.from(new Set(rows.map((row) => row.Zone)));

  const zoneOptions = zonesName.map((zone) => (
    <MenuItem key={zone.id} value={zone.id}>
      {zone.zone_name}
    </MenuItem>
  ));

  const addValidatorszone = () => {
    let isValid = true;
    let error = {
      ...isError,
      addzone: addData.addzone.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("zone_name", addData.addzone);
      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      Createzone(formData).then((res) => {

        if(res.status === 201){
          setRefreshData((prev) => prev + 1)
          setOpenShop(false);
          SwalUI({ status: res.data.status, //description: res.data.description

           });
        }else{
          const {zone_name}=res.response.data
          console.log(zone_name[0]);
          setErrorMessage(zone_name[0]);
        }
      }).catch((error)=>{
        console.log(error);
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      selectZone: addData.selectZone == null,
      boxnumber: addData.boxnumber.trim().length < 1,
      boxSize: addData.boxSize.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("zoneselect", addData.selectZone);
      formData.append("boxnumber", addData.boxnumber);
      formData.append("boxsize", addData.boxSize);

      Createboxs(formData).then((res)=>{
        console.log(res);
        if(res.status === 200){
          setOpenShop(false);
          SwalUI({ status: res.data.status, //description: res.data.description 
            });
          setRefreshData((prev) => prev + 1);
          openmodalboxs(false);
        }
      })
    }
  };
  // เริ่ม return ส่วนหน้าบ้าน
  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              className=""
              style={{
                fontSize: "1.5rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              เพิ่มข้อมูลบล็อก / แก้ไขข้อมูล
            </p>
          </div>
        </div>
        <div className="box-form1">
          <div className="first-row">
            <label htmlFor="">เลือกโซน</label>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
            >
              <InputLabel id="select-label-amphoe">A</InputLabel>
             <Select
                labelId="select-label-amphoe"
                id="select-amphoe"
                label="A"
                value={addData.selectZone}
                onChange={(e) =>
                  setAddData((prevState) => {
                    return { ...prevState, selectZone: e.target.value };
                  })
                }
                error={isError.selectZone}
              >
                {zoneOptions}
                <MenuItem
                  value=""
                  style={{ display: "flex", gap: "6px" }}
                  onClick={handleOpenShop}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{ color: "#707070" }}
                  />
                  เพิ่มโซน
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Modal ที่แสดงค้นหาร้านที่มีอยู่ */}
          <Modal open={openshop} onClose={handleCloseShop}>
            <Box
              className="width-modal-block"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                p: 2,
              }}
            >
              <TextField
                label="เพิ่มโซน"
                variant="outlined"
                fullWidth
                value={addData.addzone}
                // onChange={handleSearchInputChange}
                onChange={(e) =>
                  setAddData((prevState) => {
                    return { ...prevState, addzone: e.target.value };
                  })
                }
                error={isError.addzone}
                autoFocus
                sx={{ mb: 2 }}
                InputProps={{
                  style: {
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                helperText={errorMessage}
                FormHelperTextProps={{
                  sx: {
                    color: "red",
                  },
                }}
              />
              <div
                className=""
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "16px",
                    background: "#6C5DD3",
                    color: "white",
                    height: "39px",
                    width: "100px",
                  }}
                  component="a"
                  className="button-h"
                  onClick={addValidatorszone}
                >
                  บันทึก
                </Button>
              </div>
            </Box>
          </Modal>

          <div className="first-row">
            <label htmlFor="">เลขที่บล็อก</label>
            <TextField
              id="outlined-basic"
              label="25"
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={addData.boxnumber}
              onChange={(e) =>
                setAddData((prevState) => {
                  return { ...prevState, boxnumber: e.target.value };
                })
              }
              error={isError.boxnumber}
            />
          </div>
          <div className="first-row">
            <label htmlFor="">ขนาดบล็อก</label>
            <TextField
              id="outlined-basic"
              label="2X2 m"
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={addData.boxSize}
              onChange={(e) =>
                setAddData((prevState) => {
                  return { ...prevState, boxSize: e.target.value };
                })
              }
              error={isError.boxSize}
            />
          </div>
        </div>
        <div className="box-btn">
          {/* <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#FF2C2C",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            onClick={handleReset} // Assigning handleReset as onClick event handler
            className="button-Delete"
          >
            ลบข้อมูล
          </Button> */}

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            // href="/blockSize"
            onClick={()=>{
              openmodalboxs(false)
            }}
            className="button-h"
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            onClick={addValidators}
            className="button-h"
          >
            บันทึก
          </Button>
        </div>
      </>
    </div>
  );
}

export default FormAddBlock;
