import { toPng } from "html-to-image"; // Adjust the import based on your setup
import { jsPDF } from "jspdf";
import dayjs from "dayjs";

export const SaveAsPDFHandler = () => {
    console.log("ok");
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format
    const dom = document.getElementById("print");
    toPng(dom, { pixelRatio: 3 })
      .then((dataUrl) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = dataUrl;
        img.onload = () => {
          const imgProps = img.width / img.height;
          const pdfWidth = 80;
          const pdfHeight = pdfWidth / imgProps;
  
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight],
          });
  
          const paddingX = 3; // 5mm padding on the sides
          const pdfContentWidth =
            pdf.internal.pageSize.getWidth() - 2 * paddingX;
  
          pdf.addImage(
            dataUrl,
            imgProps.fileType,
            paddingX,
            0,
            pdfContentWidth,
            pdfHeight
          );
  
          pdf.save(`invoice-${today}-A01.pdf`);
        };
        img.onerror = (error) => {
          console.error("Error loading image", error);
        };
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };
  