import React, { useState } from 'react';
import ButtonUI from '../../../../components/ui/button/button';
import './BillingCycleEditData.scss';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { svEditDetailsbill } from '../../../../services/overalldisplay/billingelectricitybill.service';
import SwalUI from '../../../../components/ui/swal-ui/swal-ui';
import { width } from '@mui/system';

const BillingCycleEditData = (props) => {
  const { setOpenEditDate, editData, setRefreshData } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [editDataBillsElectricity, setEditDataBillsElectricity] = useState({
    cost_per_day: editData.cost_per_day,
    additional: editData.additional,
    outstanding: editData.outstanding,
    status: editData.status,
    created_at: moment(editData.created_at).format('YYYY-MM-DD'),
  });
  console.log(editData);

  const handleClose = () => {
    setOpenEditDate(false);
  };

  const calculateAverageRent = () => {
    const block = editDataBillsElectricity;
    const total =
      parseFloat(block.cost_per_day || 0) +
      parseFloat(block.additional || 0) +
      parseFloat(block.outstanding || 0);
    return total.toLocaleString('en-US', { minimumFractionDigits: 2 });
  };

  const handleEndDateChange = (date) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    setEditDataBillsElectricity((prevState) => {
      return {
        ...prevState,
        created_at: newDate,
      };
    });
  };

  const addValidators = () => {
    const formData = new FormData();

    formData.append('id', editData.id);
    formData.append('costPerDay', editDataBillsElectricity.cost_per_day);
    formData.append('additional', editDataBillsElectricity.additional);
    formData.append('outstanding', editDataBillsElectricity.outstanding);
    formData.append('costTotalPerDay', calculateAverageRent());
    formData.append('status', editDataBillsElectricity.status);
    formData.append('createdAt', editDataBillsElectricity.created_at);

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // return false;

    svEditDetailsbill(formData)
      .then((res) => {
        // console.log(res);
        if (res.status === 201) {
          setOpenEditDate(false);
          SwalUI({ status: res.data.status, description: res.data.message });
          setRefreshData((prev) => prev + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section id="products-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          gap: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div className="total-billingcycle">
          <div style={{ width: '100%', fontSize: '1.75rem', fontWeight: 500 }}>
            อัปเดตข้อมูลค่าไฟ
          </div>
          {/* <div className="block-total">
          <p>ยอดรวมค่าไฟทั้งหมด : </p>
          <p>{calculateTotalAmount()} บาท</p> 
        </div> */}
        </div>
      </div>
      <div className="box-store-expiration1">
        <div className="store1">
          <p className="p1">รายละเอียดค่าไฟ</p>
          <p style={{ fontSize: '16px', fontWeight: '400' }}>
            {/* บล็อกที่เช่า : {block.zone_name + block.zone_number} */}
          </p>
          <div className="from-box1">
            <TextField
              id={`filled-multiline-flexible`}
              label="ค่าไฟ/ วัน"
              value={editDataBillsElectricity.cost_per_day}
              onChange={(event) =>
                setEditDataBillsElectricity((prevState) => {
                  return {
                    ...prevState,
                    cost_per_day: event.target.value,
                  };
                })
              }
              multiline
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid black',
                  border: '1px solid #808080',
                  borderRadius: '5px',
                },
                inputProps: {
                  pattern: '[0-9]', // Regular expression allowing only numbers
                },
              }}
              onInput={(event) => {
                const { value } = event.target;
                const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                event.target.value = newValue; // Set the new value
              }}
            />

            <TextField
              id={`filled-multiline-flexible`}
              label="ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม"
              multiline
              maxRows={4}
              variant="filled"
              value={editDataBillsElectricity.additional}
              onChange={(event) =>
                setEditDataBillsElectricity((prevState) => {
                  return {
                    ...prevState,
                    additional: event.target.value,
                  };
                })
              }
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid black',
                  border: '1px solid #808080',
                  borderRadius: '5px',
                },
                inputProps: {
                  pattern: '[0-9]', // Regular expression allowing only numbers
                },
              }}
              onInput={(event) => {
                const { value } = event.target;
                const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                event.target.value = newValue; // Set the new value
              }}
            />
          </div>
        </div>
        <div className="store2">
          <p className="p1">สถานะการจ่าย</p>
          <p style={{ fontSize: '16px', fontWeight: '400' }}>
            {/* บล็อกที่เช่า : {block.zone_name + block.zone_number} */}
          </p>
          <div className="from-box2">
            <div className="" style={{ width: '100%' }}>
              <TextField
                id={`filled-multiline-flexible`}
                label="ค่าคงค้าง"
                value={editDataBillsElectricity.outstanding}
                onChange={(event) =>
                  setEditDataBillsElectricity((prevState) => {
                    return {
                      ...prevState,
                      outstanding: event.target.value,
                    };
                  })
                }
                multiline
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    background: 'white',
                    border: '1px solid black',
                    border: '1px solid #808080',
                    borderRadius: '5px',
                  },
                  inputProps: {
                    pattern: '[0-9]', // Regular expression allowing only numbers
                  },
                }}
                onInput={(event) => {
                  const { value } = event.target;
                  const newValue = value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with empty string
                  event.target.value = newValue; // Set the new value
                }}
              />
            </div>

            <FormControl style={{ width: '100%' }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{margin:"6px"}}>
                สถานะการจ่าย
              </InputLabel>
              <Select
                labelId="demo-select-small-label" //
                id="demo-select-small" //
                defaultValue={editDataBillsElectricity.status}
                onChange={(event) =>
                  setEditDataBillsElectricity((prevState) => {
                    return {
                      ...prevState,
                      status: event.target.value,
                    };
                  })
                }
              >
                <MenuItem value={'ยังไม่จ่าย'}>ยังไม่จ่าย</MenuItem>
                <MenuItem value={'จ่ายแล้ว'}>จ่ายแล้ว</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="store3">
          <p className="p1">ค่าไฟย้อนหลัง</p>
          <p style={{ fontSize: '16px', fontWeight: '400' }}>
            {/* บล็อกที่เช่า : {block.zone_name + block.zone_number} */}
          </p>
          <div className="from-box2">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="วันที่เก็บค่าไฟ"
                inputFormat="DD/MM/YYYY"
                value={editDataBillsElectricity.created_at}
                onChange={(date) => handleEndDateChange(date)}
                sx={{ width: '33.33%' }}
                renderInput={(params) => (
                  <TextField
                    // id={`filled-multiline-flexible`}
                    {...params}
                    sx={{ width: '100%' }}
                    size="small"
                    multiline
                    inputProps={{
                      ...params.inputProps,
                      sx: { padding: '8px' },
                      readOnly: true,
                    }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="store4">
          <p className="p1">รอบการจ่ายรายวัน</p>
          <p style={{ fontSize: '16px', fontWeight: '400' }}>
            {/* ยอดรวมค่าไฟบล็อก {block.zone_name + block.zone_number} */}
          </p>
          <div className="from-box2">
            <TextField
              id={`filled-multiline-flexible`}
              label="ยอดรวมค่าไฟทั้งหมด"
              value={calculateAverageRent()}
              multiline
              // maxRows={4}
              variant="filled"
              disabled
              InputProps={{
                disableUnderline: true,
                style: {
                  background: 'white',
                  border: '1px solid black',
                  border: '1px solid #808080',
                  borderRadius: '5px',
                  backgroundColor: '#F3F4F6',
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="status-button">
        <ButtonUI
          isLoading={isFetching}
          onClick={addValidators}
          className="btn-save"
          on="save"
          width="lg"
        >
          บันทึก
        </ButtonUI>
        <ButtonUI
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            width:'120px',
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
          }}
          component="a"
          className="button-h1"
          onClick={handleClose}
        >
          ยกเลิก
        </ButtonUI>
      </div>
    </section>
  );
};

export default BillingCycleEditData;
