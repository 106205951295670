// ข้อมูลจำลอง
export const rows = [
  {
    id: 1,
    BlockNumber: "C 01",
    NameShop: "ร้าน ยำยังใงให้กินได้ถึงดาวอังคาร",
    Name: "ตุลยากร ภัชรภิรมย์",
    Lastname: "นากร",
    Firstname: "นาคำ",
    BlockSize: "2.5x3m",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 526.png",
    Business: "อาหาร",
    RentalDate: "2020/01/01",
    Deposit: "12,000฿",
    Rent: "6,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: " ",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 2,
    BlockNumber: "A 02",
    Name: "ชนัย คมมาก",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NameShop: "ร้าน ยำยังใงให้กินได้ถึงดาวโลก",
    BlockSize: "3x3m",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 528.png",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/02/01",
    Deposit: "10,000฿",
    Rent: "5,000฿",
    WaterBill: "250฿",
    ElectricityBill: "2,000฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 3,
    BlockNumber: "A 03",
    NameShop: "ร้าน ยำยังใงให้กินได้ถึงดาวเสาร์",
    BlockSize: "2x2m",
    Name: "โชคชัย พาใจขวัญ",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 529.png",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/03/01",
    Deposit: "8,000฿",
    Rent: "4,000฿",
    WaterBill: "200฿",
    ElectricityBill: "1,800฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 4,
    BlockNumber: "A 04",
    NameShop: "ร้าน ยำยังใงให้กินได้ถึงดาวอังคาร",
    BlockSize: "2x2.5m",
    Name: "กุลนิดา ภูภักดี",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 530.png",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/04/01",
    Deposit: "15,000฿",
    Rent: "7,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,500฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 5,
    BlockNumber: "A 05",
    BlockSize: "3x3.5m",
    Name: "นันทิญา แสงสว่าง",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 532.png",
    NameShop: "ร้าน ยำยังใงให้กินได้ถึงดาวอังคาร",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/05/01",
    Deposit: "20,000฿",
    Rent: "8,000฿",
    WaterBill: "400฿",
    ElectricityBill: "2,800฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 6,
    BlockNumber: "A 06",
    BlockSize: "3x3.5m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 533.png",
    NameShop: "Shop F",
    Business: "อุปกรณ์",
    RentalDate: "2020/06/08",
    Deposit: "18,000฿",
    Rent: "7,500฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 7,
    BlockNumber: "A 07",
    BlockSize: "2.5x2.5m",
    Name: "จอมขวัญ แสงดารา",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 526.png",
    NameShop: "Shop G",
    Business: "อาหาร",
    RentalDate: "2020/07/01",
    Deposit: "16,000฿",
    Rent: "6,500฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 8,
    BlockNumber: "A 08",
    BlockSize: "2x2.5m",
    Name: "อัมพิกา พัฒน์ธนโกศล",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 528.png",
    NameShop: "Shop H",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/08/01",
    Deposit: "13,000฿",
    Rent: "6,000฿",
    WaterBill: "250฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 9,
    BlockNumber: "A 09",
    BlockSize: "3x3m",
    Name: "จันทรา กิตติเตชะคุณ",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 529.png",
    NameShop: "Shop I",
    Business: "เครื่องดื่ม",
    RentalDate: "2020/09/01",
    Deposit: "14,000฿",
    Rent: "7,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 10,
    BlockNumber: "A 10",
    BlockSize: "2.5x3.5m",
    Name: "นนท์ มั่นคงประสิทธิ์",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 530.png",
    NameShop: "Shop J",
    Business: "อุปกรณ์",
    RentalDate: "2020/10/01",
    Deposit: "17,000฿",
    Rent: "7,500฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 11,
    BlockNumber: "A 11",
    BlockSize: "2x2m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 526.png",
    NameShop: "Shop K",
    Business: "อาหาร",
    RentalDate: "2020/11/01",
    Deposit: "12,000฿",
    Rent: "6,000฿",
    WaterBill: "300฿",
    ElectricityBill: "2,200฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 12,
    BlockNumber: "A 12",
    BlockSize: "3x3.5m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 533.png",
    NameShop: "Shop L",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2020/12/01",
    Deposit: "16,000฿",
    Rent: "7,500฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 13,
    BlockNumber: "A 13",
    BlockSize: "2.5x2.5m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 526.png",
    NameShop: "Shop M",
    Business: "เครื่องดื่ม",
    RentalDate: "2021/01/01",
    Deposit: "14,000฿",
    Rent: "6,500฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
  {
    id: 14,
    BlockNumber: "A 14",
    BlockSize: "3x3m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 528.png",
    NameShop: "Shop N",
    Business: "อุปกรณ์",
    RentalDate: "2021/02/01",
    Deposit: "18,000฿",
    Rent: "7,000฿",
    WaterBill: "350฿",
    ElectricityBill: "2,600฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
  },
  {
    id: 15,
    BlockNumber: "A 15",
    BlockSize: "2x2.5m",
    Name: "นที กลับเพชร",
    Lastname: "นากร",
    Firstname: "นาคำ",
    NumberPhone: "088-888-8888",
    thumbnail_link: "/images/products/Rectangle 532.png",
    NameShop: "Shop O",
    Business: "เสื้อผ้าแฟร์ชั่น",
    RentalDate: "2021/03/01",
    Deposit: "15,000฿",
    Rent: "6,500฿",
    WaterBill: "300฿",
    ElectricityBill: "2,400฿",
    RentalStatus: "ยังเช่าอยู่",
    Fine: "200",
    StartDate: "2023/12/06",
    MoveOut: "2014/04/01",
    Date: "2024/04/28",
    NumberOfDays: "30 วัน",
    address:
      "34-35 หมู่บ้าน เจสแควร์ ซอย ประดิษงาน ต. ในเมือง อ. เมืองขอนแก่น จ.ขอนแก่น 40000",
    email: "aaa@gmail.com",
    idline: "088-888-8888",
    zipcode: "40000",
    district: "เมือง",
    subdistrict: "ในเมือง",
    province: "ขอนแก่น",
    note: "182/50",
    telegram: "tongsee",
    ContactPerson: "ทองดี ฟันขาว",
    PhoneContactPerson: "092999666",
    WhateApp: "NaGa",
  },
];
