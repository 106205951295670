import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { rows } from "../data/TableData";
import { Box, display } from "@mui/system";
import ProductsUpdate from "./ProductsUpdate/ProductsUpdate";
import MoveBlock from "./ProductsUpdate/MoveBlock";
import "../ProductsPage.scss";
import { getRentData } from "../../../services/rent.service";
import { getShopTenantData } from "../../../services/rent.service";

const DataGridExample = ({ searchInput }) => {
  const [open, setOpen] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [update, setUpdate] = useState(false);
  const [zones, setZones] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [rentDataData, setRentDataData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [refreshData, setRefreshData] = useState(0);

  useEffect(() => {
    getRentData().then((res) => {
      console.log(res.data);
      const rentDataRes = res.data.rent;
      setRentDataData(rentDataRes);
      setZones(res.data.zones);
    });
  }, [refreshData]);

  useEffect(() => {
    getShopTenantData().then((res) => {
      setTenantData(res.data.shop);
    });
  }, []);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleOpenUpdate = (id) => {
    setSelectedRow(id);
    setUpdate(true);
  };

  const handleOpenMoveBlock = (id) => {
    setSelectedRow(id);
    setOpenMove(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 130,     
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "BlockSize",
      headerName: "ขนาดบล็อก",
      width: 130,    
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "tenantName",
      headerName: "ชื่อผู้เช่า",
      sortable: false,
      width: 150,      
      renderCell: (params) => (
        <div style={{ paddingLeft: "0" }}>
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 140,      
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    { field: "RentalDate", headerName: "วันที่เริ่มเช่า",  width: 140 },
    { field: "NumberDays", headerName: "จำนวนวันที่เช่า",  width: 130 },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 120,      
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Deposit",
      headerName: "ค่ามัดจำ",
      width: 120,      
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 120,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "RentalStatus",
      headerName: "สถานะการเช่า",
      width: 150,     
      renderCell: (params) => (
        <div
          style={{
            color: params.row.RentalStatus == 1 ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.RentalStatus == 1 ? "ยังเช่าอยู่" : "ย้ายออกแล้ว"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.Endrent}
          </p>
        </div>
      ),
    },
    {
      field: "Update",
      headerName: "อัปเดตข้อมูล",
      width: 130,
      headerAlign: "center",
      align: "center",      
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3" }}
          className="button-h"
          onClick={() => {
            handleOpenUpdate(params.row);
          }}
        >
          อัปเดตข้อมูล
        </Button>
      ),
    },
    {
      field: "moveBlock",
      headerName: "ย้ายบล็อก",
      width: 130,
      headerAlign: "center",
      align: "center",      
      renderCell: (params) => (
        <Button
          variant="contained"
          disabled={params.row.RentalStatus === '0'}
          style={{ backgroundColor: "#6C5DD3" }}
          className="button-h"
          onClick={() => {
            handleOpenMoveBlock(params.row);
          }}
        >
          ย้ายบล็อก
        </Button>
      ),
    },
  ];

  const filteredRows = rentDataData
    .filter(
      (row) =>
        row.BlockNumber.includes(searchInput) ||
        row.tenantName.includes(searchInput)
    )
    .sort((a, b) =>
      a.BlockNumber.localeCompare(b.BlockNumber, undefined, { numeric: true })
    );

  // console.log("filteredRows", filteredRows);

  const totals = filteredRows.reduce(
    (accumulator, row) => {
      accumulator.totalDeposit += row.Deposit;
      accumulator.totalRent += row.Rent;
      accumulator.totalWaterBill += row.WaterBill;
      accumulator.totalElectricityBill += row.ElectricityBill;
      return accumulator;
    },
    {
      totalDeposit: 0,
      totalRent: 0,
      totalWaterBill: 0,
      totalElectricityBill: 0,
    }
  );

  const { totalDeposit, totalRent, totalWaterBill, totalElectricityBill } =
    totals;

  const formattedTotalDeposit = totalDeposit.toLocaleString();
  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalWaterBill = totalWaterBill.toLocaleString();
  const formattedTotalElectricityBill = totalElectricityBill.toLocaleString();

  // console.log("ผลรวมค่ามัดจำ:", formattedTotalDeposit);
  // console.log("ผลรวมค่าเช่า:", formattedTotalRent);
  // console.log("ผลรวมค่าน้ำ:", formattedTotalWaterBill);
  // console.log("ผลรวมค่าไฟ:", formattedTotalElectricityBill);

  const totalAll =
    totalDeposit + totalRent + totalWaterBill + totalElectricityBill;
  const formattedTotalAll = totalAll.toLocaleString();
  // console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  const dataTotal = [
    { p: "รวมค่ามัดจำ : ", price: formattedTotalDeposit },
    { p: "รวมค่าเช่า : ", price: formattedTotalRent },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-DataProd">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {/* แสดงผลรวมค่ามัดจำ, ค่าเช่า, ค่าน้ำ, และค่าไฟ */}
      <div className="data-boxProd">{totalData}</div>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              // height: "350px",
              width: "500px",
            }}
          />
        </div>
      </Modal>

      <Modal
        open={update}
        onClose={() => {
          setUpdate(false);
        }}
      >
        <Box
          className="width-modalP"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <ProductsUpdate
            selectedRow={selectedRow}
            tenantData={tenantData}
            setUpdate={setUpdate}
            setRefreshData={setRefreshData}
            handleCloseUpdate={handleCloseUpdate}
          />
        </Box>
      </Modal>

      <Modal
        open={openMove}
        onClose={() => {
          setOpenMove(false);
        }}
      >
        <Box
          className="width-modal-block"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "500px",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 3,
          }}
        >
          <MoveBlock
            selectedRow={selectedRow}
            tenantData={tenantData}
            setOpenMove={setOpenMove}
            setRefreshData={setRefreshData}
            handleCloseMove={handleCloseMove}
            zones={zones}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default DataGridExample;
