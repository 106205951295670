import axios from "axios";

export const svEmployeData=()=>{
    return axios.get('employee/data').then((res) => res).catch((err) => err);
};

export const svSalaryDetail=(id)=>{
    return axios.get(`employee/salary/detail/${id}`).then((res) => res).catch((err) => err);
};

export const svEmployeCreate=(formData)=>{
    return axios.post('employee/create',formData).then((res) => res).catch((err) => err);
};

export const svEmployeUpdate=(formData)=>{
    return axios.post('employee/update',formData).then((res) => res).catch((err) => err);
};

export const svSalaryUpdate=(formData)=>{
    return axios.post('employee/salary',formData).then((res) => res).catch((err) => err);
}

export const svSalaeyReset=()=>{
    return axios.get('employee/salary/reset').then((res) => res).catch((err) => err);
}

export const svEmployeDelete=(id)=>{
    return axios.delete(`employee/destroy/${id}`).then((res) => res).catch((err) => err);
};