import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { ListsData } from "./data/DataProductsaddPage";
import { Modal } from "@mui/material";
import FromAddBlock from "./FromAddBlock";
import FromAddEvidence from "./FromAddEvidence";
import FromAddStore from "./FromAddStore";
import CancelIcon from "@mui/icons-material/Cancel";
import ReceiptModal from "./ReceiptModal";
import { rows } from "./data/TableData";
import { CreateRent } from "../../../services/rent.service";
import Swal from "sweetalert2";
import "./ProductsaddPage.scss";
import {
  getZoneData,
  getBoxsZoneData,
  getBusinessType,
} from "../../../services/rent.service";
import { Box, color } from "@mui/system";
import SaveAsPDFHandler from "./PDFModal";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import { Tune } from "@mui/icons-material";

const ProductsaddPage = () => {
  const [open, setOpen] = useState(false);
  const [openshop, setOpenShop] = useState(false);
  const [openTypeColor, setTypeColor] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenTypeColor = () => {
    setTypeColor(true);
  };
  const handleCloseTypeColor = () => {
    setTypeColor(false);
  };

  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [blocksData, setBlocksData] = useState([]);
  const [overdueRent, setOverdueRent] = useState(0);
  const [note, setNote] = useState(null);
  const [NameShop, setNameShop] = useState("");
  const [Firstname, setFirstName] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [freshData, setFreshData] = useState(0);
  const [addDataBlocks, setAddDataBlocks] = useState([]);
  const [addDataEvidence, setAddDataEvidence] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [boxsZoneData, setBoxsZoneData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [businessData, setBusinessData] = useState([]);

  const date = new Date();
  const today = date.toLocaleDateString("en-GB", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const handleOpenShop = () => {
    if (!selectedShop.Firstname) {
      Swal.fire({
        title: "ไม่สามารถดูใบเสร็จรับเงินได้",
        text: "กรอกข้อมูลร้านและข้อมูลผู้เช่าก่อน",
        icon: "error",
      });
    } else if (!selectedImage) {
      Swal.fire({
        title: "ไม่สามารถดูใบเสร็จรับเงินได้",
        text: "เพิ่มหลักฐานการจ่ายก่อน!!",
        icon: "error",
      });
    } else {
      setOpenShop(true);
    }
  };

  useEffect(() => {
    getBusinessType()
      .then((res) => {
        const businessRes = res.data.business;
        setBusinessData(businessRes);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  useEffect(() => {
    getZoneData()
      .then((res) => {
        const zoneDataRes = res.data.zonename;
        setZoneData(zoneDataRes);
      })
      .catch((error) => {
        console.error("Error fetching zone data:", error);
      });
  }, []);

  useEffect(() => {
    getBoxsZoneData()
      .then((res) => {
        const boxsZoneDataRes = res.data.boxs;
        setBoxsZoneData(boxsZoneDataRes);
      })
      .catch((error) => {
        console.error("Error fetching boxs zone data:", error);
      });
  }, []);


  let zone = [];
  let box = [];
  zoneData.forEach(function (item, index) {
    box = [];
    zone[index] = { id: item.id, name: item.zone_name };
    boxsZoneData.forEach(function (data, index2) {
      if (item.id === data.idzone) {
        box[index2] = data;
      }
    });
    box = box.filter((item) => item);
    zone[index].list = box;
  });
  // console.log(zone);
  const showBoxsZone = zone;
  // console.log("showBoxsZone", showBoxsZone);

  const handleBlockClick = (id) => {
    if (selectedBlocks.includes(id)) {
      setSelectedBlocks(selectedBlocks.filter((blockId) => blockId !== id));
    } else {
      setSelectedBlocks([...selectedBlocks, id]);
    }
  };

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
    setSelectedBlocks([]);
  };

  const handleSelectAll = () => {
    const allLists = showBoxsZone.map((zone) => zone.list).flat();
    // console.log("list ทั้งหมด:", allLists);
    setBlocksData(allLists);
  };

  const SaveAsPDFHandler2 = () => {
    SaveAsPDFHandler("print", today);
  };

  const setData = () => {
    setFreshData((prev) => prev + 1);
// console.log(addDataBlocks);
    if (!addDataBlocks || addDataBlocks.length === 0) {
      Swal.fire({
        title: "กรุณาเลือกกรอกข้อมูลบล็อกให้ครบ",
        icon: "warning",
      });
      return;
    }

    let isValid = true;
    let data = ""; // Variable for storing the error message

    addDataBlocks.forEach(function (blocks) {
      if (
        !blocks.zone ||
        !blocks.box ||
        !blocks.deposit ||
        !blocks.rent ||
        !blocks.datestartrent ||
        !blocks.dateendrent ||
        blocks.tentfee < 0 ||
        !blocks.countdate
      ) {
        isValid = false;
        data = "กรุณากรอกข้อมูลบล็อกให้ครบ"; // Assign the error message to the data variable
      }
    });

    if (isValid && !selectedShop.BusinessType) {
      isValid = false;
      data = "กรุณาเลือกประเภทธุรกิจ";
    }

    if (
      isValid &&
      (!selectedShop.Firstname ||
        !selectedShop.idline ||
        !selectedShop.ContactPerson ||
        !selectedShop.Lastname ||
        !selectedShop.telegram ||
        !selectedShop.PhoneContactPerson ||
        !selectedShop.NumberPhone ||
        !selectedShop.WhateApp ||
        !selectedShop.email)
    ) {
      isValid = false;
      data = "กรุณากรอกข้อมูลผู้เช่า";
    }

    if (isValid && (!addDataEvidence.imageFile || !addDataEvidence.imageName)) {
      isValid = false;
      data = "กรุณาเพิ่มหลักฐานการชำระเงิน";
    }

    if (!isValid) {
      Swal.fire({
        title: data, // Use the data variable for the message
        icon: "warning",
      });
      return;
    }

    // Validate addDataEvidence fields
    if (!addDataEvidence.imageFile || !addDataEvidence.imageName) {
      // isValid = false;
      Swal.fire({
        title: "กรุณาแนบหลักฐานการชำระ",
        icon: "warning",
      });
      return;
    }

    // if (!isValid) {
    //   Swal.fire({
    //     title: "กรุณาเลือกกรอกข้อมูลให้ครบ",
    //     icon: "warning",
    //   });
    //   return;
    // }

    const formData = new FormData();
    // ข้อมูลบล็อก
    formData.append("block", JSON.stringify(addDataBlocks));
    addDataBlocks.forEach(function (blocks) {
      formData.append("zone", blocks.zone); //โซน
      formData.append("box", blocks.box); // บล็อค
      formData.append("boxSize", blocks.boxSize); //ขนาดบล็อค
      formData.append("deposit", blocks.deposit); // ค่ามัดจำ
      formData.append("rent", blocks.rent); //ค่าเข่า

      const dateStartRent = blocks.datestartrent
        ? blocks.datestartrent.format("DD/MM/YYYY")
        : "";
      const dateEndRent = blocks.dateendrent
        ? blocks.dateendrent.format("DD/MM/YYYY")
        : "";

      formData.append("datestartrent", dateStartRent); //วันที่เริ่มเช่า
      formData.append("dateendrent", dateEndRent); //วันที่ต้องเก็บค่าเช่า
      formData.append("tentfee", blocks.tentfee); //ค่าเต็นท์
      formData.append("avgrentperday", blocks.avgrentperday); //ค่าเช่าเฉลี่ยรายวัน
      formData.append("countdate", blocks.countdate); //จำนวนวันที่เช่า
      formData.append("amountpaid", blocks.amountpaid); // จำนวนเงินที่ต้องจ่าย
    });

    //ข้อมูลร้าน & ข้อมูลผู้เช่า
    formData.append("shopImage", selectedShop.imageFile);
    formData.append("shopImageName", selectedShop.ImageName); // รูป
    // formData.append("nameShop", selectedShop.NameShop); //ชื่อร้าน
    // formData.append("detailsBusiness", selectedShop.note); //รายละเอียดธุรกิจ
    formData.append("nameShop", selectedShop.NameShop ?? "-"); //ชื่อร้าน
    formData.append("detailsBusiness", selectedShop.note ?? "-"); //รายละเอียดธุรกิจ
    formData.append("businessType", selectedShop.BusinessType); //  id ประเภทธุรกิจ
    formData.append("firstname", selectedShop.Firstname); //ชื่อผู้เช่า
    formData.append("idline", selectedShop.idline); // ไอดี ไลน์ผู้เช่า
    formData.append("contactPerson", selectedShop.ContactPerson); //ชื่อขนามสกุล บุคคลที่ติดต่อได้
    formData.append("nickname", selectedShop.Lastname); // ชื่อเล่นผู้เช่า
    formData.append("telegram", selectedShop.telegram); //telegram ผู้เช่า
    formData.append("phoneContactPerson", selectedShop.PhoneContactPerson); // เบอร์โทรศัพท์ บุคคลที่ติดต่อได้
    formData.append("numberPhone", selectedShop.NumberPhone); //เบอร์โทรศัพท์ ผู้เช่า
    formData.append("whateApp", selectedShop.WhateApp); //whateApp ผู้เช่า
    formData.append("email", selectedShop.email); // email ผู้เช่า

    // หลักฐานการโอน
    formData.append("imageFile", addDataEvidence.imageFile);
    formData.append("imageName", addDataEvidence.imageName); // รูป
    formData.append("overdue", addDataEvidence.overdue); // ค่าคงค้าง
    formData.append("businessDetails", addDataEvidence.businessDetails); // หมายเหตุ

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    // return false;

    //เรียกใช้ API ตรงนี้ได้เลยถ้าไม่ได้ validation อะไร
    CreateRent(formData)
      .then((res) => {
        if (res.status === 201) {
          handleOpenShop();
          setTimeout(() => {
            SaveAsPDFHandler2();
            console.log("Data saved");
          }, 500);
          Swal.fire({
            title: "เพิ่มข้อมูลสำเร็จ",
            icon: "success",
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
        } else {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "เพิ่มข้อมูลให้ครบ",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "เกิดข้อผิดพลาดในการเชื่อมต่อ",
          icon: "error",
        });
      });
  };

  return (
    <div>
      <h1 style={{ fontSize: "1.75rem", fontWeight: "500" }}>
        เพิ่มข้อมูลการเช่า
      </h1>
      <div className="main-content">
        {/* main-map */}
        <div className="main-map">
          <div className="test">
            <figure className="map">
              <img
                className="img"
                src="/images/map2.png"
                alt=""
                onClick={handleOpen}
              />
            </figure>

            {/* Modal ที่แสดงรูปภาพใหญ่ */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src="/images/map3.png"
                  alt="Zoomed Shop"
                  style={{
                    maxWidth: "95vw",
                    maxHeight: "95vh",
                    width: "auto",
                    height: "auto",
                  }}
                />
                {/* เพิ่มปุ่มเพื่อปิด Modal */}
                <button
                  onClick={handleClose}
                  style={{ position: "absolute", top: 5, right: 5 }}
                >
                  <CancelIcon className="CancelIcon" />
                </button>
              </div>
            </Modal>

            {/* color */}

            <div style={{ display: "flex" }}>
              <div className="main-color">
                {businessData.slice(-3).map((data, index) => (
                  <figure key={index} className="flex-box-1">
                    <div
                      style={{
                        borderRadius: "3px",
                        height: "13px",
                        width: "13px",
                        backgroundColor: data.box_color,
                      }}
                    ></div>
                    <p>{data.types_name}</p>
                  </figure>
                ))}
                <div
                  className="btn-other"
                  style={{ cursor: "pointer", display: "flex" }}
                  onClick={handleOpenTypeColor}
                >
                  <div
                    className=""
                    style={{
                      borderRadius: "3px",
                      height: "13px",
                      width: "13px",
                      // backgroundColor: "#DCDCDC",
                      border: "1px solid",
                    }}
                  ></div>
                  <p>อื่นๆ</p>
                </div>
              </div>
            </div>
            <Modal open={openTypeColor} onClose={handleCloseTypeColor}>
              <Box
                className="width-boxM"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  // width: "700px",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  borderRadius: "10px",
                  p: 3,
                }}
              >
                <div className="allType">
                  <div className="title-box">
                    <p>ประเภทธุรกิจ</p>
                    <button
                      onClick={handleCloseTypeColor}
                      className="CancelIconPa1"
                    >
                      <CancelIcon className="CancelIconPa" />
                    </button>
                  </div>
                  <div className="colorType">
                    {businessData.map((data, index) => (
                      <figure key={index} className="flex-box-1">
                        <div
                          style={{
                            borderRadius: "3px",
                            height: "15px",
                            width: "15px",
                            backgroundColor: data.box_color,
                          }}
                        ></div>
                        <p>{data.types_name}</p>
                      </figure>
                    ))}
                  </div>
                </div>

                {/* เพิ่มปุ่มเพื่อปิด Modal */}
              </Box>
            </Modal>
          </div>
          {/* เลือก บล็อค */}
          <div className="main-block">
            <div className="zone">
              <p style={{ fontSize: "18px", fontWeight: "400" }}>แสดงโซน</p>
              <FormControl fullWidth size="small" style={{ width: "25%" }}>
                <InputLabel id="demo-select-small-label">โซน</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedZone}
                  label="โซน"
                  size="small"
                  onChange={handleZoneChange}
                >
                  <MenuItem value="" onClick={handleSelectAll}>
                    ทั้งหมด
                  </MenuItem>
                  {zoneData.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.zone_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="select-zone">
              <div className="BlockDetails">
                <p style={{ fontSize: "18px", fontWeight: "400" }}>แสดงบล็อค</p>
                <div className="Color-status">
                  <MenuItem value={10}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          borderRadius: "3px",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#fff",
                          marginRight: "6px",
                          border: "1px solid",
                        }}
                      />
                      <p style={{ fontSize: "14px", fontWeight: "400" }}>
                        ว่าง
                      </p>
                    </div>
                  </MenuItem>
                </div>
              </div>

              <div className="name-block">
                {selectedZone
                  ? showBoxsZone
                      .find((item) => item.id === selectedZone)
                      ?.list.map((data) => {
                         //console.log("data:", data); // ดูข้อมูลที่ได้รับ
                        return (
                          <div
                            key={data.id}
                            className="block"
                            style={{
                              backgroundColor:
                                data.status !== "0"
                                  ? data.box_color
                                  : "transparent",
                              color: data.status !== "0" ? "white" : "",
                            }}
                            onClick={() => handleBlockClick(data.id)}
                            title={data.types_name ? data.types_name : "ว่าง"} // ตรวจสอบว่า types_name มีค่า
                          >
                            {data.zone_name}
                            {data.zone_number}
                          </div>
                        );
                      })
                  : showBoxsZone.map((zoneData) => (
                      <React.Fragment key={zoneData.id}>
                        {zoneData.list.map((blockData) => {
                          //console.log("blockData:", blockData); // ดูข้อมูลที่ได้รับ
                          return (
                            <div
                              key={blockData.id}
                              className="block"
                              style={{
                                backgroundColor:
                                  blockData.status !== "0"
                                    ? blockData.box_color
                                    : "transparent",
                                color: blockData.status !== "0" ? "white" : "",
                              }}
                              title={
                                blockData.types_name
                                  ? blockData.types_name
                                  : "ว่าง"
                              } // ใช้ ternary operator อย่างถูกต้อง
                              onClick={() => handleBlockClick(blockData.id)}
                            >
                              {blockData.zone_name}
                              {blockData.zone_number}
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
        </div>

        {/* main-from */}
        <div className="main-from">
          {/* box-from */}
          <div className="box-from">
            <FromAddBlock
              selectedZone={selectedZone}
              setSelectedZone={setSelectedZone}
              openshop={openshop}
              setOpenShop={setOpenShop}
              selectedBlockId={selectedBlockId}
              setSelectedBlockId={setSelectedBlockId}
              overdueRent={overdueRent}
              setOverdueRent={setOverdueRent}
              note={note}
              setNote={setNote}
              setNameShop={setNameShop}
              NameShop={NameShop}
              setFirstName={setFirstName}
              Firstname={Firstname}
              selectedShop={selectedShop}
              setSelectedShop={setSelectedShop}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              setAddDataBlocks={setAddDataBlocks}
              freshData={freshData}
            />
          </div>

          {/* box-shop */}
          <FromAddStore
            selectedZone={selectedZone}
            openshop={openshop}
            setOpenShop={setOpenShop}
            selectedBlockId={selectedBlockId}
            setSelectedBlockId={setSelectedBlockId}
            overdueRent={overdueRent}
            setOverdueRent={setOverdueRent}
            note={note}
            setNote={setNote}
            setNameShop={setNameShop}
            NameShop={NameShop}
            setFirstName={setFirstName}
            Firstname={Firstname}
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            freshData={freshData}
          />

          {/* FromAddEvidence */}
          <FromAddEvidence
            openshop={openshop}
            setOpenShop={setOpenShop}
            selectedZone={selectedZone}
            overdueRent={overdueRent}
            setOverdueRent={setOverdueRent}
            note={note}
            setNote={setNote}
            NameShop={NameShop}
            setFirstName={setFirstName}
            Firstname={Firstname}
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
            setAddDataEvidence={setAddDataEvidence}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            handleOpenShop={handleOpenShop}
            setData={setData}
          />

          <div className="Button-shop">
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#707070",
                color: "white",
                height: "39px",
                width: "130px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              href="/products/add"
              // onClick={handleButtonClick}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "16px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                width: "130px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              onClick={setData}
            >
              บันทึกข้อมูล
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsaddPage;
