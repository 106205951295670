import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "./components/Table";
import PulseLoader from "react-spinners/PulseLoader";
import {
  getRentData,
  getZoneData,
  getBoxsZoneData,
  getShop,
  getTenant,
  getBoxsData,
} from "../../services/rent.service";

import { getExportDetailStore } from "../../services/report.service";

function ExportDetailsStore() {
  const { id } = useParams();
  const { t } = useTranslation(["dashboard-page"]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rentDetail, setRentDetail] = useState([]);
  const [boxsZoneData, setBoxsZoneData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rentResponse = await getExportDetailStore(id);
        const rentDataRes = rentResponse.data.detail[0];
        const rentDetailRes = rentResponse.data.detail;
        setCurrentRow(rentDataRes);
        setRentDetail(rentDetailRes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  return (
    <section id="export-details-page">
      {loading ? (
        <PulseLoader color="#3b326b" />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                gap: "2rem",
                marginBottom: "1rem",
              }}
            >
              <p
                style={{ width: "100%", fontSize: "1.75rem", fontWeight: 500 }}
              >
                รายละเอียด ประวัติเช่า/ชำระเงิน
              </p>
            </div>
          </div>

          {currentRow ? (
            <div className="box-detail">
              <div className="box-Ar">
                <p className="number">{currentRow.BlockNumber}</p>
                <p className="size">{currentRow.box_size}</p>
              </div>

              <figure className="img-boxr">
                <img
                  src={
                    currentRow.image
                      ? `${uploadPath}${currentRow.image}`
                      : "/images/no-image.png"
                  }
                  alt=""
                  className="img-storer"
                />
              </figure>

              <div className="box-Br">
                <p className="title">วันที่เริ่มเช่า</p>
                <p className="date-start">{currentRow.RentalDate}</p>
              </div>

              <div className="box-Cr">
                <p className="title">ค่ามัดจำ</p>
                <p className="deposit">
                  {new Intl.NumberFormat("th-TH", {
                    maximumFractionDigits: 0,
                  }).format(currentRow.deposit)}
                </p>
              </div>

              <div className="box-Dr">
                <p className="title">ค่าเช่า</p>
                <p className="rent">
                  {new Intl.NumberFormat("th-TH", {
                    maximumFractionDigits: 0,
                  }).format(currentRow.rent)}
                </p>
              </div>

              <div className="box-Er">
                <p className="title">ชื่อผู้เช่าร้าน</p>
                <p className="NameShop">{currentRow.name}</p>
              </div>

              <div className="box-Fr">
                <p className="title">ประเภทธุรกิจ</p>
                <div
                  className="type-business"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      borderRadius: "3px",
                      height: "16px",
                      width: "16px",
                      backgroundColor: currentRow
                        ? currentRow.color
                        : "transparent",
                      marginRight: "6px",
                    }}
                  />
                  <div className="NameShop">{currentRow.Business}</div>
                </div>
              </div>
            </div>
          ) : (
            <p>ไม่พบข้อมูลสำหรับ ID: {id}</p>
          )}
          <div className="">
            <Table 
              rentDetail={rentDetail}
            />
          </div>
        </>
      )}
    </section>
  );
}

export default ExportDetailsStore;
