import axios from "axios";

export const getDataDetail = (id) => {
    return axios.get(`reports/rental/detail/${id}`).then((res) => res).catch((error) => error);
}

export const getExportDetailStore = (id) => {
    return axios.get(`reports/exportdetails/store/${id}`).then((res) => res).catch((error) => error);
}

export const getExportDetail = (id) => {
    return axios.get(`reports/exportdetails/${id}`).then((res) => res).catch((error) => error);
}