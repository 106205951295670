import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Swal from "sweetalert2";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import withReactContent from "sweetalert2-react-content";
import { svBusinessTypeDelete } from "../../../services/setting/businesstype.service";

const modalSwal = withReactContent(Swal);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F6FF",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomPaginationActionsTable(props) {
  const {businessTypesData, handlemodalEdit,setBusinessTypesEdit, setRefreshData}=props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleEdit = (id) => {
    console.log("Edit row with id:", id);
    setBusinessTypesEdit(id)
    handlemodalEdit(true)
  };

  const handleDelete = (id) => {
    console.log("Delete row with id:", id);

    modalSwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "I want to delete this data!",
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "#e11d48",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        svBusinessTypeDelete(id).then((res) => {
          if (res.status === 200) {
            setRefreshData((prev) => prev + 1);

            SwalUI({
              status: res.data.status,
              //description: res.data.description
            });
          } else {
            SwalUI({
              status: res.data.status,
              //description: res.data.description
            });
          }
        });
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="left"
              colSpan={6}
              style={{ width: "110px" }}
            >
              ประเภทธุรกิจ
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "30px" }}>
              ลบ
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "30px" }}>
              แก้ไข
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? businessTypesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : businessTypesData
          ).map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="left" colSpan={6}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <div
                    style={{
                      borderRadius: "3px",
                      height: "14px",
                      width: "14px",
                      backgroundColor: row.box_color,
                      marginRight: "6px",
                    }}
                  />
                  <div style={{ fontWeight: 500 }}>{row.types_name}</div>
                </div>
              </StyledTableCell>

              <StyledTableCell align="right">
                {" "}
                <Button
                  variant="contained"
                  onClick={() => handleDelete(row.id)}
                  style={{ background: "#FF2C2C" }}
                >
                  <DeleteOutlineIcon />
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                {" "}
                <Button
                  variant="contained"
                  onClick={() =>handleEdit(row)}
                  style={{ background: "#6C5DD3" }}
                >
                  แก้ไข
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={businessTypesData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
