import axios from "axios";

export const svZoneData = () => {
  return axios
    .get(`zone/name`)
    .then((res) => res)
    .catch((error) => error);
};

export const svBoxsData = () => {
  return axios
    .get(`boxs/details`)
    .then((res) => res)
    .catch((error) => error);
};

export const Createzone = (formData) => {
  return axios
    .post(`zone/create`, formData)
    .then((res) => res)
    .catch((error) => error);
};

export const Createboxs = (foemData) => {
  return axios
    .post(`boxs/create`, foemData)
    .then((res) => res)
    .catch((error) => error);
};

export const updatebox = (foemData) => {
  return axios
    .post(`box/update`, foemData)
    .then((res) => res)
    .catch((error) => error);
};

export const svDeletebox = (id) => {
  return axios
    .delete(`box/destroy/${id}`)
    .then((res) => res)
    .catch((error) => error);
};
