/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormControlLabel from "@mui/material/FormControlLabel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Radio from "@mui/material/Radio";
import "./WaterDispenser.scss";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import moment from "moment";
import SwalUI from "../../components/ui/swal-ui/swal-ui";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  svWaterDispenserData,
  svWaterDispenserCreate,
  svWaterDispenserUpdate,
  svWaterDispenserDelete,
} from "../../services/waterDispenser.service";

const modalSwal = withReactContent(Swal);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F6FF",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const addDataDefault = {
  date: moment(),
  service_fees: " ",
};

function BathroomService({ data, fileName }) {
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("e");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [momentDateRepair, setMomemtDateRepair] = useState("");
  const [waterDispenserData, setWaterDispenserData] = useState([]);
  const [waterDispenserEdit, setWaterDispenserEdit] = useState({
    service_fee: "",
  });
  const [refreshData, setRefreshData] = useState(0);
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    date: false,
    service_fees: false,
    edit_service_fee: false,
  });

  console.log("waterDispenserData", waterDispenserData);

  useEffect(() => {
    svWaterDispenserData().then((res) => {
      // console.log(res);
      setWaterDispenserData(res.data.waterDispenser);
    });
  }, [refreshData]);

  const isValidDate = (date) => date !== null && dayjs(date).isValid();

  const rowsFiltered = waterDispenserData.filter((row) => {
    if (selectedValue === "e") {
      return true;
    }

    let rentalStatusFilter = false;

    if (
      selectedValue === "a" ||
      selectedValue === "b" ||
      selectedValue === "c" ||
      selectedValue === "d"
    ) {
      const startDate = dayjs(row.date, "YYYY-MM-DD");
      const endDate = dayjs(row.date, "YYYY-MM-DD");

      const dayFilter =
        selectedValue === "a" &&
        isValidDate(selectedDate1) &&
        startDate.date() === selectedDate1.date() &&
        startDate.month() === selectedDate1.month() &&
        startDate.year() === selectedDate1.year();
      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        startDate.month() === selectedDate2.month() &&
        startDate.year() === selectedDate2.year();
      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        startDate.year() === selectedDate3.year();
      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(selectedStartDate - 1).isBefore(startDate) &&
        dayjs(selectedEndDate + 1).isAfter(endDate);

      const dateFilter =
        dayFilter || monthFilter || yearFilter || dateRangeFilter;

      return dateFilter;
    }

    return false;
  });

  const handelOpen = (rowData) => {
    setOpen(true);
    setWaterDispenserEdit(rowData);
    setMomemtDateRepair(moment(rowData.date));
  };

  const handlemomentEdit = (rowdate) => {
    setMomemtDateRepair(rowdate);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    handleClose();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsFiltered.length) : 0;

  const totalServiceFee = rowsFiltered.reduce((total, row) => {
    const fee = parseInt(row.service_fee);
    return total + fee;
  }, 0);

  const formattedTotalServiceFee = totalServiceFee
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  console.log(`Total Service Fee: ${formattedTotalServiceFee}฿`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    console.log("Edit row with id:", id);
  };

  const handleDelete = (id) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "คุณต้องการลบข้อมูลนี้หรือไม่?",
        // text: "I want to delete this data!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svWaterDispenserDelete(id).then((res) => {
            if (res.status === 200) {
              setRefreshData((prev) => prev + 1);
              SwalUI({
                status: res.data.status,
                //description: res.data.description
                description: "คุณได้ลบข้อมูลแล้ว",
              });
            } else {
              SwalUI({
                status: res.data.status,
                //description: res.data.description
              });
            }
          });
        }
      });
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setAddData((prevState) => ({
      ...prevState,
      date: newDate,
    }));
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  function restructureObjectForSheet(obj) {
    const newData = [];
    obj.forEach((data) => {
      newData.push({
        date: moment(data.date).format("DD/MM/YYYY"),
        serviceFee: data.service_fee,
        created: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
      });
    });
    return newData;
  }

  const exportToExcel = () => {
    if (!Array.isArray(rowsFiltered) || rowsFiltered.length === 0) {
      console.error("Invalid data: Data should be a non-empty array.");
      return;
    }

    const newsRowsFiltered = restructureObjectForSheet(rowsFiltered);

    try {
      const worksheet = XLSX.utils.json_to_sheet(newsRowsFiltered);

      XLSX.utils.sheet_add_aoa(worksheet, [["รายได้ค่าบริการตู้กดน้ำ"]], {
        origin: `A1`,
      });
      worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["วัน/เดือน/ปี", "ค่าบริการ", "บันทึกเมื่อ"]],
        {
          origin: "A2",
        }
      );

      XLSX.utils.sheet_add_json(worksheet, newsRowsFiltered, {
        origin: "A3",
        skipHeader: true,
      });

      const lastRow = newsRowsFiltered.length + 3;
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["รวม", formattedTotalServiceFee, ""]],
        { origin: `A${lastRow}` }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `exportedData.xlsx`);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      data: addData.date.toString().trim().length < 1,
      service_fees: addData.service_fees.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("service_date", addData.date.format("YYYY-MM-DD"));
      formData.append("service_fee", addData.service_fees);

      svWaterDispenserCreate(formData).then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            //description: res.data.description
          });
          setAddData((prevState) => {
            return { ...prevState, service_fees: "", date: moment() };
          });
        }
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      edit_service_fee:
        waterDispenserEdit.service_fee.toString().trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("id", waterDispenserEdit.id);
      formData.append("update_date", momentDateRepair.format("YYYY-MM-DD"));
      formData.append("update_service_fee", waterDispenserEdit.service_fee);
      /* Display the key/value pairs*/
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // return false;
      svWaterDispenserUpdate(formData).then((res) => {
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            //description: res.data.description
          });
          handleClose();
        }
      });
    }
  };

  return (
    <section>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              รายได้ค่าบริการตู้กดน้ำ
            </p>
          </div>
        </div>

        <div className="from-warter">
          {/* บล็อก save */}
          <div className="from-save">
            <p style={{ fontWeight: 700, fontSize: "18px" }}>กรอกค่าบริการ</p>
            <div className="from-Date">
              <p
                className="hidden"
                style={{ minWidth: "29px", fontWeight: 400, fontSize: "16px" }}
              >
                วันที่
              </p>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="วันที่"
                  inputFormat="DD / MM / YYYY"
                  value={moment(addData.date)}
                  onChange={handleDateChange1}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "14px",
                  background: "#6C5DD3",
                  color: "white",
                  height: "39px",
                  paddingLeft: "1.3rem",
                  paddingRight: "1.3rem",
                }}
                className="button-h"
                onClick={addValidators}
              >
                <SaveAltIcon style={{ marginRight: "5px" }} />
                SAVE
              </Button>
            </div>
            <div className="service-fee">
              <p
                className="hidden"
                style={{ minWidth: "21%", fontWeight: 400, fontSize: "16px" }}
              >
                ค่าบริการ
              </p>
              <TextField
                id="outlined-basic"
                value={addData.service_fees}
                size="small"
                label="ค่าบริการ"
                variant="outlined"
                InputProps={{
                  inputProps: {
                    pattern: "[0-9]", // Regular expression allowing only numbers
                  },
                }}
                onInput={(event) => {
                  const { value } = event.target;
                  const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                  event.target.value = newValue; // Set the new value
                }}
                style={{ width: "100%" }}
                onChange={(e) =>
                  setAddData((prevState) => {
                    return { ...prevState, service_fees: e.target.value };
                  })
                }
              />
              <p
                className="hidden"
                style={{ width: "168px", fontWeight: 400, fontSize: "16px" }}
              >
                (บาท)
              </p>
            </div>
          </div>
          {/* บล็อกผลรวมตรงกลาง */}
          <div className="box-layout">
            <div className="box-total">
              <p style={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}>
                บริการตู้กดน้ำ
              </p>
              <p style={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}>
                ทั้งหมด
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "2rem",
                  color: "#fff",
                }}
              >
                {formattedTotalServiceFee} บาท
              </p>
            </div>
          </div>
          {/* บล็อกด้านขวา */}
          <div className="from-filter">
            <div className="button-text">
              <p style={{ fontWeight: 700, fontSize: "18px" }}>เลือก</p>
              <div className="Radio-All">
                <Radio {...controlProps("e")} />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    minWidth: "36px",
                    marginRight:"4px"
                  }}
                >
                  ทั้งหมด
                </p>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontSize: "16px",
                    background: "#6C5DD3",
                    color: "white",
                    height: "39px",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                  }}
                  className="button-h"
                  onClick={exportToExcel}
                >
                  Export to Excel
                </Button>
              </div>
            </div>
            <div className="from-Date-filter">
              <div className="date-1">
                <FormControlLabel
                  value="male"
                  control={<Radio {...controlProps("b")} />}
                  label="เดือน"
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="เดือน"
                    inputFormat="MM/YYYY"
                    views={["month", "year"]}
                    disabled={selectedValue !== "b"}
                    value={selectedDate2}
                    onChange={handleDateChange2}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-2">
                <FormControlLabel
                  className="box-year"
                  value="male"
                  control={<Radio {...controlProps("c")} />}
                  label="ปี"
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="ปี"
                    inputFormat="YYYY"
                    views={["year"]}
                    disabled={selectedValue !== "c"}
                    value={selectedDate3}
                    onChange={handleDateChange3}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* ส่วนล่าง */}
            <div className="from-Date-filter">
              <div className="date-1">
                <FormControlLabel
                  value="male"
                  control={<Radio {...controlProps("d")} />}
                  label="ตั้งแต่"
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="ตั้งแต่"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-2">
                <p
                  className="date-2-text"
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginRight: "19px",
                  }}
                >
                  จนถึง
                </p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="จนถึง"
                    inputFormat="DD/MM/YYYY"
                    disabled={selectedValue !== "d"}
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "250px" }}>
                  วันที่
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  colSpan={6}
                  style={{ width: "200px" }}
                >
                  จำนวนเงิน
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "100px" }}>
                  แก้ไข
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "100px" }}>
                  ลบ
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rowsFiltered.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rowsFiltered
              ).map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell style={{ fontWeight: "400" }}>
                    {moment(row.date).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="left" colSpan={6}>
                    {row.service_fee
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      className="edit-button"
                      onClick={() => handelOpen(row)}
                    >
                      <img src="/images/icons/mage_edit.png" alt="" />
                    </button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(row.id)}
                    >
                      <img
                        src="/images/icons/gravity-ui_trash-bin.png"
                        alt=""
                      />
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={8} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={rowsFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Modal open={open} onClose={handleClose}>
          <Box
            className="BoxWidthBS"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              p: 2,
            }}
          >
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "500",
                }}
              >
                แก้ไข รายได้ค่าบริการตู้กดน้ำ
              </h1>

              <button onClick={handleCancel}>
                <CancelIcon className="iconC" />
              </button>
            </div>

            <div
              className="from-save"
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: ".8rem",
              }}
            >
              <p style={{ fontWeight: 600, fontSize: "16px" }}>กรอกค่าบริการ</p>
              <div
                className="from-Date"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <p
                  className="hidden"
                  style={{
                    minWidth: "29px",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  วันที่
                </p>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="วันที่"
                    inputFormat="DD / MM / YYYY"
                    value={momentDateRepair}
                    onChange={handlemomentEdit}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div
                className="service-fee"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <p
                  className="hidden"
                  style={{ minWidth: "21%", fontWeight: 400, fontSize: "1rem" }}
                >
                  ค่าบริการ
                </p>
                <TextField
                  id="outlined-basic"
                  value={waterDispenserEdit.service_fee}
                  size="small"
                  label="ค่าบริการ"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setWaterDispenserEdit((prevState) => {
                      return { ...prevState, service_fee: e.target.value };
                    });
                  }}
                />
              </div>
            </div>

            <div
              className=""
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  fontSize: "16px",
                  background: "#6C5DD3",
                  color: "white",
                  height: "39px",
                  width: "100px",
                }}
                onClick={editValidators}
                className="button-h"
              >
                บันทึก
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    </section>
  );
}

export default BathroomService;
