/* eslint-disable */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {
  Button,
  TextField as MUITextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import './ProductsUpdate.scss';
import SwalUI from '../../../../components/ui/swal-ui/swal-ui';
import {
  getBoxsData,
  updateRentalData,
  updateMoveBlock,
} from '../../../../services/rent.service';

function MoveBlock(props) {
  const {
    selectedRow,
    tenantData,
    setOpenMove,
    setRefreshData,
    handleCloseMove,
    zones,
  } = props;
  const [searchInput, setSearchInput] = useState('');
  const [age, setAge] = useState('');
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [editData, setEditData] = useState(selectedRow);
  const [blocks, setBlocks] = useState([]);
  const [selectBlocks, setSelectBlocks] = useState([]);
  const [openshop, setOpenShop] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [note, setNote] = useState('');
  // const [selectedShop, setSelectedShop] = useState(null);
  console.log('tenantData', editData);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const rowsFiltered = tenantData.filter((row) => {
    if (
      (age === '' || row.zone_name === age) && // Filter by Zone
      row.tenant_name.includes(searchQuery)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const handleResultClick = (selectedShop) => {
    const shop = tenantData.find((row) => row.shopId === selectedShop);
    setSearchQuery(shop.tenant_name);
    setEditData((prevState) => {
      return {
        ...prevState,
        tenantId: shop.tenantId,
        shopId: shop.shopId,
        tenantName: shop.tenant_name,
        tenant_phone: shop.phone_th,
        tenant_nickname: shop.phone_kh,
        tenant_line: shop.tenant_line,
        tenant_mail: shop.tenant_mail,
        tenant_telegram: shop.tenant_telegram,
        tenant_whatsapp: shop.tenant_whatsapp,
      };
    });
    setOpenShop(false);
  };

  const handleOpenShop = () => {
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleChangeZone = (event) => {
    const { value } = event.target;
    console.log(value);

    console.log(value);
    setAge(event.target.value);
    getBoxsData(value).then((res) => {
      console.log(res);
      let boxs = [];
      const boxsRes = res.data.boxs;
      // Map the response data into an array of objects
      boxsRes.forEach((data) => {
        boxs.push({
          id: data.id,
          block: data.zone_name + ' ' + data.zone_number,
          blocksiz: data.box_size,
          status: data.status,
          zone_number: data.zone_number,
        });
      });
      boxs.sort((a, b) => a.zone_number - b.zone_number);
      // setBlocks((prevBlocks) =>
      //   prevBlocks.map((prevBlock, prevIndex) =>
      //     prevIndex === index
      //       ? {
      //           ...prevBlock,
      //           zone: { ...prevBlock.zone, value },
      //           block: {
      //             ...prevBlock.block,
      //             value: "",
      //             options: boxs,
      //             blocksiz: "",
      //           },
      //         }
      //       : prevBlock
      //   )
      // );
      setBlocks(boxs);
    });
  };

  const handleChangeBlock = (event) => {
    const { value } = event.target;
    // console.log(value);

    setSelectBlocks({ id: value.id, siz: value.blocksiz });
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const submitMoveData = () => {
    const RentalDate = editData.RentalDate
      ? moment(editData.RentalDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD[T]HH:mm:ss'
        )
      : '';

    const formData = new FormData();
    formData.append('id', editData.id); //rent_boxes id
    formData.append('moveZone', age); //set_zones id
    formData.append('moveblock', selectBlocks.id); //set_boxes id
    formData.append('blocksize', selectBlocks.siz); //rent_boxes id
    formData.append('rentalDate', RentalDate);
    formData.append('note', note); //หมายเหตุ

    /* Display the key/value pairs*/
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // return false;

    updateMoveBlock(formData).then((res) => {
      console.log(res);
      if (res.status === 200) {
        SwalUI({
          status: res.data.status,
          description: res.data.description,
        });
      }
      if (res.status === 201) {
        setRefreshData((prev) => prev + 1);
        SwalUI({
          status: res.data.status,
          // description: res.data.description
        });
        setOpenMove(false);
      }
    });

    //post
  };

  // const handleChangeZone = (event, index) => {
  //   const { value } = event.target;

  //   getBoxsData(event.target.value).then((res) => {
  //     // console.log(res);
  //     let boxs = [];
  //     const boxsRes = res.data.boxs;
  //     // Map the response data into an array of objects
  //     boxsRes.forEach((data) => {
  //       boxs.push({
  //         id: data.id,
  //         block: data.zone_name + ' ' + data.zone_number,
  //         blocksiz: data.box_size,
  //         status: data.status,
  //         zone_number: data.zone_number,
  //       });
  //     });

  //     boxs.sort((a, b) => a.zone_number - b.zone_number);
  //     setBlocks((prevBlocks) =>
  //       prevBlocks.map((prevBlock, prevIndex) =>
  //         prevIndex === index
  //           ? {
  //               ...prevBlock,
  //               zone: { ...prevBlock.zone, value },
  //               block: {
  //                 ...prevBlock.block,
  //                 value: '',
  //                 options: boxs,
  //                 blocksiz: '',
  //               },
  //             }
  //           : prevBlock
  //       )
  //     );
  //   });
  // };

  return (
    <section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          gap: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        <div style={{ width: '100%', fontSize: '1.75rem', fontWeight: 600 }}>
          อัปเดต ย้ายบล็อก {editData.BlockNumber}
        </div>
      </div>

      <div className="box-store-data">
        <div className="store-information">
          <div style={{ width: '100%', fontSize: '1.5rem', fontWeight: 400 }}>
            เลือกบล็อกใหม่
          </div>
        </div>
        {/* กรอกข้อมูล */}
        <div className="">
          <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">โซน</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={age}
              onChange={(event) => handleChangeZone(event)}
              label="Age"
            >
              {zones.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.id}>
                  {option.zone_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">
              บล็อก
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectBlocks.block}
              onChange={(event) => handleChangeBlock(event)}
              label="Block"
            >
              {blocks.map((option, optionIndex) => (
                <MenuItem
                  key={optionIndex}
                  value={option}
                  disabled={option.status === '1'}
                  style={{
                    backgroundColor: option.status === '1' ? 'gray' : 'white',
                  }}
                >
                  {option.block}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <MUITextField
                id="standard-helperText"
                // label="ขนาดบล็อก"
                value={selectBlocks.siz}
                variant="standard"
                style={{ backgroundColor: '#F3F4F6', borderRadius: '3px' }}
                disabled
                onChange={(event) =>
                  setSelectBlocks((prevState) => {
                    return { ...prevState, siz: event.target.value };
                  })
                }
              />
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันที่เริ่มเช่า"
                inputFormat="DD/MM/YYYY"
                value={moment(editData.RentalDate, 'DD/MM/YYYY')}
                onChange={(date) => {
                  setEditData((prevState) => {
                    return {
                      ...prevState,
                      RentalDate: date.format('DD/MM/YYYY'),
                    };
                  });
                }}
                renderInput={(params) => (
                  <MUITextField
                    id="standard-helperText"
                    variant="standard"
                    {...params}
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <textarea
            aria-label="empty textarea"
            placeholder="หมายเหตุ"
            value={note}
            onChange={(event) => setNote(event.target.value)}
            style={{
              border: '1px solid #808080',
              borderRadius: '5px',
              padding: '8px',
              width: '100%',
              height: '80px',
              resize: 'vertical',
            }}
          />
        </div>
      </div>

      <div className="status-button">
        <Button
          variant="contained"
          size="small"
          className="button-h"
          style={{
            fontSize: '16px',
            background: '#707070',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
          onClick={() => {
            handleCloseMove();
          }}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{
            fontSize: '16px',
            background: '#6C5DD3',
            color: 'white',
            height: '39px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
          component="a"
          className="button-h"
          onClick={submitMoveData}
        >
          บันทึก
        </Button>
      </div>
    </section>
  );
}
export default MoveBlock;
