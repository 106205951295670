import React, { useEffect, useState } from "react";
import { Modal, Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./FormAddmodal.scss";
import moment from "moment";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svCreateElectricityBill } from "../../../services/expenses/electricitybill.service";

function FormAddModal(props) {
  const { openshop, handleCloseShop, maxMumbeUnit, setRefreshData } = props;
  const [selectedStartDate, setSelectedStartDate] = useState(moment());

  const addDataDefault = {
    uses_quantity: "",
    numbeunit_old: maxMumbeUnit.toString(),
    numbeunit_new: "",
    price_unit: "",
  };
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    uses_quantity: false,
    numbeunit_old: false,
    numbeunit_new: false,
    price_unit: false,
  });

  useEffect(() => {
    let quantityUses = addData.numbeunit_new - addData.numbeunit_old;
    setAddData((prevState) => ({
      ...prevState,
      uses_quantity: quantityUses,
    }));
  }, [addData.numbeunit_new, addData.numbeunit_old]);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleCancel = () => {
    handleCloseShop();
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      uses_quantity: addData.uses_quantity < 1,
      numbeunit_old: addData.numbeunit_old.trim().length < 1,
      numbeunit_new: addData.numbeunit_new.trim().length < 1,
      price_unit: addData.price_unit.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      // console.log("123");
      const formData = new FormData();
      formData.append("date", selectedStartDate.format("DD/MM/YYYY"));
      formData.append("quantityUsed", addData.uses_quantity);
      formData.append("previousNumber", addData.numbeunit_old);
      formData.append("lastNumber", addData.numbeunit_new);
      formData.append("totalExpenses", addData.price_unit);
      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      svCreateElectricityBill(formData).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            // //description: res.data.description
          });
          handleCloseShop();
        }
      });
    }
  };

  return (
    <Modal open={openshop} onClose={handleCloseShop}>
      <Box
        className="width-modal"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 4,
        }}
      >
        <div className="box-modal-add">
          <div className="justifu-CancelIcon">
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              ข้อมูลค่าไฟ
            </p>
            <button onClick={handleCancel}>
              <CancelIcon className="CancelIcon" />
            </button>
          </div>

          <div className="main-modal">
            <div className="box-modal-1">
              <div className="box-maintenance">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  วันที่เรียกเก็บ
                </p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // label="ปี"
                    inputFormat="DD / MM / YYYY"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  เลขครั้งก่อน
                </p>
                {/* <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.numbeunit_old}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.numbeunit_old}

                /> */}
                <TextField
                  id="outlined-basic"
                  value={addData.numbeunit_old}
                  size="small"
                  // label="หัวข้อ"
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.numbeunit_old}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, numbeunit_old: e.target.value };
                    })
                  }
                />
              </div>
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  ยอดค่าใช้จ่าย
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.price_unit}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]", // Regular expression allowing only numbers
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                    event.target.value = newValue; // Set the new value
                  }}
                  error={isError.price_unit}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, price_unit: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="box-modal-2">
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  จำนวนที่ใช้
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.uses_quantity < 0 ? 0 : addData.uses_quantity}
                  variant="outlined"
                  disabled
                  style={{ width: "100%", backgroundColor: "#f3f4f6" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  เลขครั้งหลัง
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.numbeunit_new}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]",
                    },
                  }}
                  onInput={(event) => {
                    const { value } = event.target;
                    const newValue = value.replace(/[^0-9]/g, "");
                    event.target.value = newValue;
                  }}
                  error={isError.numbeunit_new}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, numbeunit_new: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Button-save">
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "14px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            className="button-h"
            onClick={addValidators}
          >
            บันทึก
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default FormAddModal;
