import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./WaterincomePage.scss";
import RadioGroup from "@mui/material/RadioGroup";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { getElectricIncome } from "../../services/overalldisplay/billingelectricitybill.service";

const ExpirationPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [Status, setStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("e");
  const [historyData, setHistoryData] = useState([]);

  const isValidDate = (date) => date !== null && dayjs(date).isValid();

  useEffect(() => {
    getElectricIncome().then((res) => {
      console.log("kk", res.data?.data);
      setHistoryData(res.data?.data || []);
    });
  }, []);

  console.log("Data", historyData);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    setSelectedValue("tambon3");
  };
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "บล็อกที่อยู่",
      sortable: false,
      width: 170,
      flex: 1,
    },
    {
      field: "name",
      headerName: "ชื่อผู้เช่าร้าน",
      sortable: false,
      width: isHovered ? 200 : 150,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: "nickname",
      headerName: "เบอร์ติดต่อ(TH)",
      sortable: false,
      width: 170,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "เบอร์ติดต่อ(KH)",
      sortable: false,
      width: 170,
      flex: 1,
    },
    {
      field: "RentalDate",
      headerName: "วันที่เริ่มเช่า",
      sortable: false,
      width: 170,
      flex: 1,
    },
    {
      field: "pay_electric_Date",
      headerName: "วันที่จ่ายเงินล่าสุด",
      sortable: false,
      width: 170,
      flex: 1,
    },
    {
      field: "cost_per_day",
      headerName: "ค่าไฟ",
      width: 170,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "additional",
      headerName: "ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม",
      width: 170,
      flex: 1,
    },
    {
      field: "total_expenses",
      headerName: "รวมรายได้ค่าไฟ",
      width: 170,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "rent_status",
      headerName: "สถานะการเช่า",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.rent_status === "1" ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.rent_status === "1" ? "ยังเช่าอยู่" : "ย้ายออก"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.updated}
          </p>
        </div>
      ),
    },
  ];

  const rowsFiltered = historyData.filter((row) => {
    if (selectedValue === "e") {
      return true;
    }

    let rentalStatusFilter = false;

    if (selectedValue === "tambon1" && row.rent_status === "1") {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon2" && row.rent_status === "0") {
      rentalStatusFilter = true;
    } else if (selectedValue === "tambon3") {
      rentalStatusFilter = true;
    }

    if (
      selectedValue === "a" ||
      selectedValue === "b" ||
      selectedValue === "c" ||
      selectedValue === "d"
    ) {
      const startDate = dayjs(row.pay_electric_Date, "DD/MM/YYYY");
      const endDate = dayjs(row.pay_electric_Date, "DD/MM/YYYY");

      const dayFilter =
        selectedValue === "a" &&
        isValidDate(selectedDate1) &&
        startDate.date() === selectedDate1.date() &&
        startDate.month() === selectedDate1.month() &&
        startDate.year() === selectedDate1.year();
      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        startDate.month() === selectedDate2.month() &&
        startDate.year() === selectedDate2.year();
      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        startDate.year() === selectedDate3.year();
      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(selectedStartDate - 1).isBefore(startDate) &&
        dayjs(selectedEndDate + 1).isAfter(endDate);

      const dateFilter =
        dayFilter || monthFilter || yearFilter || dateRangeFilter;

      return dateFilter;
    }

    if (
      (age === "" || row.Zone === age) &&
      (row.BlockNumber.includes(searchInput) ||
        row.name.includes(searchInput)) &&
      rentalStatusFilter
    ) {
      return true;
    } else {
      return false;
    }
  });

  console.log("historyData", rowsFiltered);

  const status = Array.from(new Set(rows.map((row) => row.rent_status)));
  const statusOptions = status.map((status, index) => (
    <MenuItem key={index} value={status}>
      {status}
    </MenuItem>
  ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalCost_per_day += row.cost_per_day;
      accumulator.totalAdditional += row.additional;
      accumulator.total_expenses += row.total_expenses;
      return accumulator;
    },
    {
      totalCost_per_day: 0,
      totalAdditional: 0,
      total_expenses: 0,
    }
  );

  const { totalCost_per_day, totalAdditional, total_expenses } = totals;

  const formattedTotalCost_per_day = totalCost_per_day.toLocaleString();
  const formattedTtotalAdditional = totalAdditional.toLocaleString();
  const formattedTotal_expenses = total_expenses.toLocaleString();

  // console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  const dataTotal = [
    { p: "ค่าไฟ : ", price: formattedTotalCost_per_day },
    { p: "ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม : ", price: formattedTtotalAdditional },
    { p: "รวมรายได้ค่าไฟ : ", price: formattedTotal_expenses },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  const exportToExcel = () => {
    const headers = {
      บล็อกที่อยู่: "BlockNumber",
      ชื่อผู้เช่าร้าน: "name",
      "เบอร์ติดต่อ(TH)": "phone",
      "เบอร์ติดต่อ(KH)": "nickname",
      วันที่เริ่มเช่า: "RentalDate",
      วันที่จ่ายล่าสุด: "pay_electric_Date",
      ค่าไฟ: "cost_per_day",
      ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม: "additional",
      รวมรายได้ค่าไฟ: "total_expenses",
    };

    // สร้างข้อมูลที่กรองแล้ว
    const mappedRows = rowsFiltered.map((row) => {
      const mappedRow = {};
      for (const key in headers) {
        mappedRow[key] = row[headers[key]];
      }
      return mappedRow;
    });

    // สร้างแผ่นงานใหม่
    const worksheet = XLSX.utils.json_to_sheet([]);

    // เพิ่มหัวข้อ "รายงานรายได้ค่าเช่า ค่าปรับ" และผสานเซลล์
    XLSX.utils.sheet_add_aoa(worksheet, [["รายงานรายได้ค่าเช่า ค่าปรับ"]], {
      origin: "A1",
    });
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }];

    // เพิ่มชื่อคอลัมน์ในแถวที่สอง
    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(headers)], {
      origin: "A2",
    });

    // เพิ่มข้อมูลในแถวที่สาม
    XLSX.utils.sheet_add_json(worksheet, mappedRows, {
      origin: "A3",
      skipHeader: true,
    });

    // ข้อมูลผลรวม
    const dataTotal = [
      { p: "" },
      { p: "" },
      { p: "ค่าไฟ : ", price: formattedTotalCost_per_day },
      { p: "ค่าเครื่องใช้ไฟฟ้าเพิ่มเติม : ", price: formattedTtotalAdditional },
      { p: "รวมรายได้ค่าไฟ : ", price: formattedTotal_expenses },
    ];

    // เพิ่มข้อมูลผลรวมในแถวสุดท้าย
    const dataTotalRows = dataTotal.map((item) => [item.p, item.price]);
    XLSX.utils.sheet_add_aoa(worksheet, dataTotalRows, {
      origin: "A" + (mappedRows.length + 4),
    });

    // สร้าง Workbook และเพิ่ม Worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");

    // แปลง Workbook เป็น ArrayBuffer และ Blob
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    // บันทึกไฟล์ Excel
    saveAs(data, "รายงานรายได้ค่าไฟ.xlsx");
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          รายงานรายได้ค่าไฟ
        </div>
        <div className="search">
          <TextField
            label="ค้นหา บล็อก,ร้าน"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
        </div>
      </div>

      <div className="from-rental">
        {/* บล็อกด้านขวา */}
        <div className="from-filter1">
          <div className="button-text">
            <p style={{ fontWeight: 500, fontSize: "18px" }}>เลือก</p>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "14px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
                // marginTop: "2rem",
              }}
              className="button-h"
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </div>

          <div className="from-Date-filter-Ele">
            {/* <div className="date-1">
              <Radio {...controlProps("a")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                วัน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วัน"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "a"}
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  views={["day", "month", "year"]}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div> */}
            <div className="date-1">
              <Radio {...controlProps("b")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                เดือน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="เดือน"
                  inputFormat="MM/YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "b"}
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-2">
              <Radio {...controlProps("c")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ปี
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ปี"
                  inputFormat="YYYY"
                  views={["year"]}
                  disabled={selectedValue !== "c"}
                  value={selectedDate3}
                  onChange={handleDateChange3}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-3">
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel id="select-label-tambon">สถานะการเช่า</InputLabel>
                <Select
                  labelId="select-label-tambon"
                  id="select-tambon"
                  label="สถานะการจ่าย"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <MenuItem value="tambon1">เช่าอยู่</MenuItem>
                  <MenuItem value="tambon2">ย้ายออก</MenuItem>
                  <MenuItem value="tambon3">ทั้งหมด</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="date-4">
              <Radio {...controlProps("d")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ตั้งแต่
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ตั้งแต่"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "d"}
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-5">
              <p
                className="date-2-text"
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  // marginRight: "15px",
                }}
              >
                จนถึง
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="จนถึง"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "d"}
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-6">
              <Radio {...controlProps("e")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="data-box4">{totalData}</div>

      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExpirationPage;
