/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "@mui/base";
import "./Graphs.scss";
import { dataset } from "./data/TableData";
import { BarChart } from "@mui/x-charts/BarChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { svDataDays, svDataMonth } from "../../services/graph";
import { Dashboard } from "@mui/icons-material";
import axios from "axios";

function Graphs() {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);

  const [selectedStartDateM, setSelectedStartDateM] = useState(null);
  const [selectedEndDateM, setSelectedEndDateM] = useState(null);
  const [selectedDate2M, setSelectedDate2M] = useState(null);
  const [selectedDate3M, setSelectedDate3M] = useState(null);

  const [selectedValue, setSelectedValue] = useState(" ");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chartWidth, setChartWidth] = useState();
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  //ดึงข้อมูลมาแสดงแบบเดือน
  useEffect(() => {
    svDataMonth().then((res) => {
      const data = res.data?.data || [];
      console.log("API Response Data:", data);

      const formatDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split("/").map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;
        const formattedYear = date.getFullYear();
        const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
        return `${formattedYear}-${formattedMonth}`;
      };

      const expensesMap = {};

      const addExpense = (key, amount) => {
        if (!expensesMap[key]) {
          expensesMap[key] = {
            salary: 0,
            repair: 0,
            garbage: 0,
            electricity: 0,
            cleaning: 0,
            miscellaneous: 0,
            fines: 0,
            overdue: 0,
          };
        }
        expensesMap[key].salary += amount.salary || 0;
        expensesMap[key].repair += amount.repair || 0;
        expensesMap[key].garbage += amount.garbage || 0;
        expensesMap[key].electricity += amount.electricity || 0;
        expensesMap[key].cleaning += amount.cleaning || 0;
        expensesMap[key].miscellaneous += amount.miscellaneous || 0;
        expensesMap[key].fines += amount.fines || 0;
        expensesMap[key].overdue += amount.overdue || 0;
      };

      (data.expenses || []).forEach((item) => {
        const key = formatDate(item.payment_salary_date);
        if (key) addExpense(key, { salary: item.salary });
      });

      (data.repair || []).forEach((item) => {
        const key = formatDate(item.date_repair);
        if (key) addExpense(key, { repair: item.repair_expenses });
      });

      (data.garbages || []).forEach((item) => {
        const key = formatDate(item.date_garbages);
        if (key) addExpense(key, { garbage: item.garbage_expenses });
      });

      (data.electricity || []).forEach((item) => {
        const key = formatDate(item.date_electricity);
        if (key) addExpense(key, { electricity: item.electricity_expenses });
      });

      (data.cleaning || []).forEach((item) => {
        const key = formatDate(item.date_cleaning);
        if (key) addExpense(key, { cleaning: item.cleaning_expenses });
      });

      (data.miscellaneous || []).forEach((item) => {
        const key = formatDate(item.date_miscellaneous);
        if (key)
          addExpense(key, { miscellaneous: item.miscellaneous_expenses });
      });

      (data.expiration || []).forEach((item) => {
        const key = formatDate(item.date_fines);
        if (key)
          addExpense(key, {
            fines: item.fines_expenses,
            overdue: item.overdue_expenses,
          });
      });

      const rowsData = Object.keys(expensesMap).map((key) => {
        const {
          salary,
          repair,
          garbage,
          electricity,
          cleaning,
          miscellaneous,
          fines,
          overdue,
        } = expensesMap[key];
        const total =
          salary + repair + garbage + electricity + cleaning + miscellaneous;
        return {
          monthYear: key,
          total,
          overdue,
          fines,
        };
      });

      // แสดงข้อมูลทั้งหมดเมื่อ selectedValue ไม่มีการกรอง
      const filteredRows =
        selectedValue === "all" || !selectedValue
          ? rowsData
          : rowsData.filter((row) => {
              if (selectedValue === "bb" && selectedDate2M) {
                // Filter by specific month
                return row.monthYear === selectedDate2M.format("YYYY-MM");
              } else if (selectedValue === "cc" && selectedDate3M) {
                // Filter by specific year
                return row.monthYear.startsWith(selectedDate3M.format("YYYY"));
              } else if (
                selectedValue === "dd" &&
                selectedStartDateM &&
                selectedEndDateM
              ) {
                // Filter by date range
                const startDate = new Date(
                  selectedStartDateM.format("YYYY-MM")
                );
                const endDate = new Date(selectedEndDateM.format("YYYY-MM"));
                const rowDate = new Date(row.monthYear);
                return rowDate >= startDate && rowDate <= endDate;
              }
              return true; // Show all data if none of the above conditions are met
            });

      filteredRows.sort((a, b) => {
        const [yearA, monthA] = a.monthYear.split("-").map(Number);
        const [yearB, monthB] = b.monthYear.split("-").map(Number);
        return yearB - yearA || monthB - monthA;
      });

      setRows(filteredRows.slice(0, 12));
    });
  }, [
    selectedValue,
    selectedDate2M,
    selectedDate3M,
    selectedStartDateM,
    selectedEndDateM,
  ]);

  //ดึงข้อมูลมาแสดงแบบวัน
  useEffect(() => {
    svDataDays().then((res) => {
      const data = res.data?.data;
      console.log("API Response Data Days:", data);

      const formatDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split("/").map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;
        const formattedDay = String(date.getDate()).padStart(2, "0");
        const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
        const formattedYear = date.getFullYear();
        return `${formattedDay}-${formattedMonth}-${formattedYear}`;
      };

      const expensesMap = {};

      const addExpense = (key, amount) => {
        if (!expensesMap[key]) {
          expensesMap[key] = {
            rent: 0,
            tentfee: 0,
            electric: 0,
            water: 0,
          };
        }
        expensesMap[key].rent += amount.rent || 0;
        expensesMap[key].tentfee += amount.tentfee || 0;
        expensesMap[key].electric += amount.electric || 0;
        expensesMap[key].water += amount.water || 0;
      };

      (data.rent_tent || []).forEach((item) => {
        const key = formatDate(item.date_rent);
        if (key)
          addExpense(key, {
            rent: item.rent,
            tentfee: item.tentfee,
          });
      });

      (data.electric || []).forEach((item) => {
        const key = formatDate(item.date_electric);
        if (key) addExpense(key, { electric: item.electric });
      });

      (data.water || []).forEach((item) => {
        const key = formatDate(item.date_water);
        if (key) addExpense(key, { water: item.water });
      });

      const rowsData = Object.keys(expensesMap).map((key) => {
        const { rent, tentfee, electric, water } = expensesMap[key];
        const total = rent + tentfee + electric + water;
        return {
          dMy: key,
          rent,
          tentfee,
          electric,
          water,
        };
      });

      const filteredRows =
        selectedValue === "all" || !selectedValue
          ? rowsData
          : rowsData.filter((row) => {
              const [day, month, year] = row.dMy.split("-");
              const rowDate = new Date(year, month - 1, day);

              if (selectedValue === "e") {
                return rowsData;
              } else if (selectedValue === "a" && selectedDate1) {
                return row.dMy === selectedDate1.format("DD-MM-YYYY");
              } else if (selectedValue === "b" && selectedDate2) {
                return row.dMy.slice(3) === selectedDate2.format("MM-YYYY");
              } else if (selectedValue === "c" && selectedDate3) {
                return row.dMy.endsWith(selectedDate3.format("YYYY"));
              } else if (
                selectedValue === "d" &&
                selectedStartDate &&
                selectedEndDate
              ) {
                const startDate = new Date(
                  selectedStartDate.format("YYYY-MM-DD")
                );
                const endDate = new Date(selectedEndDate.format("YYYY-MM-DD"));
                return rowDate >= startDate && rowDate <= endDate;
              }
              return true;
            });

      filteredRows.sort((a, b) => {
        const [dayA, monthA, yearA] = a.dMy.split("-").map(Number);
        const [dayB, monthB, yearB] = b.dMy.split("-").map(Number);
        return (
          new Date(yearB, monthB - 1, dayB) - new Date(yearA, monthA - 1, dayA)
        );
      });

      setFilteredData(filteredRows.slice(0, 31));
    });
  }, [
    selectedValue,
    selectedDate1,
    selectedDate2,
    selectedDate3,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const chartSetting = {
    width: chartWidth,
    height: 350,
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setChartWidth(
        window.innerWidth >= 1600
          ? 1300
          : window.innerWidth >= 1025
          ? 1500
          : 3000
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const valueFormatter = (value) => {
    return value.toLocaleString();
  };

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  const handleDateChange2M = (newDate) => {
    setSelectedDate2M(newDate);
  };

  const handleDateChange3M = (newDate) => {
    setSelectedDate3M(newDate);
  };

  const handleStartDateChangeM = (newDate) => {
    setSelectedStartDateM(newDate);
  };

  const handleEndDateChangeM = (newDate) => {
    setSelectedEndDateM(newDate);
  };

  const totals = filteredData.reduce(
    (accumulator, row) => {
      accumulator.totalRent += row.rent || 0;
      accumulator.totaltentDeposi += row.tentfee || 0;
      accumulator.totalWater += row.water || 0;
      accumulator.totalElectricity += row.electric || 0;
      return accumulator;
    },
    {
      totalRent: 0,
      totalWater: 0,
      totalElectricity: 0,
      totaltentDeposi: 0,
    }
  );

  const totalsMonth = rows.reduce(
    (accumulator, row) => {
      accumulator.totalFine += row.fines || 0;
      accumulator.totalExpensesPerMonth += row.total || 0;
      accumulator.totalOverdue += row.overdue || 0;
      return accumulator;
    },
    {
      totalFine: 0,
      totalExpensesPerMonth: 0,
      totalOverdue: 0,
    }
  );

  const { totalRent, totalWater, totalElectricity, totaltentDeposi } = totals;
  const { totalFine, totalExpensesPerMonth, totalOverdue } = totalsMonth;

  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalWater = totalWater.toLocaleString();
  const formattedTotalElectricity = totalElectricity.toLocaleString();
  const formattedTotalFine = totalFine.toLocaleString();
  const formattedTotalExpensesPerMonth = totalExpensesPerMonth.toLocaleString();
  const formattedTotalOverdue = totalOverdue.toLocaleString();
  const formattedTotalTentDeposi = totaltentDeposi.toLocaleString();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",

          marginBottom: "1.5rem",
        }}
      >
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>ข้อมูลรายได้</div>
      </div>
      {/* ข้อมูลรายได้ */}
      <div className="income1" style={{ marginBottom: "1.5rem" }}>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "500" }}>
            รวมข้อมูลรายได้ทั้งหมด
          </p>
        </div>

        <div className="income-rent">
          <div className="rent">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>ค่าเช่า :</h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalRent} ฿
            </p>
          </div>
          <div className="tent-deposi">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>
              ค่ามัดจำเต็นท์ :
            </h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalTentDeposi} ฿
            </p>
          </div>
          <div className="water">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>
              ค่าตู้กดน้ำ :
            </h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalWater} ฿
            </p>
          </div>
          <div className="month">
            <div className="fiElectricityne">
              <h1 style={{ fontSize: "16px", fontWeight: "400" }}>ค่าไฟ :</h1>
              <p style={{ fontSize: "20px", fontWeight: "700" }}>
                {formattedTotalElectricity} ฿
              </p>
            </div>
          </div>
          <div className="Fine">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>ค่าปรับ :</h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalFine} ฿
            </p>
          </div>
          <div className="ExpensesPerMonth">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>
              ค่าใช้จ่ายรายเดือน :
            </h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalExpensesPerMonth} ฿
            </p>
          </div>
          <div className="Overdue">
            <h1 style={{ fontSize: "16px", fontWeight: "400" }}>
              ค่าเช่าค้างจ่าย :
            </h1>
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {formattedTotalOverdue} ฿
            </p>
          </div>
        </div>
      </div>

      <div className="day" style={{ marginBottom: "1.5rem" }}>
        <div className="search-box">
          <p style={{ fontSize: "18px", fontWeight: "500" }}>เลือก</p>

          <div className="from-Date-filter1">
            <div className="date-1">
              <Radio {...controlProps("a")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                วัน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วัน"
                  inputFormat="DD-MM-YYYY"
                  disabled={selectedValue !== "a"}
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  views={["day", "month", "year"]}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-2">
              <Radio {...controlProps("b")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                เดือน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="เดือน"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "b"}
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-3">
              <Radio {...controlProps("c")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ปี
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ปี"
                  inputFormat="YYYY"
                  views={["year"]}
                  disabled={selectedValue !== "c"}
                  value={selectedDate3}
                  onChange={handleDateChange3}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-5">
              <Radio {...controlProps("d")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ตั้งแต่
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ตั้งแต่"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "d"}
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-6">
              <p
                className="date-2-text"
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginRight: "19px",
                }}
              >
                จนถึง
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="จนถึง"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "d"}
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-7" onClick={() => setSelectedValue("e")}>
              <Radio {...controlProps("e")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ทั้งหมด
              </p>
            </div>
          </div>
        </div>

        {/* 31 วันย้อนหลัง */}
        <div className="main-graphs1">
          {/* กราฟ */}
          <div className="graphs-total">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "18px", fontWeight: "500" }}>
                ข้อมูลรายได้ รวม
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#707070",
                }}
              >
                31 วันย้อนหลัง
              </p>
            </div>

            <div className="barchart">
              <div style={{ overflow: "auto", width: "100%" }}>
                {filteredData.length > 0 ? (
                  <BarChart
                    dataset={filteredData}
                    xAxis={[{ scaleType: "band", dataKey: "dMy" }]}
                    series={[
                      { dataKey: "rent", label: "ค่าเช่า", color: "#6C5DD3" },
                      {
                        dataKey: "tentfee",
                        label: "ค่ามัดจำเต็นท์",
                        color: "#e695d4",
                      },
                      {
                        dataKey: "water",
                        label: "ค่าตู้กดน้ำ",
                        color: "#A0D7E8",
                      },
                      {
                        dataKey: "electric",
                        label: "ค่าไฟ",
                        color: "#FFCE72",
                      },
                    ]}
                    valueFormatter={valueFormatter}
                    {...chartSetting}
                  />
                ) : (
                  <p>ไม่มีข้อมูล...</p>
                )}
              </div>

              <div className="main-color">
                <figure className="flex-box-1">
                  <div className="color-1"></div>
                  <p>ค่าเช่า</p>
                </figure>
                <figure className="flex-box-7">
                  <div className="color-7"></div>
                  <p>ค่ามัดจำเต็นท์</p>
                </figure>
                <figure className="flex-box-2">
                  <div className="color-2"></div>
                  <p>ค่าตู้กดน้ำ</p>
                </figure>
                <figure className="flex-box-3">
                  <div className="color-3"></div>
                  <p>ค่าไฟ</p>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="month">
        <div className="search-box">
          <p style={{ fontSize: "18px", fontWeight: "500" }}>เลือก</p>

          <div className="from-Date-filter2">
            <div className="date-2">
              <Radio {...controlProps("bb")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                เดือน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="เดือน"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "bb"}
                  value={selectedDate2M}
                  onChange={handleDateChange2M}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-3">
              <Radio {...controlProps("cc")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ปี
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ปี"
                  inputFormat="YYYY"
                  views={["year"]}
                  disabled={selectedValue !== "cc"}
                  value={selectedDate3M}
                  onChange={handleDateChange3M}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-5">
              <Radio {...controlProps("dd")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ตั้งแต่
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ตั้งแต่"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "dd"}
                  value={selectedStartDateM}
                  onChange={handleStartDateChangeM}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-6">
              <p
                className="date-2-text"
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginRight: "19px",
                }}
              >
                จนถึง
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="จนถึง"
                  inputFormat="MM-YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "dd"}
                  value={selectedEndDateM}
                  onChange={handleEndDateChangeM}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        {/* รายเดือน */}
        <div className="main-graphs1">
          {/* กราฟ */}
          <div className="graphs-total">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "18px", fontWeight: "500" }}>
                ข้อมูลรายได้ รวม
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#707070",
                }}
              >
                12 เดือนย้อนหลัง
              </p>
            </div>

            <div className="barchart">
              <div style={{ overflow: "auto", width: "100%" }}>
                {rows.length > 0 ? (
                  <BarChart
                    dataset={rows} // ใช้ rows ตรงนี้
                    xAxis={[{ scaleType: "band", dataKey: "monthYear" }]}
                    series={[
                      { dataKey: "fines", label: "ค่าปรับ", color: "#898088" },
                      {
                        dataKey: "total",
                        label: "ค่าใช้จ่ายรายเดือน",
                        color: "#6edcff",
                      },
                      {
                        dataKey: "overdue",
                        label: "ค่าเช่าค้างจ่าย",
                        color: "#FF8D8D",
                      },
                    ]}
                    valueFormatter={valueFormatter}
                    {...chartSetting}
                  />
                ) : (
                  <p>ไม่มีข้อมูล...</p>
                )}
              </div>

              <div className="main-color">
                <figure className="flex-box-4">
                  <div className="color-4"></div>
                  <p>ค่าปรับ</p>
                </figure>
                <figure className="flex-box-5">
                  <div className="color-5"></div>
                  <p>ค่าใช้จ่ายรายเดือน</p>
                </figure>
                <figure className="flex-box-6">
                  <div className="color-6"></div>
                  <p>ค่าเช่าค้างจ่าย</p>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Graphs;
