import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { Typography } from "@mui/material";
import { InputLabel } from "@mui/material";
import { getShowRent } from "../../services/rent.service";
import { Link } from "react-router-dom";

const RentalAmount = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [dataRental, setDataRental] = useState([]);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  // getShowRent
  useEffect(() => {
    getShowRent().then((res) => {
      const updatedData = res.data.rental.map((row) => ({
        ...row,
        total: row.TentDeposit + row.Deposit + row.Rent,
      }));
      setDataRental(updatedData);
    });
  }, []);

  console.log("dataRental", dataRental);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "Name",
      headerName: "ชื่อผู้เช่า",
      // headerAlign: "center",
      align: "left",
      sortable: false,
      width: isHovered ? 300 : 170,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: "StartDate",
      headerName: "วันที่เริ่มเช่า",
      width: 170,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "MoveOut",
      headerName: "วันที่ต้องจ่ายค่าเช่า",
      width: 170,
      sortable: true,
      // headerAlign: "center",
      // align: "center",
    },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 170,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Deposit",
      headerName: "ค่ามัดจำ",
      width: 170,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 170,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "total",
      headerName: "รายได้จากบล็อก",
      width: 170,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Update",
      headerName: "แสดงรายละเอียด",
      width: 170,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3", width: 110 }}
          component={Link}
          to={`/rental/detail/${params.row.id}`}
          target={"_blank"}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];

  const rowsFiltered = dataRental
    .filter((row) => {
      if (
        (age === "" || row.Zone === age) && // Filter by Zone
        (row.BlockNumber.includes(searchInput) ||
          row.Name.includes(searchInput))
      ) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) =>
      a.BlockNumber.localeCompare(b.BlockNumber, undefined, { numeric: true })
    );

  const zones = Array.from(new Set(dataRental.map((row) => row.Zone))).sort();

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header" style={{ marginBottom: "1.5rem" }}>
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>จำนวนค่าเช่า</div>
        <div className="search">
          <div className="">
            <FormControl fullWidth size="small" style={{ width: "100px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="ค้นหา บล็อก,ชื่อผู้เช่า"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
        </div>
      </div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RentalAmount;
