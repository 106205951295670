import axios from "axios";

export const svGetpeportshop = ()=>{
  return axios.get('reports/shop').then((res) => res).catch((error) => error);
}

export const getZoneData = () => {
    return axios.get(`rent/zone`).then((res) => res).catch((error) => error);
}

export const getBoxsZoneData = () => {
    return axios.get(`rent/boxs`).then((res) => res).catch((error) => error);
}

export const getBoxsData = (id) => {
    return axios.get(`rent/boxs/${id}`).then((res) => res).catch((error) => error);
}

export const getBusinessType = () => {
    return axios.get(`rent/business_type`).then((res) => res).catch((error) => error);
}

export const getShop = () => {
    return axios.get(`rent/shop`).then((res) => res).catch((error) => error);
}

export const getTenant = () => {
    return axios.get(`rent/tenant`).then((res) => res).catch((error) => error);
}

export const getShopDetail = (id) => {
    return axios.get(`rent/shop/detail/${id}`).then((res) => res).catch((error) => error);
}

export const getShopTenantData = () => {
    return axios.get(`rent/tenantwithshop`).then((res) => res).catch((error) => error);
}

export const getRentData = () => {
    return axios.get(`rent/data`).then((res) => res).catch((error) => error);
}

export const getRentDetail = (id) => {
    return axios.get(`rent/detail/${id}`).then((res) => res).catch((error) => error);
}

export const CreateRent = (foemData) => {
    return axios.post(`rent/create`, foemData).then((res) => res).catch((error) => error);
}

export const getShowRent = () => {
    return axios.get(`rent/show`).then((res) => res).catch((error) => error);
}

export const getRentalDetail = (id) => {
    return axios.get(`rent/rental/detail/${id}`).then((res) => res).catch((error) => error);
}

export const updateRentalData = (foemData) => {
    return axios.post(`rent/data/update`, foemData).then((res) => res).catch((error) => error);
}

export const updateMoveBlock = (foemData) => {
    return axios.post(`rent/moveblock`, foemData).then((res) => res).catch((error) => error);
}
