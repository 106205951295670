import axios from "axios";

export const svElectricityBillData = () => {
  return axios.get(`electricitybill/data`).then((res) => res).catch((err) => err);
}

export const svCreateElectricityBill = (formData) => {
  return axios.post(`electricitybill/Create`, formData).then((res) => res).catch((err) => err);
}

export const svElectricityBillUpdate = (formData) =>{
  return axios.post(`electricitybill/update`,formData).then((res) => res).catch((err) => err);
}

export const svElectricityBillDelete = (id)=>{
  return axios.delete(`electricitybill/destroy/${id}`).then((res) => res).catch((err) => err);
}