import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { Typography } from "@mui/material";

const ExpirationPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "RentDate",
      headerName: "วันที่เก็บค่าเช่า",
      width: 120,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 120,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "ImgShop",
      headerName: "ภาพหน้าร้าน",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: "RentalShopName",
      headerName: "ชื่อร้านที่เช่า",
      sortable: false,
      width: isHovered ? 290 : 150,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 150,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "อาหาร":
            color = "#FFCE72";
            break;
          case "เครื่องดื่ม":
            color = "#3F8CFF";
            break;
          case "เสื้อผ้าแฟร์ชั่น":
            color = "#FF9CBD";
            break;
          case "อุปกรณ์":
            color = "#6C5DD3";
            break;
          default:
            color = "inherit";
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    { field: "Rent", headerName: "ค่าเช่า", width: 110 },
    { field: "WaterBill", headerName: "ค่าน้ำ", width: 110 },
    { field: "ElectricityBill", headerName: "ค่าไฟ", width: 110 },
    { field: "Fine", headerName: "ค่าปรับ", width: 110 },
    {
      field: "Total",
      width: 140,
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}
          >
            ยอดรวมที่ต้องเก็บ
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const numberDay = params.value;

        if (!numberDay || !numberDay.trim()) {
          return <p>----฿</p>;
        }
        return numberDay;
      },
    },
    {
      field: "NumberRentalDays",
      width: 130,
      renderHeader: () => (
        <div>
          <Typography style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}>
         จำนวนวันรอบบิล
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;
      
        if (!PayRentvalue || !PayRentvalue.trim()) {
          return <p>--/--/--</p>;
        }
        return PayRentvalue;
      }
    },
    {
      field: "RentalStatus",
      headerName: "สถานะการเช่า",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row.RentalStatus === "ยังเช่าอยู่" ? "#00D715" : "#FF2C2C",
          }}
        >
          {params.row.RentalStatus}
        </div>
      ),
    },
    {
      field: "Invoice",
      headerName: "ใบแจ้งหนี้",
      width: 150,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
            width:"35px"
          }}
          onClick={() => handleOpen(params.value)}
        >
         <img
            src="/images/icons/pdf-file.png"
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          {/* <p style={{ color: "#3F8CFF" }}>view</p> */}
        </div>
      ),

      headerClassName: "custom-header-class",
    },
    {
      field: "Receipt",
      headerName: "ใบเสร็จ",
      width: 150,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
            width:"35px"
          }}
          onClick={() => handleOpen(params.value)}
        >
         <img
            src="/images/icons/pdf-file.png"
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          {/* <p style={{ color: "#3F8CFF" }}>view</p> */}
        </div>
      ),

      headerClassName: "custom-header-class",
    },
    {
      field: "ProofPayment",
      headerName: "หลักฐานการจ่าย",
      width: 150,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(params.value)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          <p style={{ color: "#3F8CFF" }}>view</p>
        </div>
      ),

      headerClassName: "custom-header-class",
    },
    {
      field: "Update",
      headerName: "อัปเดตข้อมูล",
      width: 110,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3" }}
          onClick={() => (window.location.href = "/expiration/update")}
          className="button-h"
        >
          อัปเดตข้อมูล
        </Button>
      ),
    },
  ];

  const rowsFiltered = rows.filter((row) => {
    if (
      (age === "" || row.Zone === age) && // Filter by Zone
      (row.BlockNumber.includes(searchInput) ||
        row.RentalShopName.includes(searchInput))
    ) {
      return true;
    } else {
      return false;
    }
  });

  const zones = Array.from(new Set(rows.map((row) => row.Zone)));

  const zoneOptions = zones.map((zone, index) => (
    <MenuItem key={index} value={zone}>
      {zone}
    </MenuItem>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
        วันที่ จ่าย/เก็บ ค่าเช่า
        </div>
        <div className="search">
          <p style={{ fontWeight: "600" }}>โซน</p>
          <FormControl fullWidth style={{ width: "70px" }}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              size="small"
              onChange={handleChange}
            >
              <MenuItem value="">ทั้งหมด</MenuItem>
              {zoneOptions}
            </Select>
          </FormControl>
          <TextField
            label="ค้นหา บล็อก,ร้าน"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            href="/products/add"
            className="button-h"
          >
            เพิ่มข้อมูลการเช่า
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExpirationPage;
