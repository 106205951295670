import React from "react";
import Button from "@mui/material/Button";

const rows = [
  {
    id: 1,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A01",
    RentalShopName: "ร้านยำยังใงให้กินได้ถึงดาวอังคาร",
    ImgShop: "/images/products/Rectangle 526.png",
    Fine: "----฿",
    Business: "อาหาร",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 2,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A02",
    RentalShopName: "ร้านขนมจีนน้ำยาโปราณ",
    ImgShop: "/images/products/Rectangle 528.png",
    Fine: "----฿",
    Business: "เครื่องดื่ม",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 3,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A03",
    RentalShopName: "ร้านยำตำทะเลเหาะ",
    ImgShop: "/images/products/Rectangle 529.png",
    Fine: "----฿",
    Business: "เสื้อผ้าแฟร์ชั่น",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 4,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A04",
    RentalShopName: "ร้านปลาย่างแม่น้ำ",
    ImgShop: "/images/products/Rectangle 530.png",
    Fine: "----฿",
    Business: "เครื่องดื่ม",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 5,
    Zone: "G",
    RentDate: "2023/10/03",
    BlockNumber: "G05",
    RentalShopName: "ร้านกล้วยทับบ้านนา",
    ImgShop: "/images/products/Rectangle 532.png",
    Fine: "----฿",
    Business: "เสื้อผ้าแฟร์ชั่น",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 6,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A06",
    RentalShopName: "ร้านยายมอยพัดไทย",
    ImgShop: "/images/products/Rectangle 533.png",
    Fine: "----฿",
    Business: "อุปกรณ์",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 7,
    Zone: "F",
    RentDate: "2023/10/03",
    BlockNumber: "F07",
    RentalShopName: "ร้านผลไม้สดปั่น",
    ImgShop: "/images/products/Rectangle 526.png",
    Fine: "----฿",
    Business: "อาหาร",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 8,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A08",
    RentalShopName: "ร้านผลไม้สดปั่น",
    ImgShop: "/images/products/Rectangle 528.png",
    Fine: "----฿",
    Business: "เสื้อผ้าแฟร์ชั่น",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 9,
    Zone: "E",
    RentDate: "2023/10/03",
    BlockNumber: "E09",
    RentalShopName: "ร้านผลไม้สดปั่น",
    ImgShop: "/images/products/Rectangle 529.png",
    Fine: "----฿",
    Business: "เครื่องดื่ม",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 10,
    Zone: "D",
    RentDate: "2023/10/03",
    BlockNumber: "D10",
    RentalShopName: "ร้านน้ำดื่นชื่นใจ",
    ImgShop: "/images/products/Rectangle 530.png",
    Fine: "----฿",
    Business: "อุปกรณ์",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 11,
    Zone: "A",
    RentDate: "2023/10/03",
    BlockNumber: "A11",
    RentalShopName: "ร้านเสื้อ",
    ImgShop: "/images/products/Rectangle 526.png",
    Fine: "----฿",
    Business: "อาหาร",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 12,
    Zone: "C",
    RentDate: "2023/10/03",
    BlockNumber: "C12",
    RentalShopName: "ร้านดูดีมีสไตร์",
    ImgShop: "/images/products/Rectangle 533.png",
    Fine: "----฿",
    Business: "เสื้อผ้าแฟร์ชั่น",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 13,
    Zone: "C",
    RentDate: "2023/10/03",
    BlockNumber: "C13",
    RentalShopName: "ร้านชวนชิม",
    ImgShop: "/images/products/Rectangle 526.png",
    Fine: "----฿",
    Business: "เครื่องดื่ม",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 14,
    Zone: "B",
    RentDate: "2023/10/03",
    BlockNumber: "B14",
    RentalShopName: "ร้านยีนอย่างเดียว",
    ImgShop: "/images/products/Rectangle 528.png",
    Fine: "----฿",
    Business: "อุปกรณ์",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
  {
    id: 15,
    Zone: "B",
    RentDate: "2023/10/03",
    BlockNumber: "B15",
    RentalShopName: "ร้านยีนอย่างเดียว",
    ImgShop: "/images/products/Rectangle 532.png",
    Fine: "----฿",
    Business: "เสื้อผ้าแฟร์ชั่น",
    Total: "6000$",
    NumberRentalDays: "30 วัน",
    Rent: 7000,
    WaterBill: 350,
    ElectricityBill: 2600,
    RentalStatus: "ยังเช่าอยู่",
    Update: <Button variant="contained">อัปเดตข้อมูล</Button>,
    ProofPayment: "/images/products/Rectangle 526.png",
  },
];

export default rows;
