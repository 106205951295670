/* eslint-disable */
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Card } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./components/Table";
import "./ProductsPage.scss";

function ProductsPage() {
  // นี่คือตัวแปรที่ใช้สำหรับการค้นหา
  const [searchInput, setSearchInput] = useState("");

  // ฟังก์ชันสำหรับการค้นหา
  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    // ทำการค้นหาตามคำค้นหาที่ผู้ใช้ป้อน
  };

  return (
    <section id="products-page">
      <div className="header">
        <div className="title-header">ข้อมูลรายละเอียดผู้เช่า</div>
        <div className="search">
          <TextField
            label="ค้นหา บล็อก,ชื่อผู้เช่า"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            href="/products/add"
            className="button-h"
          >
            เพิ่มข้อมูลการเช่า
          </Button>
        </div>
      </div>

      <div>
        <Table searchInput={searchInput} />
      </div>
    </section>
  );
}

export default ProductsPage;
