/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, FormControlLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Checkbox from "@mui/material/Checkbox";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Radio from "@mui/material/Radio";
import "./Salary.scss";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import AddEditFrom from "./AddEditFrom";
import CancelIcon from "@mui/icons-material/Cancel";
import rows from "./data/TableData";
import { useParams } from "react-router-dom";
import { svSalaryDetail } from "../../services/expenses/employe.service";
import { useSelector } from "react-redux";

function SalaryDetails() {
  const { id } = useParams();
  // const { t } = useTranslation(["dashboard-page"]);
  // const [loading, setLoading] = useState(true);
  // const [exportedProductDetails, setExportedProductDetails] = useState([]);
  // const [refreshData, setRefreshData] = useState(0);
  // const [year, setYear] = React.useState("");
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState({});
  const [employe, setEmploye] = useState();
  // const [selectedImage, setSelectedImage] = useState(null);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    // console.log(id);
    svSalaryDetail(id).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setDetail(res.data.detailsalary);
        setEmploye(res.data.employees);
      }
    });
  }, [employe, id, detail]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (event) => {
  //   setYear(event.target.value);
  // };

  const columns = [
    {
      field: "name",
      headerName: "ชื่อพนักงาน",
      sortable: false,
      width: 100,
      flex: 1,
    },
    {
      field: "telephone_number",
      headerName: "เบอร์ติดต่อ",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "department",
      headerName: "ตำแหน่ง",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "salary",
      headerName: "เงินเดือน",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "payment_date",
      headerName: "วันที่จ่ายเงินเดือน",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "payment_salary_date",
      headerName: "วันที่รับเงินเดือน",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "statusSalary",
      headerName: "สถานะการจ่าย",
      width: 100,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            color: "#00D715",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {"จ่ายแล้ว"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            {params.row.payment_salary_date}
          </p>
        </div>
      ),
    },
  ];

  // const rowsFiltered = rows;

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              gap: "2rem",
              marginBottom: "1rem",
            }}
          >
            <p style={{ width: "100%", fontSize: "1.75rem", fontWeight: 500 }}>
              รายละเอียด ประวัติการจ่ายเงินเดือน
            </p>
          </div>
        </div>

        <div className="box-detailS">
          <figure
            className="img-boxr"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          >
            <img
              src={`${uploadPath}${employe?.url_img}`}
              alt=""
              className="img-storer"
            />
          </figure>
          <div className="box-Er">
            <p className="title">ชื่อ - นามสกุล</p>
            <p className="NameShop">
              {employe?.first_name} {employe?.last_name}
            </p>
          </div>

          <div className="box-Br">
            <p className="title">วันที่เริ่มงาน</p>
            <p className="date-start">{employe?.work_start_date}</p>
          </div>

          <div className="box-Cr">
            <p className="title">ตำแหน่ง</p>
            <p className="deposit">{employe?.department}</p>
          </div>

          <div className="box-Dr">
            <p className="title">เงินเดือน</p>
            <p className="rent">{employe?.salary.toLocaleString()} ฿</p>
          </div>
        </div>

        <DataGrid
          rows={detail}
          columns={columns}
          rowHeight={70}
          rowsPerPageOptions={[5]}
          className="custom-data-grid"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src="/images/products/Rectangle 526.png"
              alt="Zoomed Shop"
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                width: "500px",
              }}
            />
          </div>
        </Modal>
      </>
    </section>
  );
}

export default SalaryDetails;
