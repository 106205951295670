const rows = [
  {
    id: 1,
    section: "ซ่อมพื้น",
    RequisitionDay: "05-01-2024",
    name: "สมพง คัมพี",
    total: "2000",
  },
  {
    id: 2,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-02-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 3,
    section: "ซ่อมพื้น",
    RequisitionDay: "10-03-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 4,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-03-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 5,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2024",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 6,
    section: "ซ่อมพื้น",
    RequisitionDay: "02-04-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 7,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 8,
    section: "ซ่อมพื้น",
    RequisitionDay: "01-06-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 9,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-05-2024",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 10,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-07-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 11,
    section: "ซ่อมพื้น",
    RequisitionDay: "05-08-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 12,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-09-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 13,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-10-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 14,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-12-2024",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 15,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2024",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 16,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 17,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
  {
    id: 18,
    section: "ซ่อมพื้น",
    RequisitionDay: "06-01-2023",
    name: "สมพง คัมพี",
    total: "200",
  },
];

export default rows;
