import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Modal, Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/material";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svUpdataMaintenance } from "../../../services/expenses/maintenance.service";

function FoemEditmodal(props) {
  const { setRefreshData, setOpenEditRepair, repairEdit, openEditRepair } =
    props;

  const [editData, setEditData] = useState(repairEdit);
  const [momentDateRepair, setMomemtDateRepair] = useState(
    moment(editData.date_repair, "DD/MM/YYYY")
  );
  const [isError, setIsError] = useState({
    titlename: false,
    repairbyname: false,
    daterepair: false,
    expenses: false,
  });

  const handleStartDateChange = (date) => {
    console.log(moment(date));
    setEditData((prevState) => ({
      ...prevState,
      date_repair: moment(date),
    }));
    setMomemtDateRepair(date);
  };

  const addValidators = () => {
    let isValid = true;
    let error = {
      ...isError,
      titlename: editData.title.trim().length < 1,
      repairbyname: editData.report_by.trim().length < 1,
      daterepair: editData.date_repair.toString().trim().length < 1,
      expenses: editData.expenses.toString().trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;
    if (isValid) {
      const formData = new FormData();
      formData.append("repairid", editData.id);
      formData.append("title_name", editData.title);
      formData.append("reportby", editData.report_by);
      formData.append("daterepir", editData.date_repair);
      formData.append("expenses", editData.expenses);
      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      //   return false;
      svUpdataMaintenance(formData).then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          setOpenEditRepair(false);
          SwalUI({
            status: res.data.status,
            //description: res.data.description
          });
        }
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        open={openEditRepair}
        onClose={() => {
          setOpenEditRepair(false);
        }}
      >
        <Box
          className="width-modal"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <div className="box-modal-add">
            <div className="justifu-CancelIcon">
              <p
                style={{
                  fontSize: "1.75rem",
                  fontWeight: 500,
                  alignItems: "center",
                }}
              >
                ค่าซ่อม / บำรุงรักษา
              </p>
              <button
                onClick={() => {
                  setOpenEditRepair(false);
                }}
              >
                <CancelIcon className="CancelIcon" />
              </button>
            </div>
            <div className="main-modal">
              <div className="box-modal-1">
                <div className="box-section">
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    หัวข้อ
                  </p>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    // label="หัวข้อ"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={editData.title}
                    error={isError.titlename}
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return { ...prevState, title: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="box-maintenance">
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    วันเบิกซ่อม/บำรุง
                  </p>
                  <DatePicker
                    // label="ปี"
                    value={momentDateRepair}
                    inputFormat="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    sx={{ width: "33.33%" }}
                    error={isError.daterepair}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        value={editData.date_repair}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        error={isError.daterepair}
                        required
                      />
                    )}
                  />
                </div>
              </div>
              <div className="box-modal-2">
                <div className="box-name-person">
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    ชื่อผู้แจ้งซ่อม/บำรุง
                  </p>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    // label="หัวข้อ"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={editData.report_by}
                    error={isError.repairbyname}
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return { ...prevState, report_by: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="box-total-expenses">
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    ยอดค่าใช้จ่าย
                  </p>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    // label="หัวข้อ"
                    InputProps={{
                      inputProps: {
                        pattern: "[0-9]", // Regular expression allowing only numbers
                      },
                    }}
                    onInput={(event) => {
                      const { value } = event.target;
                      const newValue = value.replace(/[^0-9]/g, ""); // Replace non-numeric characters with empty string
                      event.target.value = newValue; // Set the new value
                    }}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={editData.expenses}
                    error={isError.expenses}
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return { ...prevState, expenses: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Button-save">
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "14px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
              }}
              className="button-h"
              onClick={addValidators}
            >
              บันทึก
            </Button>
          </div>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
}

export default FoemEditmodal;
