import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { height } from "@mui/system";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function Table({
  detailPayment,
  exportedProductDetails,
  refreshData,
  setRefreshData,
  searchInput,
}) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const uploadPath = useSelector((state) => state.app.uploadPath);

  console.log(detailPayment);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "RentalDate",
      headerName: "วันที่",
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const Datevalue = params.value;

        if (!Datevalue || !Datevalue.trim()) {
          return <p>--/--/--</p>;
        }
        return Datevalue;
      },
    },

    {
      field: "image",
      headerName: "ภาพหน้าร้าน",
      width: 100,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อผู้เช่า",
      width: 200,
      renderCell: (params) => (
        <div>
          <p>{params.value}</p>
        </div>
      ),
      sortable: false,
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        if (params.value == null || params.value === 0) {
          return <p>----฿</p>;
        }
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
        return `${formattedValue}`;
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 100,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "ElectricityBill",
      headerName: "ค่าไฟ",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "overdue_date",
      headerName: "ค่าปรับ",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value*params.row.fines);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },

    {
      field: "NumberOfDays",
      // headerName: "วันที่ต้องจ่ายค่า เช่าในแต่ละเดือน",
      width: 150,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "30px" }}
          >
            วันที่ต้องจ่ายค่า
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            เช่าในแต่ละเดือน
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue || !PayRentvalue.trim()) {
          return <p>วันที่ 01 ของทุกเดือน</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: "total_amount",
      width: 120,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ยอดรวม
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ที่ต้องเก็บ
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "slip",
      headerName: "หลักฐานการจ่าย",
      width: 120,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(uploadPath + params.value)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          <p style={{ color: "#3F8CFF" }}>view</p>
        </div>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      BlockNumber: "A 01",
      BlockSize: "2.5x3m",
      thumbnail_link: "/images/products/Rectangle 532.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop A",
      Business: "อาหาร",
      RentalDate: "2020/01/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: " ",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: " ",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 2,
      BlockNumber: "A 02",
      BlockSize: "3x3m",
      thumbnail_link: "/images/products/Rectangle 532.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop B",
      Business: "เครื่องดื่ม",
      RentalDate: "2020/02/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 3,
      BlockNumber: "A 03",
      BlockSize: "2x2m",
      thumbnail_link: "/images/products/Rectangle 532.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop C",
      Business: "เสื้อผ้าแฟร์ชั่น",
      RentalDate: "2020/03/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 4,
      BlockNumber: "A 04",
      BlockSize: "2x2.5m",
      thumbnail_link: "/images/products/Rectangle 532.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop D",
      Business: "เครื่องดื่ม",
      RentalDate: "2020/04/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 5,
      BlockNumber: "A 05",
      BlockSize: "3x3.5m",
      thumbnail_link: "/images/products/Rectangle 532.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop E",
      Business: "เสื้อผ้าแฟร์ชั่น",
      RentalDate: "2020/05/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 6,
      BlockNumber: "A 06",
      BlockSize: "3x3.5m",
      thumbnail_link: "/images/products/Rectangle 533.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop F",
      Business: "อุปกรณ์",
      RentalDate: "2020/06/08",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 7,
      BlockNumber: "A 07",
      BlockSize: "2.5x2.5m",
      thumbnail_link: "/images/products/Rectangle 526.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop G",
      Business: "อาหาร",
      RentalDate: "2020/07/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 8,
      BlockNumber: "A 08",
      BlockSize: "2x2.5m",
      thumbnail_link: "/images/products/Rectangle 528.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop H",
      Business: "เสื้อผ้าแฟร์ชั่น",
      RentalDate: "2020/08/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 9,
      BlockNumber: "A 09",
      BlockSize: "3x3m",
      thumbnail_link: "/images/products/Rectangle 529.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop I",
      Business: "เครื่องดื่ม",
      RentalDate: "2020/09/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
    {
      id: 10,
      BlockNumber: "A 10",
      BlockSize: "2.5x3.5m",
      thumbnail_link: "/images/products/Rectangle 530.png",
      img: "/images/products/rypxu87l1kIpGvypT5ml-o.jpg",
      NameShop: "Shop J",
      Business: "อุปกรณ์",
      RentalDate: "2020/10/01",
      Deposit: " 2000",
      Rent: "6000",
      Total: "6000",
      WaterBill: "300",
      ElectricityBill: "2200",
      RentalStatus: "ยังเช่าอยู่",
      Fine: "200",
      StartDate: "2023/12/06",
      MoveOut: "2014/04/01",
      Date: "2024/04/28",
      NumberOfDays: "30 วัน",
      PayRent: "วันที่ 01 ของทุกเดือน",
      TentDeposit: 2000,
      Name: "ตุลยากร ภัชรภิรมย์",
    },
  
  ].filter((row) => row.NameShop.includes(searchInput));

  const rowsFiltered = detailPayment.filter((row) => row.name.includes(searchInput));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              // height: "350px",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Table;
