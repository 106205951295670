import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import "./ExpirationUpdate.scss";
import { useSelector } from "react-redux";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import dayjs from "dayjs";
import { SaveAsPDFHandler } from "./PDFModal";

const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

function ReceiptModal({
  receiptValue,
  blockNumbers,
  rentalCosts,
  nameRent,
  nameShop,
}) {
  const [randomNumber, setRandomNumber] = useState(null);
  const { selectedDate2, selectedDate3 } = receiptValue;

  useEffect(() => {
    generateRandomNumber();
  }, []);

  const generateRandomNumber = () => {
    const min = 100000;
    const max = 999999;
    let newRandomNumber;
    do {
      newRandomNumber = Math.floor(min + Math.random() * (max - min + 1));
    } while (newRandomNumber === randomNumber);

    const numberWithUFO = `UFO-${newRandomNumber}`;
    setRandomNumber(numberWithUFO);
  };

  const { displayName } = useSelector((state) => state.auth.profile);

  return (
    <div>
      <Box
        className="width-modal22"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 1,
        }}
      >
        <div className="print-invoice" id="print">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <img
              src="/images/icons/logo-web-UFOmarket.png"
              style={{ width: "250px" }}
            />
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              ใบเสร็จรับเงิน
            </h1>
          </div>

          <div className="header-invoiceEE">
            <div className="header11">
              <p style={{ fontWeight: "400" }}>วันที่แจ้ง : {today}</p>
              <p style={{ fontWeight: "400" }}>ชื่อผู้แจ้ง : {displayName}</p>
              <p style={{ fontWeight: 400 }}>
                ชื่อผู้เช่า :{" "}
                {nameRent.map((value, index) => (
                  <React.Fragment key={index}>
                    <span style={{ fontWeight: "400" }}>{value}</span>
                    {index !== nameRent.length - 1 && (index + 1) % 2 === 0 ? (
                      <span style={{ flexBasis: "100%" }} />
                    ) : (
                      index !== nameRent.length - 1 && ","
                    )}
                  </React.Fragment>
                ))}
              </p>
              <p style={{ fontWeight: 400 }}>
                บล็อกที่เช่า:{" "}
                {blockNumbers.map((value, index) => (
                  <React.Fragment key={index}>
                    <span style={{ fontWeight: "400" }}>{value}</span>
                    {index !== blockNumbers.length - 1 && ","}
                  </React.Fragment>
                ))}
              </p>
              <p style={{ fontWeight: "400" }}>เบอร์โทร : 098-8888-888</p>
              <p style={{ fontWeight: "400" }}>
                ตั้งแต่วันที่ : {dayjs(selectedDate2).format("DD / MM / YYYY")}{" "}
                - {dayjs(selectedDate3).format("DD / MM / YYYY")}
              </p>
            </div>
          </div>

          <div className="detail-invoice1">
            <div className="detail1">
              <p className="detail-text">คำอธิบาย</p>
              <p className="detail-text">จำนวนเงิน</p>
            </div>
            {blockNumbers.map((value, index) => (
              <React.Fragment key={index}>
                <div style={{ display: "flex-col", marginLeft: "1.8rem" }}>
                  <p style={{ fontWeight: "400", fontSize: "1.8rem" }}>
                    บล็อกที่เช่า : {value}
                  </p>

                  <div className="detail1">
                    <p className="detail-text1">ค่าเช่า</p>
                    <p className="detail-text1">
                      {rentalCosts[index].toLocaleString()} บ.
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          {!!receiptValue.totalFine && (
            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">ค่าปรับ</p>
                <p className="total-text">
                  {receiptValue.totalFine.toLocaleString()} บาท
                </p>
              </div>
            </div>
          )}

          {!!receiptValue.overdue && (
            <div className="total-invoice">
              <div className="total1">
                <p className="total-text">ค่าคงค้าง</p>
                <p className="total-text">
                  {receiptValue.overdue.toLocaleString()} บาท
                </p>
              </div>
            </div>
          )}

          <div className="total-invoice">
            <div className="total1">
              <p className="total-text">รวมทั้งหมด</p>
              <p className="total-text">{receiptValue.totalToPay} บาท</p>
            </div>
          </div>

          {!!receiptValue.note && (
            <div className="note-invoice">
              <p className="note-text">หมายเหตุ*</p>
              <p>{receiptValue.note}</p>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2rem",
            }}
          >
            <p style={{ fontSize: "2rem", fontWeight: "400" }}>
              ผู้รับเงิน : {displayName}
            </p>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "15px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "150px",
              display: "flex",
              gap: "4px",
              cursor: "pointer",
              marginBottom: "1rem",
            }}
            className="button-h"
            onClick={SaveAsPDFHandler}
          >
            <SaveAltIcon style={{ color: "white" }} />
            Download
          </Button>
        </div> */}
      </Box>
    </div>
  );
}

export default ReceiptModal;
