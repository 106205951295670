import axios from "axios"

export const svBathroomData = () => {
    return axios.get(`bathroom/data`).then((res) => res).catch((error) => error);
}

export const svBathroomCreate = (formData) => {
    return axios.post(`bathroom/create`, formData).then((res) => res).catch((error) => error);
}

export const svBathroomUpdate = (formData) => {
    return axios.post(`bathroom/update`, formData).then((res) => res).catch((error) => error);
}

export const svBathroomDelete = (id) => {
    return axios.delete(`bathroom/destroy/${id}`).then((res) => res).catch((error) => error);
}