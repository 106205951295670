import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
// import rows from "./data/TableData";
import { FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./VariousExpensesPage.scss";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { getVariousExpensesData } from "../../services/variousExpenses.service";

const ExpirationPage = ({setAddDataTotal}) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [selectedDate3, setSelectedDate3] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState("e");
  const [rows, setRows] = useState([]);
  const [totalLatestMonth, setTotalLatestMonth] = useState(0);

  console.log("rows", rows);
  

  const isValidDate = (date) => date !== null && dayjs(date).isValid();

  useEffect(() => {
    getVariousExpensesData().then((res) => {
      const data = res.data?.data;
      console.log('Data:', data); // ตรวจสอบข้อมูลที่ได้รับ
  
      const formatDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split("/").map(Number);
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;
        const formattedYear = date.getFullYear();
        const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
        return `${formattedYear}-${formattedMonth}`;
      };
  
      const expensesMap = {};
      const addExpense = (key, amount) => {
        if (!expensesMap[key]) {
          expensesMap[key] = {
            salary: 0,
            repair: 0,
            garbage: 0,
            electricity: 0,
            cleaning: 0,
            miscellaneous: 0,
          };
        }
        expensesMap[key].salary += amount.salary || 0;
        expensesMap[key].repair += amount.repair || 0;
        expensesMap[key].garbage += amount.garbage || 0;
        expensesMap[key].electricity += amount.electricity || 0;
        expensesMap[key].cleaning += amount.cleaning || 0;
        expensesMap[key].miscellaneous += amount.miscellaneous || 0;
      };
  
      data.expenses.forEach((item) => {
        const key = formatDate(item.payment_salary_date);
        if (key) addExpense(key, { salary: item.salary });
      });
  
      data.repair.forEach((item) => {
        const key = formatDate(item.date_repair);
        if (key) addExpense(key, { repair: item.repair_expenses });
      });
  
      data.garbages.forEach((item) => {
        const key = formatDate(item.date_garbages);
        if (key) addExpense(key, { garbage: item.garbage_expenses });
      });
  
      data.electricity.forEach((item) => {
        const key = formatDate(item.date_electricity);
        if (key) addExpense(key, { electricity: item.electricity_expenses });
      });
  
      data.cleaning.forEach((item) => {
        const key = formatDate(item.date_cleaning);
        if (key) addExpense(key, { cleaning: item.cleaning_expenses });
      });
  
      data.miscellaneous.forEach((item) => {
        const key = formatDate(item.date_miscellaneous);
        if (key) addExpense(key, { miscellaneous: item.miscellaneous_expenses });
      });
  
      const rowsData = Object.keys(expensesMap).map((key, index) => {
        const {
          salary,
          repair,
          garbage,
          electricity,
          cleaning,
          miscellaneous,
        } = expensesMap[key];
        const total =
          salary + repair + garbage + electricity + cleaning + miscellaneous;
  
        return {
          id: index,
          monthYear: key,
          salary,
          repair,
          garbage,
          electricity,
          cleaning,
          miscellaneous,
          total,
        };
      });
  
      rowsData.sort((a, b) => {
        const [yearA, monthA] = a.monthYear.split("-").map(Number);
        const [yearB, monthB] = b.monthYear.split("-").map(Number);
        return yearB - yearA || monthB - monthA;
      });
  
      console.log('Rows Data:', rowsData); // ตรวจสอบข้อมูลที่ได้รับการจัดเรียง
  
      setRows(rowsData);
  
      if (rowsData.length > 0) {
        const latestTotal = rowsData[0].total;
        console.log('Latest Total:', latestTotal);
        if (typeof setAddDataTotal === 'function') {
            setAddDataTotal(latestTotal);
        } else {
            console.error('setAddDataTotal is not a function');
        }
    }
    
});
  }, []);
  
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
    setSelectedValue("tambon3");
  };

  const rowsFiltered = rows.filter((row) => {
    if (selectedValue === "e") {
      return true;
    }
    if (
      selectedValue === "a" ||
      selectedValue === "b" ||
      selectedValue === "c" ||
      selectedValue === "d"
    ) {
      const [year, month] = row.monthYear.split("-").map(Number);

      const monthFilter =
        selectedValue === "b" &&
        isValidDate(selectedDate2) &&
        dayjs(selectedDate2).month() === month - 1 && // dayjs เดือนเริ่มต้นจาก 0
        dayjs(selectedDate2).year() === year;

      const yearFilter =
        selectedValue === "c" &&
        isValidDate(selectedDate3) &&
        dayjs(selectedDate3).year() === year;

      const dateRangeFilter =
        selectedValue === "d" &&
        isValidDate(selectedStartDate) &&
        isValidDate(selectedEndDate) &&
        dayjs(`${year}-${month}-01`).isBetween(
          dayjs(selectedStartDate).startOf("month"),
          dayjs(selectedEndDate).endOf("month"),
          null,
          "[)"
        ); // ช่วงเวลารวมวันแรกถึงวันสุดท้าย

      return monthFilter || yearFilter || dateRangeFilter;
    }
  });

  const columns = [
    {
      field: "monthYear",
      headerName: "วันที่จ่าย",
      sortable: false,
      width: 200,
    },
    {
      field: "salary",
      headerName: "เงินเดือน",
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "repair",
      headerName: "ค่าซ่อม/บำรุงรักษา",
      sortable: false,
      width: 220,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "garbage",
      headerName: "ค่าเก็บขยะ",
      sortable: false,
      width: 220,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "electricity",
      headerName: "ค่าไฟ",
      width: 190,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "cleaning",
      headerName: "ค่าซื้ออุปกรณ์ทำความสะอาด",
      width: 230,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "miscellaneous",
      headerName: "ค่าใช้จ่ายเบ็ดเตล็ด",
      width: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "total",
      headerName: "รวมค่าใช้จ่าย",
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
  ];

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalSalary += row.salary;
      accumulator.totalRepair += row.repair;
      accumulator.totalGarbage += row.garbage;
      accumulator.totalElectricityBill += row.electricity;
      accumulator.totalCleaning += row.cleaning;
      accumulator.totalMiscellaneous += row.miscellaneous;
      accumulator.totalMoney += row.Total;
      return accumulator;
    },
    {
      totalSalary: 0,
      totalRepair: 0,
      totalGarbage: 0,
      totalElectricityBill: 0,
      totalCleaning: 0,
      totalMiscellaneous: 0,
      totalMoney: 0,
    }
  );

  const {
    totalSalary,
    totalRepair,
    totalGarbage,
    totalElectricityBill,
    totalCleaning,
    totalMiscellaneous,
    totalMoney,
  } = totals;

  const formattedtotalSalary = totalSalary.toLocaleString();
  const formattedtotalRepair = totalRepair.toLocaleString();
  const formattedTotalGarbage = totalGarbage.toLocaleString();
  const formattedTotalElectricityBill = totalElectricityBill.toLocaleString();
  const formattedtotalCleaning = totalCleaning.toLocaleString();
  const formattedTotalMiscellaneous = totalMiscellaneous.toLocaleString();
  const formattedTotalMoney = totalMoney.toLocaleString();

  const totalAll =
    totalSalary +
    totalRepair +
    totalGarbage +
    totalElectricityBill +
    totalCleaning +
    totalMiscellaneous;
  const formattedTotalAll = totalAll.toLocaleString();
  console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  const dataTotal = [
    { p: "เงินเดือน : ", price: formattedtotalSalary },
    { p: "ซ่อม/บำรุงรักษา : ", price: formattedtotalRepair },
    { p: "เก็บขยะ : ", price: formattedTotalGarbage },
    { p: "ค่าไฟ : ", price: formattedTotalElectricityBill },
    { p: "ซื้ออุปกรณ์ทำความสะอาด : ", price: formattedtotalCleaning },
    { p: "ค่าใช้จ่ายเบ็ดเตล็ด : ", price: formattedTotalMiscellaneous },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  const exportToExcel = () => {
    const headers = {
      วันที่จ่าย: "monthYear",
      งินเดือน: "salary",
      ค่าซ่อมและบำรุงรักษา: "repair",
      ค่าเก็บขยะ: "garbage",
      ค่าไฟ: "electricity",
      ค่าซื้ออุปกรณ์ทำความสะอาด: "cleaning",
      ค่าใช้จ่ายเบ็ดเตล็ด: "miscellaneous",
      รวมค่าใช้จ่าย: "total",
    };

    // สร้างข้อมูลที่กรองแล้ว
    const mappedRows = rowsFiltered.map((row) => {
      const mappedRow = {};
      for (const key in headers) {
        mappedRow[key] = row[headers[key]];
      }
      return mappedRow;
    });

    // สร้างแผ่นงานใหม่
    const worksheet = XLSX.utils.json_to_sheet([]);

    // เพิ่มหัวข้อ "รายงานรายได้ค่าเช่า ค่าปรับ" และผสานเซลล์
    XLSX.utils.sheet_add_aoa(worksheet, [["รายงานค่าใช้จ่าย"]], {
      origin: "A1",
    });
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];

    // เพิ่มชื่อคอลัมน์ในแถวที่สอง
    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(headers)], {
      origin: "A2",
    });

    // เพิ่มข้อมูลในแถวที่สาม
    XLSX.utils.sheet_add_json(worksheet, mappedRows, {
      origin: "A3",
      skipHeader: true,
    });

    // ข้อมูลผลรวม
    const dataTotal = [
      { p: "" },
      { p: "ผลรวมค่าต่างๆ" },
      { p: "เงินเดือน : ", price: formattedtotalSalary },
      { p: "ซ่อม/บำรุงรักษา : ", price: formattedtotalRepair },
      { p: "ค่าไฟ : ", price: formattedTotalElectricityBill },
      { p: "เก็บขยะ :", price: formattedTotalGarbage },
      { p: "ซื้ออุปกรณ์ทำความสะอาด : ", price: formattedtotalCleaning },
      { p: "ค่าใช้จ่ายเบ็ดเตล็ด : ", price: formattedTotalMiscellaneous },
      { p: "ยอดรวมทั้งหมด : ", price: formattedTotalAll },
    ];

    // เพิ่มข้อมูลผลรวมในแถวสุดท้าย
    const dataTotalRows = dataTotal.map((item) => [item.p, item.price]);
    XLSX.utils.sheet_add_aoa(worksheet, dataTotalRows, {
      origin: "A" + (mappedRows.length + 4),
    });

    // สร้าง Workbook และเพิ่ม Worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");

    // แปลง Workbook เป็น ArrayBuffer และ Blob
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    // บันทึกไฟล์ Excel
    saveAs(data, "รายงานค่าใช้จ่าย.xlsx");
  };

  useEffect(() => {
    if (selectedValue !== "a") {
      setSelectedDate1(null);
    }
    if (selectedValue !== "b") {
      setSelectedDate2(null);
    }
    if (selectedValue !== "c") {
      setSelectedDate3(null);
    }
    if (selectedValue !== "d") {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [selectedValue]);

  const handleDateChange1 = (newDate) => {
    setSelectedDate1(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setSelectedDate2(newDate);
  };

  const handleDateChange3 = (newDate) => {
    setSelectedDate3(newDate);
  };

  const handleStartDateChange = (newDate) => {
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setSelectedEndDate(newDate);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>

      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          รายงานค่าใช้จ่าย
        </div>
      </div>

      <div className="from-various">
        <div className="from-various1">
          <p style={{ fontSize: "1rem", fontWeight: 500 }}>
            รวมค่าใช้จ่ายต่างๆ ทั้งหมด
          </p>
          <p style={{ fontSize: "2rem", fontWeight: 700 }}>
            {formattedTotalAll} บาท
          </p>
        </div>
        {/* บล็อกด้านขวา */}
        <div className="from-filter1">
          <div className="button-text">
            <p style={{ fontWeight: 700, fontSize: "16px" }}>เลือก</p>
            <Button
              variant="contained"
              size="small"
              style={{
                fontSize: "14px",
                background: "#6C5DD3",
                color: "white",
                height: "39px",
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
                // marginTop: "2rem",
              }}
              className="button-h"
              onClick={exportToExcel}
            >
              Export to Excel
            </Button>
          </div>

          <div className="from-Date-filter1">
            {/* <div className="date-1">
              <Radio {...controlProps("a")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                วัน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="วัน"
                  inputFormat="DD/MM/YYYY"
                  disabled={selectedValue !== "a"}
                  value={selectedDate1}
                  onChange={handleDateChange1}
                  views={["day", "month", "year"]}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div> */}
            <div className="date-2">
              <Radio {...controlProps("b")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                เดือน
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="เดือน"
                  inputFormat="MM/YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "b"}
                  value={selectedDate2}
                  onChange={handleDateChange2}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-3">
              <Radio {...controlProps("c")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ปี
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ปี"
                  inputFormat="YYYY"
                  views={["year"]}
                  disabled={selectedValue !== "c"}
                  value={selectedDate3}
                  onChange={handleDateChange3}
                  sx={{ width: "33.33%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-4">
              <Radio {...controlProps("e")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ทั้งหมด
              </p>
            </div>

            <div className="date-5">
              <Radio {...controlProps("d")} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "36px",
                }}
              >
                ตั้งแต่
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="ตั้งแต่"
                  inputFormat="MM/YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "d"}
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="date-6">
              <p
                className="date-2-text"
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  marginRight: "19px",
                }}
              >
                จนถึง
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="จนถึง"
                  inputFormat="MM/YYYY"
                  views={["month", "year"]}
                  disabled={selectedValue !== "d"}
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>

      <div className="data-box3">{totalData}</div>

      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExpirationPage;
