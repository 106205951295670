import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import rows from "./data/TableData";
import { InputLabel } from "@mui/material";
import { getPaymentData } from "../../services/proofpayment.service";

const ProofPayment = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [age, setAge] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [rentData, setRentData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  useEffect(() => {
    getPaymentData().then((res) => {
      if (res.status === 200) {
        setRentData(res.data.rent);
      }
    });
  }, [refreshData]);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const searchSupplier = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "Name",
      headerName: "ชื่อผู้เช่าล่าสุด",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <div style={{ paddingLeft: "0" }}>
          <p>{params.row.tenant.name}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 180,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 220,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 220,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "Deposit",
      headerName: "ค่ามัดจำ",
      width: 220,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "ElectricityBill",
      headerName: "ค่าไฟ",
      width: 220,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "----฿";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },

    {
      field: "Update",
      headerName: "แสดงรายละเอียด",
      width: 220,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          style={{ backgroundColor: "#6C5DD3", width: 110 }}
          onClick={() => {
            const newWindow = window.open("/proof-payment/details/" + params.row.boxId, "_blank");
            if (newWindow) {
              newWindow.opener = null;
            }
          }}
          className="button-h"
        >
          รายละเอียด
        </Button>
      ),
    },
  ];

  const rowsFiltered = rentData
    .filter((row) => {
      if (
        (age === "" || row.zone_name === age) &&
        (row.BlockNumber.includes(searchInput) ||
          row.tenant.name.includes(searchInput))
      ) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) =>
      a.BlockNumber.localeCompare(b.BlockNumber, undefined, { numeric: true })
    );

  const zones = Array.from(new Set(rentData.map((row) => row.zone_name)));

  const zoneOptions = zones
    .sort(function (a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((zone, index) => (
      <MenuItem key={index} value={zone}>
        {zone}
      </MenuItem>
    ));

  const totals = rowsFiltered.reduce(
    (accumulator, row) => {
      accumulator.totalDeposit += row.Deposit;
      accumulator.totalRent += row.Rent;
      accumulator.totalWaterBill += row.WaterBill;
      accumulator.totalElectricityBill += row.ElectricityBill;
      return accumulator;
    },
    {
      totalDeposit: 0,
      totalRent: 0,
      totalWaterBill: 0,
      totalElectricityBill: 0,
    }
  );

  const { totalDeposit, totalRent, totalWaterBill, totalElectricityBill } =
    totals;

  const formattedTotalDeposit = totalDeposit.toLocaleString();
  const formattedTotalRent = totalRent.toLocaleString();
  const formattedTotalWaterBill = totalWaterBill.toLocaleString();
  const formattedTotalElectricityBill = totalElectricityBill.toLocaleString();

  const totalAll =
    totalDeposit + totalRent + totalWaterBill + totalElectricityBill;
  const formattedTotalAll = totalAll.toLocaleString();
  console.log("ผลรวมทั้งหมด:", formattedTotalAll);

  const dataTotal = [
    { p: "รวมค่าเช่า : ", price: formattedTotalRent },
    { p: "รวมค่ามัดจำ : ", price: formattedTotalDeposit },
    // { p: "รวมค่าน้ำ : ", price: formattedTotalWaterBill },
    { p: "รวมค่าไฟ : ", price: formattedTotalElectricityBill },
    // { p: "ยอดรวมทั้งหมด : ", price: formattedTotalAll },
  ];

  const totalData = dataTotal.map((item, index) => (
    <div key={index} className="total-Data">
      <p className="title-total">{item.p}</p>
      <p className="price-total">{item.price} ฿</p>
    </div>
  ));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="header">
        <div style={{ fontSize: "1.75rem", fontWeight: 500 }}>
          หลักฐานการจ่ายเงิน
        </div>
        <div className="search">
          <div className="">
            <FormControl fullWidth size="small" style={{ width: "100px" }}>
              <InputLabel id="demo-select-small-label">โซน</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={rentalStatus2}
                label="โซน"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>ทั้งหมด</em>
                </MenuItem>
                {zoneOptions}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="ค้นหา บล็อก,ร้าน"
            id="outlined-size-small"
            size="small"
            style={{ width: "230px" }}
            onChange={(e) => searchSupplier(e)}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "grey" }} />,
            }}
          />
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
            }}
            component="a"
            href="/products/add"
            className="button-h"
          >
            เพิ่มข้อมูลการเช่า
          </Button>
        </div>
      </div>

      <div className="data-box">{totalData}</div>

      <DataGrid
        rows={rowsFiltered}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProofPayment;
