import React, { useState } from "react";
import { Modal, Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import "./FormAddmodalMiscellaneous.scss";
import { svMiscellaneousCreate } from "../../../services/expenses/miscellaneous.service";

const addDataDefault = {
  title: "",
  quantity: "",
  expenses: "",
  comment: "-",
};

function FormAddmodalMiscellaneous(props) {
  const { openshop, handleCloseShop, setRefreshData } = props;
  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [addData, setAddData] = useState(addDataDefault);
  const [isError, setIsError] = useState({
    title: false,
    RequisitionDay: false,
    quantity: false,
    expenses: false,
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleCancel = () => {
    handleCloseShop();
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: addData.title.trim().length < 1,
      RequisitionDay: selectedStartDate.toString().trim().length < 1,
      quantity: addData.quantity.trim().length < 1,
      expenses: addData.expenses.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();

      formData.append("title", addData.title);
      formData.append("RequisitionDay", selectedStartDate.format("DD/MM/YYYY"));
      formData.append("quantity", addData.quantity);
      formData.append("totalExpenses", addData.expenses);
      formData.append("comment", addData.comment);

      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      svMiscellaneousCreate(formData).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
          SwalUI({
            status: res.data.status,
            //description: res.data.description
          });
          handleCloseShop();
        }
      });
    }
  };

  return (
    <Modal open={openshop} onClose={handleCloseShop}>
      <Box
        className="width-modal"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          p: 4,
        }}
      >
        <div className="box-modal-add">
          <div className="justifu-CancelIcon">
            <p
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              ข้อมูลค่าใช้จ่ายเบ็ดเตล็ด
            </p>
            <button onClick={handleCancel}>
              <CancelIcon className="CancelIcon" />
            </button>
          </div>

          <div className="main-modal">
            <div className="box-modal-1">
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  หัวข้อ
                </p>
                <TextField
                  id="outlined-basic"
                  value={addData.title}
                  size="small"
                  // label="หัวข้อ"
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.title}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, title: e.target.value };
                    })
                  }
                />
              </div>
              <div className="box-section">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  วันที่จ่าย
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="วันที่จ่าย"
                    inputFormat="DD / MM / YYYY"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    sx={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="box-modal-2">
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  จำนวนที่ซื้อ
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.quantity}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setAddData((prevState) => {
                        return { ...prevState, quantity: value };
                      });
                    }
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </div>
              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  ยอดค่าใช้จ่าย
                </p>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={addData.expenses}
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={isError.expenses}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setAddData((prevState) => {
                        return { ...prevState, expenses: value };
                      });
                    }
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </div>

              <div className="box-name-person">
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  หมายเหตุ
                </p>

                <TextField
                  id="filled-multiline-static"
                  size="small"
                  value={addData.comment}
                  multiline
                  rows={4}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setAddData((prevState) => {
                      return { ...prevState, comment: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Button-save">
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "14px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
            }}
            className="button-h"
            onClick={addValidators}
          >
            บันทึก
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default FormAddmodalMiscellaneous;
