import axios from "axios"

export const svDataWaterBill=()=>{
    return axios.get(`waterbill/data`).then((res)=> res).catch((error) => error);
}

export const svCreateWaterBill=(formData)=>{
    return axios.post(`waterbill/Create`,formData).then((res)=> res).catch((error) => error);
}

export const svUpdateWaterBill=(formData)=>{
    return axios.post(`waterbill/update`,formData).then((res)=> res).catch((error) => error);
}

export const svDeleteWaterBill=(id)=>{
    return axios.delete(`waterbill/destroy/${id}`).then((res)=> res).catch((error) => error);
}


