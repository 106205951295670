import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { useTranslation } from "react-i18next";
import { Autocomplete, Button, Card, TextField } from "@mui/material";
import axios from "axios";
import { useRef } from "react";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import ReactToPdf from "react-to-pdf";
import PDFFile from "./components/PDFFile";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./RentalAmountDetails.scss";
import GraphRental from "./components/GraphRental";
import { getRentalDetail } from "../../services/rent.service";
import { getDataDetail } from "../../services/report.service";
import { useParams } from "react-router-dom";
import rows from "./data/TableData";
import { useSelector } from "react-redux";

function RentalAmountDetails() {
  const { id } = useParams();
  const { t } = useTranslation(["dashboard-page"]);
  const [loading, setLoading] = useState(true);
  const [exportedProductDetails, setExportedProductDetails] = useState([]);
  const [year, setYear] = React.useState("");
  const [showTable, setShowTable] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataRental, setDataRental] = useState([]);
  const [rentalDetail, setRentalDetail] = useState([]);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    console.log(id);
    // getRentalDetail(id).then((res) => {
    //   setDataRental(res.data.RentalDetail);
    // });

    getDataDetail(id).then((res) => {
      setDataRental(res.data.detail);
      setRentalDetail(res.data.payment)
    });
  }, [id]);

  console.log(dataRental);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "PayRentDate",
      headerName: "วันที่",
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const Datevalue = params.value;

        if (!Datevalue || !Datevalue.trim()) {
          return <p>--/--/--</p>;
        }
        return Datevalue;
      },
    },
    {
      field: "RentalDate",
      headerName: "วันที่เริ่มเช่า",
      width: 100,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      renderCell: (params) => {
        const Datevalue = params.value;

        if (!Datevalue || !Datevalue.trim()) {
          return <p>--/--/--</p>;
        }
        return Datevalue;
      },
    },
    {
      field: "image",
      headerName: "ภาพหน้าร้าน",
      width: 100,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <img
          src={uploadPath + params.value}
          alt="Shop"
          style={{
            width: 100,
            borderRadius: "0.375rem",
            width: "59px",
            height: "59px",
          }}
          onClick={() => handleOpen(params.value)}
        />
      ),
    },
    {
      field: "name",
      headerName: "ชื่อผู้เช่า",
      width: 200,
      renderCell: (params) => (
        <div>
          <p>{params.value}</p>
        </div>
      ),
      sortable: false,
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: params.row.color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "tentfee",
      headerName: "ค่ามัดจำเต็นท์",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "deposit",
      headerName: "ค่ามัดจำ",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "rent",
      headerName: "ค่าเช่า",
      width: 100,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    // {
    //   field: "WaterBill",
    //   headerName: "ค่าน้ำ",
    //   width: 100,
    //   sortable: false,
    //   valueFormatter: (params) => {
    //     if (params.value == null) {
    //       return "";
    //     }
    //     return new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);
    //   },
    //   renderCell: (params) => {
    //     const formattedValue = new Intl.NumberFormat("th-TH", {
    //       maximumFractionDigits: 0,
    //     }).format(params.value);

    //     if (!params.value) {
    //       return <p>----฿</p>;
    //     }
    //     return `${formattedValue}`;
    //   },
    // },
    {
      field: "ElectricityBill",
      headerName: "ค่าไฟ",
      width: 100,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "overdue_date",
      headerName: "ค่าปรับ",
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value*params.row.fines);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "total_amount",
      // headerName: "จำนวนวันที่เช่า ของรอบบิล",
      width: 120,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ยอดรวม
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            ที่ต้องเก็บ
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const formattedValue = new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);

        if (!params.value) {
          return <p>----฿</p>;
        }
        return `${formattedValue}`;
      },
    },
    {
      field: "PayRent",
      // headerName: "วันที่ต้องจ่ายค่า เช่าในแต่ละเดือน",
      width: 120,
      sortable: false,
      // headerAlign: "center",
      // align: "center",
      renderHeader: () => (
        <div>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            วันที่ต้องจ่ายค่า
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: 500, lineHeight: "14px" }}
          >
            เช่าในแต่ละเดือน
          </Typography>
        </div>
      ),
      renderCell: (params) => {
        const PayRentvalue = params.value;

        if (!PayRentvalue || !PayRentvalue.trim()) {
          return <p>วันที่ 01 ของทุกเดือน</p>;
        }
        return PayRentvalue;
      },
    },
    {
      field: "slip",
      headerName: "หลักฐานการจ่าย",
      width: 150,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(uploadPath + params.row.slip)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100%", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          <p style={{ color: "#3F8CFF" }}>view</p>
        </div>
      ),
    },
  ];
  const rowsFiltered = rentalDetail;

  // const showTableHandler = () => {
  //   setShowTable(true); // เปลี่ยนค่า state เพื่อแสดงตาราง
  //   setShowForm(false); // ซ่อนฟอร์ม
  // };

  // const showFormHandler = () => {
  //   setShowTable(false); // ซ่อนตาราง
  //   setShowForm(true); // เปิดฟอร์ม
  // };
  

  console.log(exportedProductDetails);

  return (
    <section id="export-details-page">
      {/* {loading ? (
        <PulseLoader color="#3b326b" />
      ) : ( */}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              gap: "2rem",
              marginBottom: "1rem",
            }}
          >
            <p style={{ width: "100%", fontSize: "1.75rem", fontWeight: 500 }}>
              จำนวนค่าเช่า
            </p>
          </div>
        </div>

        <div className="box-detail">
          <div className="box-Ar">
            <p className="number">{dataRental.BlockNumber}</p>
            <p className="size">{dataRental.box_size}</p>
          </div>

          <figure className="img-boxr">
            <img
              src={`${uploadPath}${dataRental.image}`}
              alt=""
              className="img-storer"
            />
          </figure>

          <div className="box-Br">
            <p className="title">วันที่เริ่มเช่า</p>
            <p className="date-start">{dataRental.RentalDate}</p>
          </div>
          <div className="box-Cr">
            <p className="title">ค่ามัดจำ</p>
            <p className="deposit">
              {dataRental?.deposit?.toLocaleString()} ฿
            </p>
          </div>

          <div className="box-Dr">
            <p className="title">ค่าเช่า</p>
            <p className="rent">
              {dataRental?.rent?.toLocaleString()} ฿
            </p>
          </div>

          <div className="box-Er">
            <p className="title">ชื่อผู้เช่า</p>
            <p className="NameShop">{dataRental.name}</p>
          </div>

          <div className="box-Fr">
            <p className="title">ประเภทธุรกิจ</p>
            <div
              className="type-business"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  borderRadius: "3px",
                  height: "16px",
                  width: "16px",
                  backgroundColor: `${dataRental.color}`,
                  marginRight: "6px",
                }}
              />
              <div className="NameShop">{dataRental.Business}</div>
            </div>
          </div>
        </div>

        <div
          className="box-button"
          style={{
            width: "100%",
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            gap: "2rem",
            marginBottom: "1rem",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              gap: "1rem",
            }}
          >
            <Button
              // onClick={showTableHandler}
              variant="contained"
              sx={{
                background: "#6C5DD3",
                width: "150px",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              className="button-h"
            >
              ค่าเช่า
            </Button>

            {/* <Button
              onClick={showFormHandler}
              variant="contained"
              sx={{
                background: showForm ? "#6C5DD3" : "#707070",
                width: "150px",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              className="button-h"
            >
              ข้อมูลรายได้
            </Button> */}
          </div>

          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              gap: "1rem",
            }}
          >
            {showTable && (
              <FormControl
                sx={{ minWidth: 150 }}
                size="small"
                style={{ backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-label">ปี</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={year}
                  label="ปี"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>2023</MenuItem>
                  <MenuItem value={20}>2022</MenuItem>
                  <MenuItem value={30}>2021</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        </div>

        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={rowsFiltered}
            columns={columns}
            rowHeight={70}
            rowsPerPageOptions={[5]}
            className="custom-data-grid"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img
                src={selectedImage}
                alt="Zoomed Shop"
                style={{
                  maxWidth: "90vw",
                  maxHeight: "90vh",
                  // height: "350px",
                  width: "500px",
                }}
              />
            </div>
          </Modal>
        </div>
      </>
      {/* )} */}
    </section>
  );
}

export default RentalAmountDetails;
