import axios from "axios";

export const exGetRentData = () => {
  return axios
    .get(`expiration/rent/data`)
    .then((res) => res)
    .catch((error) => error);
};

export const updateExpiration = (foemData) => {
  return axios
    .post(`expiration/update`, foemData)
    .then((res) => res)
    .catch((error) => error);
};
