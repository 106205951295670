import React, { useState, useRef, useEffect } from "react";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { Button, TextField } from "@mui/material";
import { ChromePicker } from "react-color"; // import ChromePicker จาก react-color
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { svBusinessTypeCreate } from "../../../services/setting/businesstype.service";

const addDataDefault = {
  typrnmae: " ",
  blockColor: "#FFFFFF",
};

function FormAddColor(props) {
  const { handlemodalAdd, refreshData } = props;
  // const [blockName, setBlockName] = useState("");
  // const [blockColor, setBlockColor] = useState("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false); // State เพื่อควบคุมการแสดง/ซ่อน ChromePicker
  const [addData, setAddData] = useState(addDataDefault);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState({
    typrnmae: false,
    color: false,
  });

  const handleCloseColorPicker = () => {
    setShowColorPicker(false);
  };

  const hoverStyle = {
    transform: "scale(1.1)", // Example: Increase size on hover
  };

  // Function to handle reset
  // const handleReset = () => {
  //   // Reset input values
  //   setBlockName("");
  //   setBlockColor(" ");
  // };

  const addValidators = () => {
    let isValid = true;
    let error = {
      ...isError,
      typrnmae: addData.typrnmae.trim().length < 1,
    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("Btypename", addData.typrnmae);
      formData.append("businesscolor", addData.blockColor);

      svBusinessTypeCreate(formData).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          refreshData((prev) => prev + 1);
          handlemodalAdd(false);
          SwalUI({
            status: res.data.status,
            //description: res.data.description
          });
        } else {
          const { Btypename } = res.response.data;
          // console.log(Btypename);
          setErrorMessage(Btypename[0]);
        }
      });
    }
  };

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
          className="defective-nav"
        >
          <div style={{ width: "100%" }}>
            <p
              className=""
              style={{
                fontSize: "1.75rem",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              เพิ่ม / แก้ไข ประเภทธุรกิจ
            </p>
          </div>
        </div>
        <div className="box-form1">
          <div className="first-row">
            <label htmlFor="">ชื่อประเภทธุรกิจ</label>
            <TextField
              id="outlined-basic"
              // label="ประเภทธุรกิจ"
              variant="outlined"
              size="small"
              sx={{ width: "20%" }}
              value={addData.typrnmae}
              // error={isError.typrnmae}
              error={isError.typrnmae}
              onChange={(e) =>
                setAddData((prevState) => {
                  return { ...prevState, typrnmae: e.target.value };
                })
              }
              helperText={errorMessage}
              FormHelperTextProps={{
                sx: {
                  color: "red",
                  fontSize: "13px !important",
                },
              }}
            />
          </div>

          <div className="first-row">
            <label htmlFor="">เลือกสี</label>
            <div>
              <TextField
                id="outlined-basic"
                label={
                  <div
                    style={{
                      borderRadius: "3px",
                      height: "25px",
                      width: "70px",
                      backgroundColor: addData.blockColor,
                      border: "1px solid",
                    }}
                  />
                }
                variant="outlined"
                size="small"
                sx={{ width: "20%", paddingBottom: "6px" }}
                value={addData.blockColor}
                onFocus={() => setShowColorPicker(true)}
                // onBlur={() => setShowColorPicker(false)} // เมื่อ TextField ไม่ได้ focus ให้ซ่อน ChromePicker
              />
              {showColorPicker && (
                <div
                  style={{ display: "flex", alignItems: "start", gap: "4px" }}
                >
                  <ChromePicker
                    color={addData.blockColor}
                    onChange={(color) =>
                      setAddData((prevState) => {
                        return { ...prevState, blockColor: color.hex };
                      })
                    }
                  />
                  <FontAwesomeIcon
                    onClick={handleCloseColorPicker}
                    icon={faCircleXmark}
                    style={{ color: "#f00000", cursor: "pointer" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="box-btn">
          {/* <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#FF2C2C",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            onClick={handleReset}
            className="button-Delete"
          >
            ลบข้อมูล
          </Button> */}

          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#707070",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            onClick={() => {
              handlemodalAdd(false);
            }}
            className="button-h"
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              fontSize: "16px",
              background: "#6C5DD3",
              color: "white",
              height: "39px",
              width: "100px",
            }}
            component="a"
            onClick={addValidators}
            className="button-h"
          >
            บันทึก
          </Button>
        </div>
      </>
    </div>
  );
}

export default FormAddColor;
