import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { getRentData } from "../../../services/rent.service";
// import { getPayRentData } from "../../services/payrent.service";
// import './Table.scss';

const DataGridExample = ({ searchInput, rentalStatus }) => {
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rentalStatus1, setRentalStatus] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [rows, setRows] = useState([]);

  console.log("rows", rows);

  useEffect(() => {
    getRentData().then((res) => {
      console.log(res);
      const rentDataRes = res.data.rent;
      setRows(rentDataRes);
    });
  }, []);

  const handleRentalStatusChange = (event) => {
    setRentalStatus(event.target.value); // สำหรับตารางที่ 1
    // setRentalStatus2(event.target.value); // สำหรับตารางที่ 2
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(`${uploadPath}${imageUrl}`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: "RentalDate",
      headerName: "วันที่เก็บค่าเช่า",
      width: 120,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    {
      field: "BlockNumber",
      headerName: "เลขที่บล็อก",
      width: 100,
      renderCell: (params) => (
        <div style={{ fontWeight: 500 }}>{params.value}</div>
      ),
    },
    // {
    //   field: "ImgShop",
    //   headerName: "ภาพหน้าร้าน",
    //   width: 140,
    //   renderCell: (params) => (
    //     <img
    //       src={params.value}
    //       alt="Shop"
    //       style={{
    //         width: 100,
    //         borderRadius: "0.375rem",
    //         width: "59px",
    //         height: "59px",
    //       }}
    //       onClick={() => handleOpen(params.value)}
    //     />
    //   ),
    // },
    {
      field: "tenantName",
      headerName: "ชื่อผู้เช่า",
      width: isHovered ? 250 : 160,
      renderCell: (params) => (
        <div
          style={{ paddingLeft: "0" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p>{params.value}</p>
        </div>
      ),
    },
    {
      field: "Business",
      headerName: "ประเภทธุรกิจ",
      width: 130,
      renderCell: (params) => {
        const business = params.value;
        const color = params.row.color || "inherit";

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "3px",
                height: "14px",
                width: "14px",
                backgroundColor: color,
                marginRight: "6px",
              }}
            />
            <div style={{ fontWeight: 500 }}>{business}</div>
          </div>
        );
      },
    },

    {
      field: "TentDeposit",
      headerName: "ค่ามัดจำเต็นท์",
      width: 120,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "Rent",
      headerName: "ค่าเช่า",
      width: 110,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    // { field: "WaterBill", headerName: "ค่าน้ำ", width: 110 },
    {
      field: "ElectricityBill",
      headerName: "ค่าไฟ",
      width: 110,
      valueFormatter: (params) => {
        if (params.value == 0) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "fines",
      headerName: "ค่าปรับ",
      width: 110,
      valueFormatter: (params) => {
        if (params.value == 0) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "total_amount",
      headerName: "ยอดรวมที่ต้องเก็บ",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == 0) {
          return "";
        }
        return new Intl.NumberFormat("th-TH", {
          maximumFractionDigits: 0,
        }).format(params.value);
      },
    },
    {
      field: "NumberDays",
      headerName: "จำนวนวันที่เช่าของรอบบิล",
      width: 190,
    },
    {
      field: "RentalStatus",
      headerName: "สถานะการเช่า",
      width: 120,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.RentalStatus === "1" ? "#00D715" : "#FF2C2C",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {params.row.RentalStatus === "1" ? "ยังเช่าอยู่" : "ว่าง"}
          <p
            style={{
              fontSize: "12px",
              lineHeight: "12.5px",
              color: "#9993B4",
              textAlign: "center",
            }}
          >
            06/09/2023
          </p>
        </div>
      ),
    },
    {
      field: "slip",
      headerName: "หลักฐานการจ่าย",
      width: 180,
      headerClassName: "table-columns",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleOpen(params.value)}
        >
          <img
            src={
              params.value
                ? `/images/mingcute_paper-line.png`
                : "/images/no-image.png"
            }
            alt={""}
            style={{
              maxHeight: "200px", // ปรับความสูงตามต้องการ
              maxWidth: "100px", // ให้รูปยืดเต็มความกว้างของป๊อปอัพ
            }}
          />
          <p style={{ color: "#3F8CFF" }}>view</p>
        </div>
      ),

      headerClassName: "custom-header-class",
    },
  ];

  const filteredRows = rows
    .filter((row) => {
      // กรองตามข้อความค้นหา
      const matchesSearchInput = row.RentalStatus.toLowerCase().includes(
        searchInput.toLowerCase()
      );

      // กรองตามสถานะการเช่า (ถ้ามีการเลือก)
      const matchesRentalStatus =
        rentalStatus === "" || row.RentalStatus === rentalStatus;

      return matchesSearchInput && matchesRentalStatus;
    })
    .sort((a, b) =>
      a.BlockNumber.localeCompare(b.BlockNumber, undefined, { numeric: true })
    );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        rowHeight={70}
        rowsPerPageOptions={[5]}
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed Shop"
            style={{
              maxWidth: "90vw",
              maxHeight: "90vh",
              // height: "350px",
              width: "500px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DataGridExample;
