import axios from "axios";

export const svTenantData=()=>{
    return axios.get('tenant/data').then((res) => res).catch((err) => err);
}

export const svUpdateTenantDetails=(formData)=>{
    return axios.post('rent/tenant/details',formData).then((res) => res).catch((err) => err);
}

export const svdestroyTenant=(formData)=>{
    return axios.post('rent/destroy/tenant',formData).then((res) => res).catch((err) => err);
}